import React, { useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, Typography, Pagination, useTheme, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import CustomInputField from '../../../components/CustomInputField';
import AlertCard from './AlertCard';
import { getAlerts } from '../../../store/alert/alertThunk';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getQueries } from '../../../store/analysis/analysisThunk'; // Import the thunk to fetch queries
import { toast } from 'react-toastify';

const AlertBoxContent = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [queryData, setQueryData] = React.useState<Record<string, any>>([]);

  // Redux state
  const { alerts = [], alertsLoading } = useAppSelector((state: any) => state.alert);
  const [totalAlerts, setTotalAlerts] = useState(0); // State to hold total alerts count
  const [loading, setLoading] = React.useState(true); // State for loader

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(getQueries({ isFavourite: true }))
        .then((res) => {
          if (res?.payload?.status === 200) {
            let queryData = res?.payload?.data;
            setQueryData(queryData.reverse());
          } else {
            toast.error("Failed to get the data.");
          }
          setLoading(false);
        })
        .catch((e) => {
          toast.error("Failed to get the data.");
          setLoading(true);
        });
    }, 2000); // Simulate for 2000ms delay
  }, []);

  // Local state
  const [selectedQuery, setSelectedQuery] = useState(''); // State for selected query
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const PAGE_ITEMS_COUNT = 10;

  useEffect(() => {
    // Dispatch fetch alerts action when the component loads or when selectedQuery or page changes
    const offset = (page - 1) * PAGE_ITEMS_COUNT; // Calculate offset
    dispatch(getAlerts({ queryId: selectedQuery, limit: PAGE_ITEMS_COUNT, offset })) // Pass the selected query ID
      .then((res) => {
        if (res?.payload?.status === 200) {
          setTotalAlerts(res.payload.total); // Assuming the response contains total count of alerts
        }
      });
  }, [dispatch, selectedQuery, page]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Function to filter alerts based on search query
  const filteredAlerts = alerts.filter((alert: any) => {
    const reason = alert.reason || ''; // Assuming 'reason' is the field in alert
    return reason.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {/* Header Section */}
      <Box sx={{ padding: 2, flexShrink: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomInputField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)} // Update search query
              placeholder="Search by reason"
              size="small"
              variant="outlined"
              autoComplete="off"
              sx={{ width: { lg: "40%", md: "100%" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{
              background: theme.palette.background.paper,
              borderRadius: 1
            }}>
              <Select
                labelId="query-select-label"
                value={selectedQuery}
                onChange={(e) => setSelectedQuery(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }} // Accessibility
                sx={{
                  backgroundColor: theme.palette.background.darkCardBackground,
                  color: theme.palette.text.default,
                  borderColor: theme.palette.additionalColors.databasecardBorder,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select a query {/* Default option prompting user to select */}
                </MenuItem>
                {queryData.map((query: any) => (
                  <MenuItem
                    key={query._id}
                    value={query._id}
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      '&:hover': {
                        backgroundColor: theme.palette.action.selected,
                      },
                    }}
                  >
                    {query.text} {/* Assuming 'text' is the field to display */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* Scrollable Content */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          padding: 2,
          background: theme.palette.dashboardContainer.containerBackground,
        }}
      >
        <Box>
          {loading ? ( // Show loader while data is being fetched
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            alertsLoading ? ( // Show loading message if alerts are being loaded
              <Typography variant="caption" textAlign="center">
                Loading alerts...
              </Typography>
            ) : filteredAlerts.length > 0 ? ( // If there are filtered alerts, display them
              <Grid container spacing={2}>
                {filteredAlerts.map((alert: any) => (
                  <Grid item xs={12} key={alert._id}>
                    {/* Render SingleAlert for a single alert */}
                    <AlertCard alertData={alert} />
                  </Grid>
                ))}
              </Grid>
            ) : ( // No results found
              <Typography variant="caption" textAlign="center">
                No Results Found
              </Typography>
            )
          )}

          {/* Footer Section */}
          <Grid container spacing={2} px={4} py={2}>
            <Box
              sx={{
                mt: 5,
                mx: "auto",
                mr: "0px",
                display: 'flex',
                alignItems: 'center', // Align items vertically centered
              }}
            >
              <Pagination
                onChange={handlePageChange}
                page={page}
                count={Math.ceil(totalAlerts / PAGE_ITEMS_COUNT)} // Update count based on total alerts
                size="small"
                sx={(theme) => ({
                  ...theme.palette.pagination,
                })}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AlertBoxContent;
