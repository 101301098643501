import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Formik } from "formik";
import React, { useEffect } from "react";
import CustomDivider from "../../../../components/CustomDivider";
import FormikInputField from "../../../../components/FormikInputField";
import { conversationalActions } from "../../../../store/conversational/chatSlice";
import { getChatHistory, sendChatMessage } from "../../../../store/conversational/chatThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ChatBox from "../../../support/component/Chatbot/component/ChatBox";

interface User {
  id: string;
  display: string;
}

export default function ChatBotChatSection({ queryId, isDrawerOpen, drawerWidth }: { queryId: string, isDrawerOpen: boolean, drawerWidth: number }) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const chatMessagesRef = React.useRef(null);
  const { messages, isLoading, lastPrompt, selectedMessage } = useAppSelector((state) => state.conversational);
  const [conversationInitiated, setConversationInitiated] = React.useState(false);

  const handleSendMessage = (messageText: string) => {
    dispatch(sendChatMessage({ query_id: queryId, prompt: messageText })).then(() => setConversationInitiated(true));
  };


  useEffect(() => {
    if (queryId) {
      dispatch(getChatHistory(queryId));
    }
  }, [queryId]); 

  React.useEffect(() => {
    if (chatMessagesRef.current) {
      const scrollContainer = chatMessagesRef.current as HTMLDivElement;
      scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
    }
  }, [messages]); // Run the effect whenever the messages array changes

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%", // Take the full height of the parent
      }}
    >
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" ,mb:"120px"}} ref={chatMessagesRef}>
        <Typography variant="body3" color={theme.palette.primary.baseDisabled} textAlign="center">
          Your chat history will be displayed here until chat is finished
        </Typography>

        <Box sx={{ flex: 1, overflowY: "auto",}}>
          {messages.map((m, index) => {
              return(
                index == messages.length - 1 ?
                  (<div key={m.id} style={{
                    marginBottom: "20px"
                  }}>
                    <ChatBox onCardClick={() => { dispatch(conversationalActions.setSelectedMessage(m)) }} showUserIcons={true} type="outgoing" message={m?.prompt} timestamp={m?.timestamp} customSentBoxIcon={<Avatar />} />
                <ChatBox
                      showUserIcons={true}
                      onCardClick={()=>{dispatch(conversationalActions.setSelectedMessage(m))}}
                      type="incoming"
                      timestamp={ m?.timestamp}
                      isSelected={m.id === selectedMessage?.id}
                      message={m.response?.answer}
                      typing={conversationInitiated && index === messages.length - 1}
                      customRecieveBoxIcon={<Avatar src="/skylarklabs_logo.png" sx={{ p: 1 }} />}
                    />
                </div>):(
                  <div key={m.id}>
                      <ChatBox onCardClick={() => { dispatch(conversationalActions.setSelectedMessage(m)) }} showUserIcons={true} type="outgoing" message={m?.prompt} timestamp={m?.timestamp} customSentBoxIcon={<Avatar />} />
                <ChatBox
                      showUserIcons={true}
                      onCardClick={()=>{dispatch(conversationalActions.setSelectedMessage(m))}}
                      type="incoming"
                      timestamp={m?.timestamp}
                      isSelected={m.id === selectedMessage?.id}
                      message={m.response?.answer}
                      typing={conversationInitiated && index === messages.length - 1}
                      customRecieveBoxIcon={<Avatar src="/skylarklabs_logo.png" sx={{ p: 1 }} />}
                    />
                </div>
                )
              )}
            )}   
          {lastPrompt !== "" && <ChatBox sx={{ mt: "100px" }} showUserIcons={true} type="outgoing" message={lastPrompt} customSentBoxIcon={<Avatar />} timestamp={new Date().toISOString()} />}
          {isLoading && <ChatBox type="incoming" loader={<LinearProgress />} />}
        </Box>
        </Box>
        {/* Input Section - Fixed at the Bottom */}
        <Box
          sx={{
            bgcolor:"red",
            width: isDrawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
            position: "absolute",
            bottom: 0,
            background: theme.palette.background.default,
            // boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
            padding: "10px",
            zIndex: 10, // Ensure the input stays above other content
            height: "80px",
            paddingRight: isDrawerOpen ? "20px" :"35px",
          }}
        >
           <CustomDivider width="100%" />
          <Box display="flex" mt={2} alignItems="center">
            <Formik
              initialValues={{
                replyText: '',
              }}
              onSubmit={(values, { resetForm }) => {
                handleSendMessage(values.replyText);
                resetForm();
              }}
            >
              {(props) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                  }}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    background: `${theme.palette.primary.tertiary}33`,
                    borderRadius: "10px",
                    padding: "0px 10px"
                  }}
                >
                  <FormikInputField
                    name="replyText"
                    variant="standard"
                    placeholder="Type a Message.."
                    value={props.values.replyText}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    autoComplete="off"
                    sx={{
                      width: "100%",
                      "& .MuiInput-root": {
                        ...theme.typography.body2,
                        color: theme.palette.primary.inactiveIcon,
                         background: "transparent",
                         boxShadow: "none",
                         ":hover:not(.Mui-disabled):before": {
                            borderBottom: "none", // Removes the border on hover
                          },
                          ":after": {
                            borderBottom: "none", // Ensures no bottom border after focus
                          },
                          ":before": {
                            borderBottom: "none", // Removes the initial bottom border
                          },
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: theme.palette.text.secondary, // Adjust the placeholder color here
                        opacity: 0.7, // Optional: Ensure full opacity for better visibility
                      },
                    }}
                    inputProps={{
                      style: {
                        // height: "15px",
                        borderRadius: "6px 6px 6px 6px",
                        background: "transparent",
                        color: theme.palette.text.titleLabel,
                      },
                    }}
                    error={props.touched.replyText && Boolean(props.errors.replyText)}
                    helperText={props.touched.replyText && props.errors.replyText ? props.errors.replyText : null}
                  />

                  <Box display="flex" gap={1.5} ml="auto">
                    <CustomDivider orientation="vertical" sx={{ height: "1.2em" }} />
                    <Button type="submit" sx={{ minWidth: 0, p: 0 }}>
                      <SendRoundedIcon
                        sx={{ color: theme.palette.text.default, fontSize: "1.8em", cursor: "pointer", transform: "rotate(-45deg)" }}
                      />
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
  );
}