import { Close } from "@mui/icons-material";
import { FormControl, IconButton, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

interface CustomDropdownProps {
    label: string;
    value: string;
    onChange: (event: SelectChangeEvent) => void;
    options: Array<{
        id: string;
        name?: string;
        text?: string;
        mentions?: number;
    }>;
    showMentions?: boolean;
    showViewAll?: boolean;

}



const CustomDropdown = ({
    label,
    value,
    onChange,
    options,
    showMentions = false,
    showViewAll = false,

}: CustomDropdownProps) => {
    const theme = useTheme()

    // Prepare all menu items including the view all option
    const menuItems = [
        ...options.map((option) => (
            <MenuItem
                key={option.id}
                value={option.id}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center'
                }}
            >
                <span>{option.name || option.text}</span>
                {showMentions && option.mentions !== undefined && (
                    <Typography variant="caption" color="text.secondary">
                        ({option.mentions})
                    </Typography>
                )}
            </MenuItem>
        ))
    ];

    const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onChange({ target: { value: '' } } as SelectChangeEvent);
    };

    // Add divider and view all option if needed
    if (showViewAll) {
        menuItems.push(
            <MenuItem key="divider" divider sx={{ my: 1 }} />,
            <MenuItem key="view-all" value="view-all">
                <Typography color="primary">View All {label}</Typography>
            </MenuItem>
        );
    }

    const formControlStyles = {
        minWidth: { xs: '100%', sm: 180 },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.darkCardBackground,
            '& fieldset': {
                borderColor: theme.palette.primary.tertiary,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.text.disabled,
            fontSize: '16px',
            fontWeight: 500,
            '&.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -9px) scale(0.85)',
            },
        },
        '& .MuiSelect-select': {
            fontSize: '16px',
            padding: '8px 14px',
            paddingRight: '72px !important',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block'
        },
        '& .MuiMenuItem-root': {
            fontSize: '14px',
            whiteSpace: 'normal',
            wordBreak: 'break-word'
        },
        '& .MuiSelect-icon': {
            color: theme.palette.primary.tertiary,
            right: value ? '32px' : '7px',
        }
    };

    return (
        <FormControl size="small" sx={{
            ...formControlStyles,
            minWidth: { xs: '100%', sm: 180 }
        }}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={onChange}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 300,
                            '& .MuiMenuItem-root': {
                                fontSize: '18px',
                                padding: '6px 14px',
                            }
                        }
                    }
                }}
            >
                {menuItems}
            </Select>
            {value && (
                <IconButton
                    size="small"
                    onClick={handleClear}
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: theme.palette.grey[500],
                        '&:hover': {
                            color: theme.palette.grey[700],
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        padding: '2px',
                    }}
                >
                    <Close fontSize="small" />
                </IconButton>
            )}
        </FormControl>
    );
};

export default CustomDropdown;