import { Event, People, Place } from '@mui/icons-material';
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import UiLoading from '../../../../../layouts/UiLoading';
import { getEntities } from '../../../../../store/analysis/analysisThunk';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { CategoryCard } from './CategoryCard';

interface EntityGridProps {
    searchTerm: string;
    onSearchChange: (value: string) => void;
}

const gridTypes = [
    {
        id: "Person",
        title: "Persons",
        icon: People,
    },
    {
        id: "Location",
        title: "Locations",
        icon: Place,
    },
    {
        id: "Event",
        title: "Events",
        icon: Event,

    },
    // {
    //     id: "Topic",
    //     title: "Topics",
    //     icon: LocalOffer,

    // },
    // {
    //     id: "Miscellaneous",
    //     title: "Miscellaneous",
    //     icon: MoreHoriz,
    // }


];

export const EntityGrid: React.FC<EntityGridProps> = ({ searchTerm, onSearchChange }) => {
    const theme = useTheme();
    const [queryParameters] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const { allEntities } = useAppSelector((state) => state.analysisEntities);


    useEffect(() => {
        let queryId = queryParameters.get("query");
        if (queryId) {
            setLoading(true);
            dispatch(getEntities(queryId)).then(() => { }).catch(() => { }).finally(() => { setLoading(false) })
        }
    }, [queryParameters]);


    return (
        <Box>
            {loading ? (
                <UiLoading height="100%" />
            ) : (
                <Grid container spacing={4}>
                    {gridTypes.map((category) => (
                        <Grid item xs={12} md={4} key={category.id}>
                            <CategoryCard
                                id={category.id}
                                title={category.title}
                                icon={category.icon}

                            />
                        </Grid>
                    ))}
                </Grid>
            )}

        </Box>
    );
};