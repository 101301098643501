import { Box, Chip, Typography, useTheme } from '@mui/material';
import { EntitiesType } from '../../../../../store/analysis/entitiesSlice';

interface RelatedEntitiesProps {
    selectedEntity: EntitiesType | null;
    relatedEntities: EntitiesType[];
    onEntityClick: (entity: EntitiesType) => void;
}

const RelatedEntities = ({
    selectedEntity,
    relatedEntities,
    onEntityClick
}: RelatedEntitiesProps) => {
    const theme = useTheme();

    if (!selectedEntity) return null;

    return (
        <Box
            sx={{
                // mt: { xs: 2, sm: 3 },
                backgroundColor: theme.palette.background.darkCardBackground,
                borderRadius: '8px',
                overflow: 'hidden',
                maxWidth: '100%'
            }}
        >
            {/* Header */}
            <Box sx={{
                p: 2,
                borderBottom: `1px solid ${theme.palette.primary.tertiary}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: 1
            }}>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: theme.palette.text.primary
                    }}
                >
                    Related to "{selectedEntity.name}"
                </Typography>
                {relatedEntities.length > 0 && (
                    <Chip
                        label={`${relatedEntities.length} items`}
                        size="small"
                        sx={{
                            height: '20px',
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                            fontSize: '14px'
                        }}
                    />
                )}
            </Box>

            {/* List Container */}
            <Box sx={{
                p: 2,
                display: 'grid',
                gap: 2,
                gridTemplateColumns: {
                    xs: '1fr',
                    sm: 'repeat(auto-fill, minmax(300px, 1fr))'
                }
            }}>
                {relatedEntities.length > 0 ? (
                    relatedEntities.map((entity: any) => (
                        <Box
                            key={(entity as any)._id.$oid}
                            onClick={() => onEntityClick(entity)}
                            sx={{
                                p: 2,
                                borderRadius: '6px',
                                backgroundColor: theme.palette.background.paper,
                                cursor: 'pointer',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    backgroundColor: `${theme.palette.primary.main}10`,
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                gap: 2
                            }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            color: theme.palette.text.primary,
                                            mb: 0.5
                                        }}
                                    >
                                        {entity.name}
                                    </Typography>
                                    {entity.type && (
                                        <Typography
                                            sx={{
                                                fontSize: '13px',
                                                color: theme.palette.text.secondary
                                            }}
                                        >
                                            {entity.type}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ flexShrink: 0 }}>
                                    {entity.mentions && (
                                        <Chip
                                            label={`${entity.mentions} mentions`}
                                            size="small"
                                            sx={{
                                                height: '20px',
                                                backgroundColor: `${theme.palette.primary.main}20`,
                                                color: theme.palette.primary.main,
                                                fontSize: '12px',
                                                fontWeight: 500
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>

                            {/* Additional Info Section - Uncomment and modify as needed */}
                            {/* {entity.additionalInfo && (
                                <Box sx={{
                                    mt: 1,
                                    pt: 1,
                                    borderTop: `1px solid ${theme.palette.primary.tertiary}`,
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            color: theme.palette.text.secondary,
                                            lineHeight: 1.4
                                        }}
                                    >
                                        {entity.additionalInfo}
                                    </Typography>
                                </Box>
                            )} */}
                        </Box>
                    ))
                ) : (
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: theme.palette.text.disabled,
                            py: 4,
                            fontSize: '13px',
                            gridColumn: '1 / -1'  // Span all columns
                        }}
                    >
                        No related entities found
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default RelatedEntities;