
import {
  ElectricBolt as ElectricBoltIcon,
  Public as GlobeIcon,
  Group,
  Info as InfoIcon,
  Language,
  TrendingUp as TrendingUpIcon,
  Warning
} from '@mui/icons-material';
import Share from "@mui/icons-material/Share";
import ShowChart from "@mui/icons-material/ShowChart";
import { Box, Button, Divider, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomStatics from "../../../components/CustomStatics";
import LineChart from "../../../components/LineChart";
import { useTab } from '../../../contexts/TabContext';
import UiLoading from "../../../layouts/UiLoading";
import { getEntitiesData, getLatestQuery, getNewConnections, getQueryAnalyticsData } from "../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFormattedDate } from "../../../utils/helper";
import MapSection from "../Components/Map";
import Title from "../Components/Title";
import EntitiesListDialog from "./EntitiesListDialog";
import KeyEntities from "./KeyEntities";
import SourcesListDialog from "./SourcesListDialog";

export default function OverviewWrapper() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { analyticsData } = useAppSelector((state) => state.analysis);
  const queryId = searchParams.get("query") || '';
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));
  const [entities, setEntities] = useState<any>({});
  const [showMore, setShowMore] = useState(false);
  const [showMoreSources, setShowMoreSources] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emergingTrendsData, setEmergingTrendsData] = useState<any[]>([]);
  const navigate = useNavigate();
  const { onTabChange } = useTab();

  // const statisticData = [
  //   { label: "Sources", count: analyticsData.sources_count, icon: <HexagonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  //   { label: "Results", count: analyticsData.results_count, icon: <InsertDriveFileIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  //   { label: "Mentions", count: analyticsData.mentions_count, icon: <MarkChatUnreadIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  //   { label: "Entities", count: analyticsData.entities_count, icon: <PersonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  //   { label: "Red Flags", count: analyticsData.red_flags_count, icon: <FlagIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  // ];

  const statisticData = [
    {
      label: "Sources",
      count: analyticsData.sources_count,
      icon: <Share sx={{ color: theme.palette.additionalColors.light }} />,
      trend: "+12%",
      subLabel: "Total active"
    },
    {
      label: "Results",
      count: analyticsData.results_count,
      icon: <ShowChart sx={{ color: theme.palette.additionalColors.light }} />,
      trend: "+15%",
      subLabel: "Last 24 hours"
    },
    {
      label: "Mentions",
      count: analyticsData.mentions_count,
      icon: <Language sx={{ color: theme.palette.additionalColors.light }} />,
      trend: "+18%",
      subLabel: "Across platforms"
    },
    {
      label: "Entities",
      count: analyticsData.entities_count,
      icon: <Group sx={{ color: theme.palette.additionalColors.light }} />,
      trend: "+8%",
      subLabel: "Unique identified"
    },
    {
      label: "Red Flags",
      count: analyticsData.red_flags_count,
      icon: <Warning sx={{ color: theme.palette.additionalColors.light }} />,
      subLabel: "Requiring attention",
    }
  ];
  // useEffect(() => {
  //   if (queryId) {
  //     dispatch(getQueryAnalyticsData(queryId)).then((res) => {
  //       if (res?.payload?.status !== 200) {
  //         dispatch(getLatestQuery()).then((latestRes) => {
  //           if (latestRes.payload.status === 200 && latestRes.payload.data._id !== undefined) {
  //             dispatch(getQueryAnalyticsData(latestRes.payload.data._id));
  //           }
  //         });
  //       }
  //     });

  //     //get entities data for query
  //     dispatch(getEntitiesData(queryId)).then((res) => {
  //       if (res.payload && res.payload.data && res.payload.data.entities) {
  //         const entities = res.payload.data.entities
  //         //group data by type
  //         const groupedData = entities.reduce((acc: any, entity: any) => {

  //           if (!acc[entity.type]) {
  //             acc[entity.type] = [];
  //           }
  //           acc[entity.type].push(entity);
  //           return acc;
  //         }, {});


  //         setEntities(groupedData);
  //       }
  //     });
  //   } else {
  //     dispatch(getLatestQuery()).then((res) => {
  //       if (res.payload.status === 200 && res.payload.data._id !== undefined) {
  //         dispatch(getQueryAnalyticsData(res.payload.data._id));
  //       }
  //     });
  //   }
  //   handleRefresh();
  // }, [queryId]);



  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true before fetching
      try {
        if (queryId) {
          const analyticsRes = await dispatch(getQueryAnalyticsData(queryId)).unwrap();
          if (analyticsRes?.status !== 200) {
            const latestRes = await dispatch(getLatestQuery()).unwrap();
            if (latestRes?.status === 200 && latestRes?.data?._id) {
              await dispatch(getQueryAnalyticsData(latestRes.data._id)).unwrap();
            }
          }
          const entitiesRes = await dispatch(getEntitiesData(queryId)).unwrap();
          if (entitiesRes?.data?.entities) {
            const groupedData = entitiesRes.data.entities.reduce((acc: any, entity: any) => {
              if (!acc[entity.type]) acc[entity.type] = [];
              acc[entity.type].push(entity);
              return acc;
            }, {});
            setEntities(groupedData);
          }

          dispatch(getNewConnections(queryId))
            .then((response: any) => {
              console.log("🚀 ~ .then ~ response:", response)

              if (response.payload?.data) {
                const formattedTopics = response.payload.data.hashtags.map((hashtag: any) => ({
                  id: hashtag.hashtag_id,
                  name: hashtag.name,
                  mentions: hashtag.count
                }));



                // Take the last 5 unique emerging trends
                setEmergingTrendsData(formattedTopics.slice(5, 10));
              }
            })
            .catch((error) => {
              console.error('Error fetching topics:', error);
            })
            .finally(() => {
              // setLoading(false);
            });
        } else {
          const latestRes = await dispatch(getLatestQuery()).unwrap();
          if (latestRes?.status === 200 && latestRes?.data?._id) {
            await dispatch(getQueryAnalyticsData(latestRes.data._id)).unwrap();
          }
        }
        handleRefresh();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after all API calls
      }
    };

    fetchData();
  }, [queryId]);

  const handleRefresh = () => {
    const currentDate = new Date();
    setLastUpdated(getFormattedDate(currentDate) + " at " + currentDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }));
  };

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleShowMoreSources = () => {
    setShowMoreSources(true);
  };
  const handleEntityClick = (type: string, value: string) => {

    if (onTabChange) {
      onTabChange('entities'); // Switch to 'entities' tab
    }
    const currentQuery = searchParams.get('query');
    navigate(`${window.location.pathname}?tab=home&subtab=entities${currentQuery ? `&query=${currentQuery}` : ''}`);

    // onEntityTypeChange(value);

  };
  const handleFeedClick = (type: string, value: string) => {

    if (onTabChange) {
      onTabChange('feed'); // Switch to 'entities' tab
    }
    const currentQuery = searchParams.get('query');
    navigate(`${window.location.pathname}?tab=home&subtab=feed${currentQuery ? `&query=${currentQuery}` : ''}`);

    // onEntityTypeChange(value);

  };
  const handleConnectionClick = (type: string, value: string) => {

    if (onTabChange) {
      onTabChange('connection'); // Switch to 'entities' tab
    }
    const currentQuery = searchParams.get('query');
    navigate(`${window.location.pathname}?tab=home&subtab=connection${currentQuery ? `&query=${currentQuery}` : ''}`);

    // onEntityTypeChange(value);

  };



  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={2}
    >
      {isLoading ? (
        <UiLoading height="100%" />
      ) : (
        <>
          <Title title="Overview" lastUpdated={lastUpdated} refresh={true} handleRefresh={handleRefresh} />
          <Grid
            container
            mt={2}
            spacing={2}
            sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}
          >
            {statisticData.map((staticData: any, idx) => (
              // <Grid item xs={12} sm={4} lg={2.4} key={`statics-${idx}`} display="flex" justifyContent="space-between" gap={1} width="100%">
              //   <CustomStatics label={staticData?.label} count={staticData?.count} icon={staticData?.icon} />
              // </Grid>
              <Grid item xs={12} sm={4} lg={2.4} key={`statics-${idx}`}>
                <CustomStatics
                  label={staticData.label}
                  count={staticData.count}
                  icon={staticData.icon}
                  subLabel={staticData.subLabel}
                />
              </Grid>
            ))}
          </Grid>

          <Grid
            mt={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            gap={2}
          >
            {/* Trending Topics */}




            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                background: theme.palette.background.darkCardBackground,
                p: 2,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TrendingUpIcon sx={{ color: theme.palette.additionalColors.dark }} />
                  <Box>
                    <Typography variant="h3" color={theme.palette.text.default} sx={{ fontSize: "1.2rem" }}>
                      {"Trending Topics"}
                    </Typography>
                    <Typography variant="caption" color={theme.palette.text.titleLabel}>
                      Top hashtags & keywords
                    </Typography>
                  </Box>
                </Box>
                <Tooltip title="This section shows the most popular hashtags and keywords currently trending across platforms.">
                  <InfoIcon sx={{ color: theme.palette.additionalColors.dark, fontSize: "1.2rem" }} />
                </Tooltip>
              </Box>

              <Divider sx={{
                my: 1.5,
                borderColor: theme.palette.additionalColors.databasecardBorder
              }} />

              {analyticsData &&
                analyticsData?.hashtags_trend &&
                analyticsData?.hashtags_trend.length > 0 &&
                analyticsData?.hashtags_trend.map((topic, idx) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      background: theme.palette.background.darkCardBackground,
                      p: 2,
                      border: theme.palette.additionalColors.databasecardBorder,
                      borderRadius: "8px",
                      marginY: "5px",
                    }}
                    key={idx}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,  // Ensures this section takes available space
                      }}
                    >
                      <Typography
                        variant="h6"
                        color={theme.palette.text.titleLabel}
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {`${topic.name}`}
                      </Typography>
                      <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                        {""}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h6" color={"green"} sx={{ marginRight: "10%" }}>
                        {`${topic.count}`}
                      </Typography>

                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          fontSize: "0.875rem",
                          marginRight: "15px",  // Small space between the buttons
                        }}
                        onClick={() => handleEntityClick('events', 'someEventValue')} // Replace 'someEventValue' with the actual dynamic value
                      >
                        Entities
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          fontSize: "0.875rem",
                          marginRight: "15px",
                        }}
                        onClick={() => handleFeedClick('events', 'someEventValue')} // Replace 'someEventValue' with the actual dynamic value
                      >
                        Feed
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          fontSize: "0.875rem",
                          marginRight: "15px",

                        }}
                        onClick={() => handleConnectionClick('events', 'someEventValue')} // Replace 'someEventValue' with the actual dynamic value
                      >
                        Connections
                      </Button>

                      {/* Buttons with navigation */}

                    </Box>
                  </Box>
                ))}
            </Box>

            {/* Emergency Trends Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                background: theme.palette.background.darkCardBackground,
                p: 2,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <ElectricBoltIcon sx={{ color: theme.palette.additionalColors.dark }} />
                  <Box>
                    <Typography variant="h3" color={theme.palette.text.default} sx={{ fontSize: "1.2rem" }}>
                      {"Emerging Trends"}
                    </Typography>
                    <Typography variant="caption" color={theme.palette.text.titleLabel}>
                      Fast-growing topics
                    </Typography>
                  </Box>
                </Box>
                <Tooltip title="This section shows the mostFast growing topics currently trending across platforms.">
                  <InfoIcon sx={{ color: theme.palette.additionalColors.dark, fontSize: "1.2rem" }} />
                </Tooltip>              </Box>

              <Divider sx={{
                my: 1.5,
                borderColor: theme.palette.additionalColors.databasecardBorder
              }} />

              {/* Sentiment Analysis */}


              {/* Emerging Trends Cards */}
              {emergingTrendsData.map((trend: any, index: any) => (
                <Box
                  key={index}
                  sx={{
                    background: theme.palette.background.darkCardBackground,
                    p: 2,
                    border: theme.palette.additionalColors.databasecardBorder,
                    borderRadius: "8px",
                    marginY: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    variant="h6"
                    color={theme.palette.text.titleLabel}
                  >
                    {trend.name}
                  </Typography>

                  <Typography
                    variant="h6"
                    color="green"
                  >
                    {trend.mentions}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Key Entities section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                background: theme.palette.background.darkCardBackground,
                p: 2,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <GlobeIcon sx={{ color: theme.palette.additionalColors.dark }} />
                  <Box>
                    <Typography variant="h3" color={theme.palette.text.default} sx={{ fontSize: "1.2rem" }}>
                      {"Key Entities"}
                    </Typography>
                    <Typography variant="caption" color={theme.palette.text.titleLabel}>
                      Frequently mentioned
                    </Typography>
                  </Box>
                </Box>
                <Tooltip title="This section shows the most frequently mentioned across platforms.">
                  <InfoIcon sx={{ color: theme.palette.additionalColors.dark, fontSize: "1.2rem" }} />
                </Tooltip>              </Box>

              <Divider sx={{
                my: 1.5,
                borderColor: theme.palette.additionalColors.databasecardBorder
              }} />

              <KeyEntities entitiesData={entities} handleShowMore={handleShowMore} />
            </Box>
          </Grid>


          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <LineChart lineData={analyticsData?.sentiment_time_series} />
            </Grid>


            <Grid item xs={12} sm={6}>
              <MapSection />
            </Grid>
          </Grid>




          {/* <Grid mt={4}>
            <RiskSummary />
          </Grid> */}
        </>
      )}

      {/* Dialog to show more entities */}
      <EntitiesListDialog
        showMore={showMore}
        setShowMore={setShowMore}
        entities={entities}
      />

      {/* Dialog to show more sources */}
      <SourcesListDialog
        showMore={showMoreSources}
        setShowMoreSources={setShowMoreSources}
        sources={analyticsData?.source_counts} />

    </Box>
  );
}


