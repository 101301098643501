import { Box, Typography, useTheme } from "@mui/material";
import GoogleMap from "google-map-react";
import React, { useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ConnectionSlider, { CustomConnectionSlider } from "../../../../../components/ConnectionSlider";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../../../utils/constants";
import EntityConnections from "./EntityConnections";
import Legend from "./Legend";
import ScoreLegend from "./ScoreLegend";

type TextPalette = {
  extremeStatus: string;
  highStatus: string;
  moderateStatus: string;
  neutralStatus: string;
  lowStatus: string;
};

type HotspotType = { id: string; label: string; position: { lat: number; lng: number }; status: string; legend: string; connectId: string };

const EntitiesMap = () => {
  const theme = useTheme();
  const infoWindowRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [hotspots, setHotspots] = useState<HotspotType[]>([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [connectionDepth, setConnectionDepth] = useState<number>(1);
  const { tieredConnections } = useAppSelector((state) => state.analysisConnections);

  React.useEffect(() => {
    if (tieredConnections.length > 0) {
      const allEntitiesWithLocation = tieredConnections[connectionDepth - 1]
        .filter((e) => "location" in e?.head)
        .map((e) => ({ ...e.head, id: e.head.id, connectId: e.tail.id }))
        .map((e) => ({
          id: e.id,
          label: e.name,
          position: {
            lat: e?.location?.latitude,
            lng: e?.location?.longitude,
          },
          status: "neutral",
          legend: e.type,
          connectId: e.connectId,
        }));
      // console.log("allEntitiesWithLocation", allEntitiesWithLocation);
      setHotspots(allEntitiesWithLocation);
    }
  }, [tieredConnections, connectionDepth]);

  const handleOpenDetails = () => {
    setOpenDetails((prev) => !prev);
  };

  const handleMarkerClick = useCallback(
    (map: any, maps: any, mark: any, nodes: any) => {
      if (infoWindowRef.current && !openDetails) {
        setOpenDetails(false);
      }

      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }

      const cityName = "Entity Name";
      const entitySubType = "Goverment worker";
      const location = "USA";
      const publicationTextStyle = `color: ${theme.palette.primary.main}; font-size: 12px; font-weight: 400; line-height: 120%;`;
      const paraStyle = `color: ${theme.palette.text.titleLabel};text-transform:capitalize; font-size: 12px; font-weight: 400; line-height: 120%; margin-top:5px; margin-bottom:0px`;
      const content = `
          <div style="background-color: #181D27; color: #C7DDFD; padding: 10px; margin: 0px;">
            <strong>${cityName}</strong><br /><br />
            <p style="${paraStyle}">Entity type: ${nodes?.legend}</p>
            <p style="${paraStyle}">Entity Sub type: ${entitySubType}</p>
            <p style="${paraStyle}">Location: ${location}</p>
            <p id="sharePara" style="${publicationTextStyle}; display: flex; cursor:pointer">
              <img src="/assets/shareicon.png" alt="shareicon" /><span style="margin-left:10px;">${54}
            </p>
            <span style="${paraStyle}; marginTop: 10px">Risk Score: 
            <span style="color:${theme.palette.text.extremeStatus}">${nodes?.status}</span>
            </span>
            <br>
          </div>`;

      const newInfoWindow = new maps.InfoWindow({ content });
      newInfoWindow.open(map, mark);
      infoWindowRef.current = newInfoWindow;

      setTimeout(() => {
        const paraElement = document.getElementById("sharePara");
        if (paraElement) {
          paraElement.addEventListener("click", handleOpenDetails);
        }
      }, 1000);
    },
    [infoWindowRef]
  );

  const defaultProps = {
    center: {
      lat: 55.7558,
      lng: 37.6176,
    },
    zoom: 1,
  };

  const renderMarkers = (map: any, maps: any) => {
    hotspots.forEach((mark: any) => {
      const statusColor: any = `${mark?.status}Status`;
      const connectionLocation = hotspots?.find((node: any) => node?.id === mark?.connectId);
      if (connectionLocation?.position) {
        const polyline = new maps.Polyline({
          path: [mark.position, connectionLocation?.position],
          geodesic: true,
          strokeColor: theme.palette.text.tableHeader,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        polyline.setMap(map);
      }

      const createMarker = (data: any) => {
        const marker = new maps.Marker({
          zIndex: data?.zIndex,
          position: { lat: mark?.position?.lat, lng: mark?.position?.lng },
          map,
          icon: data?.icon,
          label: {
            text: `${mark?.label}`,
            color: theme.palette.text.secondaryText,
            fontWeight: "bold",
            fontSize: "12px",
            className: "marker-label-position",
          },
        });

        marker.addListener("click", () => {
          handleMarkerClick(map, maps, marker, mark);
        });
      };

      const iconUrl =
        mark?.legend === "person"
          ? "/assets/usericon.png"
          : mark?.legend === "organization"
            ? "/assets/group.png"
            : mark?.legend === "event"
              ? "/assets/event.png"
              : mark.legend === "topic"
                ? "/assets/topic.png"
                : "/assets/location.png";

      createMarker({
        icon: {
          url: iconUrl,
          scaledSize: new maps.Size(20, 20),
          anchor: new maps.Point(10, 10),
        },
        zIndex: 20,
      });

      createMarker({
        icon: {
          path: maps.SymbolPath.CIRCLE,
          scale: 15,
          fillColor: `${theme.palette.text[statusColor as keyof TextPalette]}`,
          fillOpacity: 0.7,
          strokeWeight: 1,
          strokeOpacity: 0.8,
          strokeColor: `${theme.palette.text[statusColor as keyof TextPalette]}`,
        },
        zIndex: 10,
      });
    });
  };

  const handleChangeConnectionDepth = (event: Event, newValue: number | number[]) => {
    setConnectionDepth(newValue as number);
  };

  const marks = Array.from({ length: 5 }, (_, index) => ({
    value: index + 1,
    label: (index + 1).toString(),
  }));

  return (
    <Box mt={2} width="100%" height={"54vh"}>
      <Box width="100%" height="100%" borderRadius={2} overflow="scroll" position="relative">
        <GoogleMap
          key={connectionDepth}
          bootstrapURLKeys={{ key: GOOGLE_MAP_REACT_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }: any) => renderMarkers(map, maps)}
        ></GoogleMap>
        <Box
          width="236px"
          ml={2}
          sx={{ position: "absolute", background: "#1D2330", borderRadius: 2, pt: 2, pb: 0.5, px: 1.5, bottom: "20px", zIndex: 1000 }}
        >
          <Typography variant="body4" color={theme.palette.text.tableHeader}>
            Connection level
          </Typography>
          <br />
          <CustomConnectionSlider
            sx={{
              "&.MuiSlider-root": {
                padding: "12px 0",
              },
              "& .MuiSlider-mark": { display: "none" },
              "& .MuiSlider-markLabel": {
                color: theme.palette.text.titleLabel,
                fontSize: "12px",
                fontWeight: 400,
              },
            }}
            slots={{ thumb: ConnectionSlider }}
            min={1}
            max={5}
            step={1}
            value={connectionDepth}
            onChange={handleChangeConnectionDepth}
            marks={marks}
            defaultValue={1}
            getAriaValueText={(value: number) => `${value}`}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: 2,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <Legend />
        <ScoreLegend sx={{ [theme.breakpoints.up("md")]: { ml: "auto" } }} />
      </Box>
      {openDetails && <EntityConnections open={openDetails} openDrawer={handleOpenDetails} />}
    </Box>
  );
};

export default EntitiesMap;
