import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";

function EntitiesListDialog({ showMore, setShowMore, entities }: any) {
    const theme = useTheme();

    return (
        <Dialog
            open={showMore}
            onClose={() => setShowMore(false)}
            PaperProps={{
                sx: {
                    background: theme.palette.background.gradientLight1,
                    borderRadius: "8px",
                    py: 4,
                    px: 2,
                    width: "30%",
                    height: "80%",
                },
            }}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.039)",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: "bold",
                    borderRadius: 4,
                    color: theme.palette.text.primary,
                    fontSize: "1.20rem",
                }}
            >
                {"All Entities"}
            </DialogTitle>
            <DialogContent sx={{ margin: 0, padding: 0 }}>
                {Object.keys(entities)
                    .filter((entityType) => entityType.trim() !== "") // Skip empty keys
                    .map((entityType) => (
                        <Box key={entityType} sx={{ marginBottom: "20px" }}>
                            <Typography
                                variant="h5"
                                color={theme.palette.text.titleLabel}
                                style={{
                                    margin: "10px 0 10px 0",
                                }}
                            >
                                {entityType}
                            </Typography>

                            {entities[entityType]
                                .filter(
                                    (entity: any) =>
                                        entity.name.trim() !== "" && entity.type.trim() !== "" // Skip entities with empty name or type
                                )
                                .map((entity: any) => (
                                    <Box
                                        key={entity.entity_id}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            background: theme.palette.background.darkCardBackground,
                                            p: 2,
                                            border: theme.palette.additionalColors.databasecardBorder,
                                            borderRadius: "8px",
                                            marginY: "5px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                color={theme.palette.text.titleLabel}
                                                style={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                {entity.name}
                                            </Typography>
                                            <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                                                {entity.type}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="h6" color={"green"}>
                                                {`+${entity.count}%`} {/* Adjust as per your data */}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowMore(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EntitiesListDialog;
