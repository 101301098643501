import TabLayout from "../../layouts/TabLayout";
import AlertBoxContent from "./component/AlertBoxContent";

const tabs = [
    {
        id: "alert",
        title: "Alerts",
        disabled: false,
        component: <AlertBoxContent />,
    },
]

export default function Alert() {
    return <TabLayout tabs={tabs} />;
}