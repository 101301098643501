import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
const KeyEntities = ({ entitiesData, handleShowMore }: any) => {

    const theme = useTheme();
    const topEntityTypes = Object.keys(entitiesData).slice(0, 2);

    return (
        <React.Fragment>
            {/* <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                    marginBottom: "10px",
                }}
            >
                <DomainIcon sx={{ color: theme.palette.additionalColors.dark, marginRight: "5px" }} />
                <Typography variant="h3" color={theme.palette.text.default} mb={0.2} sx={{ fontSize: "1.2rem" }}>
                    {"Key Entities"}
                </Typography>
            </Box> */}

            <Box>

                {(!entitiesData || topEntityTypes.length === 0) && (
                    < Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%", // Adjust based on your layout
                            minHeight: "200px", // Ensures visibility even in small containers
                        }}
                    >
                        <Typography variant="h6" color={theme.palette.text.secondary}>
                            No data available.
                        </Typography>
                    </Box>
                )}
                {topEntityTypes.map((entityType) => (
                    <Box key={entityType} sx={{ marginBottom: "20px" }}>
                        <Typography
                            variant="h5"
                            color={theme.palette.text.titleLabel}
                            style={{
                                margin: "10px 0 10px 0",
                            }}
                        >
                            {entityType}
                        </Typography>

                        {/* Render all entities of the current entity type */}
                        {entitiesData[entityType].map((entity: any, index: number) => (
                            <>
                                {(entity.name !== '' && entity.name !== 'i' && entity.name !== 'i\'d' && entity.name !== 'u') && <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        background: theme.palette.background.darkCardBackground,
                                        p: 2,
                                        border: theme.palette.additionalColors.databasecardBorder,
                                        borderRadius: "8px",
                                        marginY: "5px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            color={theme.palette.text.titleLabel}
                                            style={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            {entity.name}
                                        </Typography>
                                        <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                                            {entity.type}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6" color={"green"}>
                                            {`+${entity.count}`}
                                        </Typography>
                                    </Box>
                                </Box>}
                            </>
                        ))}


                    </Box>
                ))}

                {
                    entitiesData && Object.keys(entitiesData).length > 0 && (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => handleShowMore()}
                            sx={{ marginTop: "20px" }}
                        >
                            Show More
                        </Button>
                    )
                }

            </Box>
        </React.Fragment>
    );
};

export default KeyEntities;
