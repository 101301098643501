import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomTab from "../../../components/CustomTab";
import { TabProvider } from "../../../contexts/TabContext";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { analysisQueryActions } from "../../../store/analysis/analysisQuerySlice";
import { getLatestQuery, getQueries, getQueryById } from "../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setActiveAnalysisTab } from "../../../store/ui/uiSlice";
import { textTruncate } from "../../../utils/helper";
import Connectionswrapper from "../Wrappers/Connections";
import Entitieswrapper from "../Wrappers/Entities";
import { default as Feedwrapper } from "../Wrappers/Feed";
import Overviewrapper from "../Wrappers/Overview";
import CustomDropdown from "./Feed/components/CustomDropdown";


export default function SubHeader() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();

  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
  const { activeAnalysisTab } = useAppSelector((state: Record<string, any>) => state.ui);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryData, setQueryData] = useState<Record<string, any>>(query);
  const queryParam = searchParams.get("subtab");
  const queryId = searchParams.get("query");
  const [activeTab, setActiveTab] = useState(queryParam || "overview");
  const [queryList, setQueryList] = useState<Record<string, any>>()



  const formControlStyles = {
    minWidth: {
      xs: 180,
      sm: 220,
      md: 280
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.darkCardBackground,
      '& fieldset': {
        borderColor: theme.palette.primary.tertiary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.disabled,
      fontSize: '14px',
      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
      padding: '6px 14px',
      color: theme.palette.text.primary,
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.tertiary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
    dispatch(setActiveAnalysisTab(tab));
    setSearchParams((params) => {
      params.set("subtab", tab);
      return params;
    });
  };

  const handleQueryClick = (value: string) => {
    if (value === 'view-all') {
      // Navigate to history page while maintaining any necessary params
      const currentParams = new URLSearchParams(window.location.search);
      handleRouteClick(`/history?tab=history&subtab=queries-history`);
    } else {
      // Handle normal query selection
      setSearchParams((params) => {
        params.set("query", value);
        return params;
      });
      dispatch(analysisQueryActions.setSelectedQueryId(value));
    }
  };

  const tabs = [
    {
      id: "overview",
      title: "Overview",
      disabled: false,
      component: <Overviewrapper />,
    },
    //  {
    //   id: "chat",
    //   title: "Chat",
    //   disabled: false,
    //   component: <ChatBot />,
    // },
    {
      id: "entities",
      title: "Entities",
      disabled: false,
      component: <Entitieswrapper />,
    },
    {
      id: "feed",
      title: "Feed",
      disabled: false,
      component: <Feedwrapper />,
    },
    {
      id: "connection",
      title: "Connections",
      disabled: false,
      component: <Connectionswrapper />,
    },
    // {
    //   id: "map",
    //   title: "Map",
    //   disabled: true,
    //   component: <Mapwrapper />,
    // },
    // {
    //   id: "chat",
    //   title: "Chat",
    //   disabled: false,
    //   component: <ChatWrapper />,
    // },
  ];


  useEffect(() => {
    setActiveTab(activeAnalysisTab);
  }, [activeAnalysisTab]);

  // useEffect(() => {
  //   if (queryParam) {
  //     dispatch(setActiveAnalysisTab(queryParam));
  //     setSearchParams((params) => {
  //       if (queryId) {
  //         params.set("query", queryId);
  //       }
  //       return params;
  //     });
  //   }
  // }, [queryParam, queryId]);

  useEffect(() => {
    if (queryParam) {
      setActiveTab(queryParam);
      dispatch(setActiveAnalysisTab(queryParam));
    }

    // if (queryId !== undefined) {
    //   dispatch(getQueryById(queryId)).then((res) => {
    //     if (res?.payload?.status === 200) {
    //       setQueryData(res?.payload?.data);
    //     }
    //     // else if(!res?.payload){ // handling if someone enters a queryId which doesn't exist
    //     //   handleRouteClick(`${routes.home.path}?tab=${activeTab}&page=${queryParam}&query=${queryData?.id}`);
    //     // }
    //   });
    // }
  }, []);

  useEffect(() => {
    if (queryId) {
      dispatch(getQueryById(queryId)).then((res) => {
        if (res?.payload?.status === 200) {
          setQueryData(res?.payload?.data);
          dispatch(analysisQueryActions.setSelectedQueryId(queryId));
        } else {
          dispatch(getLatestQuery()).then((res) => {
            if (res.payload.status === 200 && res.payload.data._id !== undefined) {
              dispatch(getQueryById(res?.payload?.data?._id)).then((res) => {
                if (res?.payload?.status === 200) {
                  setQueryData(res?.payload?.data);
                  dispatch(analysisQueryActions.setSelectedQueryId(queryId));
                }
              });
            }
            handleRouteClick(`${routes.home.path}?tab=home&subtab=overview&query=${res?.payload?.data?._id}`);
          });
        }
      });
    } else {
      dispatch(getLatestQuery()).then((res) => {
        if (res.payload.status === 200 && res.payload.data._id !== undefined) {
          dispatch(getQueryById(res?.payload?.data?._id)).then((res) => {
            if (res?.payload?.status === 200) {
              setQueryData(res?.payload?.data);
              dispatch(analysisQueryActions.setSelectedQueryId(queryId));
            }
          });
        }
        handleRouteClick(`${routes.home.path}?tab=home&subtab=overview&query=${res?.payload?.data?._id}`);
      });
    }
  }, [queryId]);

  useEffect(() => {
    dispatch(getQueries({ isFavourite: false })).then((res) => {
      if (res?.payload?.status === 200) {
        let queryData = res?.payload?.data;

        setQueryList(queryData.reverse().slice(0, 5));
      } else {
        toast.error("Failed to get the data.");
      }
    })
      .catch((e) => { });;
  }, []);

  return (
    <TabProvider onTabChange={handleActiveTab}>

      <Box height="100%" overflow="hidden">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                padding: {
                  xs: "6px 8px",
                  sm: "8px 10px"
                },
                width: {
                  xs: '100%',
                  sm: '90%',
                  md: '90%'
                },
                display: "flex",
                alignItems: "center",
                flexWrap: {
                  xs: 'wrap',
                  sm: 'nowrap'
                },
                gap: 1
              }}
            >
              <Typography
                variant="bigCaption"
                color={theme.palette.text.tableHeader}
                sx={{
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '16px'
                  },
                  whiteSpace: 'nowrap'
                }}
              >
                Query: &nbsp;
              </Typography>

              <CustomDropdown
                label=""
                value={queryData?._id || ""}
                onChange={(event) => handleQueryClick(event.target.value)}
                options={queryList?.map((q: any) => ({
                  id: q._id,
                  name: q.text ? textTruncate(q.text, 30) : textTruncate(q.keywords?.join(", "), 30)
                })) || []}
                showViewAll
              />

            </Box>
          </Grid>
          <Grid item xs={9} md={6} sx={{ textAlign: "center" }}>
            <CustomTab tabs={tabs} handleActiveTab={handleActiveTab} sx={{ p: 1 }} activeTab={activeTab} />
          </Grid>
          <Grid item xs={3} md={3} sx={{ textAlign: "right" }}>
            {/* <CustomSecondaryButton
            startIcon={<UploadIcon />}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            }}
          >
            Export Report
          </CustomSecondaryButton>
          <CustomIconButton
            sx={{
              color: theme.palette.text.default,
              width: "36px",
              height: "36px",
              [theme.breakpoints.up("lg")]: {
                display: "none",
              },
            }}
          >
            <UploadIcon />
          </CustomIconButton> */}
          </Grid>
        </Grid>
        <Box sx={{ height: "100%", overflow: "scroll", mt: 2, pb: 15 }}>
          {tabs.map((tab) => (
            tab.id === activeTab && (
              <Box key={tab.id}>
                {/* {tab.id === 'feed' ? (
                <FeedWrapper onTabChange={handleTabChange} />
              ) : (
                tab.component
              )} */}
                {tabs.find((tab) => tab.id === activeTab)?.component}
              </Box>
            )
          ))}
        </Box>
      </Box>

    </TabProvider>
  );

}
