// import { Autocomplete, Box, Chip, TextField, useTheme } from '@mui/material';
// import { useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import { getEntities } from '../../../../../../../store/analysis/analysisThunk';
// import { EntitiesType } from '../../../../../../../store/analysis/entitiesSlice';
// import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';

// interface EntitySearchProps {
//     onEntitiesChange: (entities: EntitiesType[]) => void;
// }

// const EntitySearch = ({ onEntitiesChange }: EntitySearchProps) => {
//     const theme = useTheme();
//     const dispatch = useAppDispatch();
//     const [searchParams] = useSearchParams();
//     const { allEntities } = useAppSelector((state) => state.analysisEntities);
//     const [selectedEntities, setSelectedEntities] = useState<EntitiesType[]>([]);
//     const [availableEntities, setAvailableEntities] = useState<EntitiesType[]>([]);
//     const [open, setOpen] = useState(false);

//     useEffect(() => {
//         const queryId = searchParams.get('query');
//         if (queryId) {
//             dispatch(getEntities(queryId));
//         }
//     }, [dispatch, searchParams]);

//     useEffect(() => {
//         setAvailableEntities(allEntities);
//     }, [allEntities]);

//     const handleChange = (_event: any, newValue: EntitiesType[]) => {
//         setSelectedEntities(newValue);
//         onEntitiesChange(newValue);
//     };

//     return (
//         <Box sx={{ width: '100%' }}>
//             <Autocomplete
//                 multiple
//                 id="entity-search"
//                 options={availableEntities}
//                 value={selectedEntities}
//                 onChange={handleChange}
//                 open={open}
//                 onInputChange={(event, value) => {
//                     setOpen(value.length > 0);
//                 }}
//                 getOptionLabel={(option) => option.name || ''}
//                 isOptionEqualToValue={(option: any, value: any) =>
//                     option?._id?.['$oid'] === value?._id?.['$oid']
//                 }
//                 popupIcon={null}
//                 ListboxProps={{
//                     style: {
//                         fontSize: '13px'
//                     }
//                 }}
//                 slotProps={{
//                     paper: {
//                         sx: {
//                             backgroundColor: theme.palette.background.darkCardBackground,
//                             '& .MuiAutocomplete-option': {
//                                 fontSize: '13px',
//                                 padding: '4px 8px',
//                             }
//                         }
//                     }
//                 }}
//                 renderInput={(params) => {
//                     const { InputProps, inputProps, ...rest } = params;
//                     const { startAdornment, ...other } = InputProps;
//                     return (
//                         <TextField
//                             {...rest}
//                             InputProps={{
//                                 ...other,
//                                 startAdornment: startAdornment,
//                                 inputProps: {
//                                     ...inputProps,
//                                     // Only show placeholder when input is empty AND no entities are selected
//                                     placeholder:
//                                         (!params.inputProps.value && selectedEntities.length === 0)
//                                             ? "Search entities..."
//                                             : ""
//                                 }
//                             }}
//                             variant="outlined"
//                             size="small"
//                             sx={{
//                                 width: '100%',
//                                 backgroundColor: theme.palette.background.darkCardBackground,
//                                 borderRadius: '6px',
//                                 '& .MuiOutlinedInput-root': {
//                                     height: '36px',
//                                     '& fieldset': {
//                                         borderColor: theme.palette.primary.tertiary,
//                                     },
//                                     '&:hover fieldset': {
//                                         borderColor: theme.palette.primary.main,
//                                     },
//                                     '&.Mui-focused fieldset': {
//                                         borderColor: theme.palette.primary.main,
//                                     },
//                                     '& input': {
//                                         fontSize: '13px',
//                                         '&::placeholder': {
//                                             color: theme.palette.text.disabled,
//                                             opacity: 0.7,
//                                             fontSize: '15px',
//                                         }
//                                     }
//                                 }
//                             }}
//                         />
//                     );
//                 }}
//                 renderTags={(value, getTagProps) =>
//                     value.map((option, index) => {
//                         const tagProps = getTagProps({ index });
//                         const { key, ...otherTagProps } = tagProps;

//                         return (
//                             <Chip
//                                 key={(option as any)._id['$oid']}
//                                 label={option.name}
//                                 {...otherTagProps}
//                                 size="small"
//                                 sx={{
//                                     backgroundColor: theme.palette.primary.main,
//                                     color: theme.palette.common.white,
//                                     fontSize: '13px',
//                                     '& .MuiChip-deleteIcon': {
//                                         color: theme.palette.common.white,
//                                         '&:hover': {
//                                             color: theme.palette.grey[300],
//                                         }
//                                     }
//                                 }}
//                             />
//                         );
//                     })
//                 }
//                 filterOptions={(options, { inputValue }) =>
//                     options.filter((option) =>
//                         option.name?.toLowerCase().includes(inputValue.toLowerCase())
//                     )
//                 }
//                 sx={{
//                     '& .MuiAutocomplete-endAdornment': {
//                         top: '50%',
//                         transform: 'translateY(-50%)',
//                     }
//                 }}
//             />
//         </Box>
//     );
// };

// export default EntitySearch;

import { Psychology as BrainIcon } from '@mui/icons-material';
import { Autocomplete, Box, Chip, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getEntities } from '../../../../../../../store/analysis/analysisThunk';
import { EntitiesType } from '../../../../../../../store/analysis/entitiesSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';

interface EntitySearchProps {
    onEntitiesChange: (entities: EntitiesType[]) => void;
}

const EntitySearch = ({ onEntitiesChange }: EntitySearchProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { allEntities } = useAppSelector((state) => state.analysisEntities);
    const [selectedEntities, setSelectedEntities] = useState<EntitiesType[]>([]);
    const [availableEntities, setAvailableEntities] = useState<EntitiesType[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const queryId = searchParams.get('query');
        if (queryId) {
            dispatch(getEntities(queryId));
        }
    }, [dispatch, searchParams]);

    useEffect(() => {
        setAvailableEntities(allEntities);
    }, [allEntities]);

    const handleChange = (_event: any, newValue: EntitiesType[]) => {
        setSelectedEntities(newValue);
        onEntitiesChange(newValue);
    };

    const handleDelete = (entityToDelete: EntitiesType) => {
        const newEntities = selectedEntities.filter(
            entity => (entity as any)._id['$oid'] !== (entityToDelete as any)._id['$oid']
        );
        setSelectedEntities(newEntities);
        onEntitiesChange(newEntities);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/* Search Input Container */}
            <Box sx={{ position: 'relative', mb: selectedEntities.length > 0 ? 2 : 0 }}>
                {/* Brain Icon and Search Text */}
                <Box
                    sx={{
                        position: 'absolute',
                        left: '16px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        zIndex: 1,
                        color: theme.palette.text.disabled,
                    }}
                >
                    <BrainIcon sx={{ fontSize: 18 }} />
                    <span style={{ fontSize: '13px' }}>Search...</span>
                </Box>

                {/* Command Key Shortcut */}
                <Box
                    sx={{
                        position: 'absolute',
                        right: '12px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                    }}
                >
                    <Chip
                        label="⌘K"
                        size="small"
                        sx={{
                            height: '20px',
                            backgroundColor: theme.palette.background.darkCardBackground,
                            border: `1px solid ${theme.palette.primary.tertiary}`,
                            borderRadius: '4px',
                            '& .MuiChip-label': {
                                fontSize: '11px',
                                padding: '0 4px',
                                color: theme.palette.text.disabled,
                            },
                        }}
                    />
                </Box>

                <Autocomplete
                    multiple
                    id="entity-search"
                    options={availableEntities}
                    value={selectedEntities}
                    onChange={handleChange}
                    open={open}
                    onInputChange={(event, value) => {
                        setOpen(value.length > 0);
                    }}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                        option?._id?.['$oid'] === value?._id?.['$oid']
                    }
                    popupIcon={null}
                    ListboxProps={{
                        style: {
                            fontSize: '13px'
                        }
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                backgroundColor: theme.palette.background.darkCardBackground,
                                '& .MuiAutocomplete-option': {
                                    fontSize: '13px',
                                    padding: '4px 8px',
                                }
                            }
                        }
                    }}
                    renderInput={(params) => {
                        const { InputProps, inputProps, ...rest } = params;
                        const { endAdornment, ...other } = InputProps;
                        return (
                            <TextField
                                {...rest}
                                InputProps={{
                                    ...other,
                                    endAdornment: endAdornment,
                                    inputProps: {
                                        ...inputProps,
                                        placeholder: "What would you like to search?"
                                    },
                                    style: {
                                        paddingLeft: '100px',
                                        paddingRight: '50px',
                                    }
                                }}
                                variant="outlined"
                                sx={{
                                    width: '100%',
                                    backgroundColor: theme.palette.background.darkCardBackground,
                                    borderRadius: '8px',
                                    '& .MuiOutlinedInput-root': {
                                        minHeight: '36px',
                                        '& fieldset': {
                                            borderColor: theme.palette.primary.tertiary,
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: theme.palette.primary.main,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: theme.palette.primary.main,
                                        },
                                        '& input': {
                                            fontSize: '13px',
                                            height: '36px',
                                            padding: '0 !important',
                                            '&::placeholder': {
                                                color: theme.palette.text.disabled,
                                                opacity: 0.7,
                                                fontSize: '13px',
                                            }
                                        }
                                    }
                                }}
                            />
                        );
                    }}
                    renderTags={() => null}
                    filterOptions={(options, { inputValue }) =>
                        options.filter((option) =>
                            option.name?.toLowerCase().includes(inputValue.toLowerCase())
                        )
                    }
                    sx={{
                        '& .MuiAutocomplete-endAdornment': {
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }
                    }}
                />
            </Box>

            {/* Selected Entities Chips */}
            {selectedEntities.length > 0 && (
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                    padding: '8px',
                    backgroundColor: theme.palette.background.darkCardBackground,
                    // borderRadius: '8px',
                    // border: `1px solid ${theme.palette.primary.tertiary}`,
                }}>
                    {selectedEntities.map((entity) => (
                        <Chip
                            key={(entity as any)._id['$oid']}
                            label={entity.name}
                            onDelete={() => handleDelete(entity)}
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.common.white,
                                fontSize: '13px',
                                height: '24px',
                                '& .MuiChip-deleteIcon': {
                                    color: theme.palette.common.white,
                                    width: '16px',
                                    height: '16px',
                                    '&:hover': {
                                        color: theme.palette.grey[300],
                                    }
                                }
                            }}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default EntitySearch;