import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useHandleRouteClick } from "../../../routes/hooks";
import { updateAlert } from '../../../store/alert/alertThunk';
import { useAppDispatch } from '../../../store/hooks';

interface AlertCardProps {
    alertData: any;
    view?: string;
}

const AlertCard = ({ alertData, view }: AlertCardProps) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isResolved, setIsResolved] = useState(alertData.is_resolved);
    const dispatch = useAppDispatch();
    const handleRouteClick = useHandleRouteClick();
    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleResolve = () => {
        setIsResolved(!isResolved);
        // Dispatch update alert action here
        dispatch(updateAlert({ alertId: alertData._id, isResolved: !isResolved }));
    };

    const handleNavigation = (id: string) => {
        handleRouteClick(`/single-alert/?alert_id=${id}`)
        // Implement your routing logic here (e.g., window.location.href = `/alerts/${id}`)
    };

    return (
        <Box
            sx={{
                cursor: "arrow",
                background: theme.palette.background.darkCardBackground,
                border: theme.palette.additionalColors.databasecardBorder,
                p: 2,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "auto",
                flexWrap: "wrap",
                justifyContent: "space-between",
            }}
        >
            <Box width="100%">
                <Box display="flex" gap={2} alignItems={"center"}>
                    <Box >
                        <Typography variant="bigCaption" color={theme.palette.text.titleLabel} onClick={() => handleNavigation(alertData?._id)} sx={{ cursor: "pointer" }}>
                            {alertData?.reason}
                        </Typography>
                    </Box>
                </Box>

                <Box display="flex" gap={1} sx={{ marginY: "10px", alignItems: "center", justifyContent: "space-between" }}>
                    <Box>
                        {alertData?.datetime && (
                            <Typography variant="body4" color={theme.palette.text.tableHeader}>
                                {new Date(alertData?.datetime).toLocaleString()}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box display="flex" gap={1} alignItems={"center"}>
                    <Typography variant="body4" color={theme.palette.text.tableHeader}>
                        Content:
                        <Typography variant="body4" color={theme.palette.text.default} sx={{ ml: "5px" }}>
                            {isExpanded ? alertData?.content : alertData?.content.split(' ').slice(0, 100).join(' ') + (alertData?.content.split(' ').length > 100 ? '...' : '')}
                            {alertData?.content.split(' ').length > 100 && (
                                <Link
                                    onClick={handleToggleExpand}
                                    component="button" // makes it behave like a button
                                    sx={{
                                        color: "white",
                                        textDecoration: 'none',
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}
                                >
                                    {isExpanded ? 'Read Less' : 'Read More'}
                                </Link>
                            )}
                        </Typography>
                    </Typography>

                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} mt={1} sx={{ width: "100%" }}>
                    <Button
                        variant="outlined"
                        sx={{
                            color: isResolved ? theme.palette.error.main : theme.palette.success.main,
                            borderColor: isResolved ? theme.palette.error.main : theme.palette.success.main,
                            "&:hover": {
                                backgroundColor: isResolved ? theme.palette.error.light : theme.palette.success.light,
                                borderColor: isResolved ? theme.palette.error.light : theme.palette.success.light,
                                color: isResolved ? theme.palette.error.dark : theme.palette.success.dark,
                            },
                        }}
                        onClick={handleResolve}
                    >
                        {isResolved ? 'Unresolve' : 'Resolve'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default AlertCard;