
// import { Bookmark, BookmarkBorder } from "@mui/icons-material";
// import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
// import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
// import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
// import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
// import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import CustomSecondaryButton from "../../../../../../components/CustomSecondaryButton";
// import CustomThreadButtons from "../../../../../../components/CustomThreadButtons";
// import { routes } from "../../../../../../routes";
// import { useHandleRouteClick } from "../../../../../../routes/hooks";
// import { useAppSelector } from "../../../../../../store/hooks";
// import { formatDateTimeIntoDDMMMYYYY, publicationImage, textTruncate } from "../../../../../../utils/helper";

// interface CardProps {
//     [x: string]: any;
//     view?: string;
//     matchedContent?: string;
//     blacklistedWords?: string[];
// }

// const ModifiedPulicationListViewCard = ({ cardData, view, matchedContent, blacklistedWords = [] }: CardProps) => {
//     const theme = useTheme();
//     const { showTranslatedFeed } = useAppSelector((state) => state.analysis);
//     const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
//     const handleRouteClick = useHandleRouteClick();
//     const date = cardData?.creation_time?.split(" ");
//     const [feedImage, setFeedImage] = useState<null | string>(null);
//     const [imageBroken, setImageBroken] = React.useState(false);
//     const [searchParams] = useSearchParams();
//     const { queryId } = useParams();
//     const [activeThumb, setActiveThumb] = useState<"up" | "down" | null>(null);
//     const [isBookmarked, setIsBookmarked] = useState(false);

//     const queryID = queryId || searchParams.get("query") || query?.id;

//     useEffect(() => {
//         if (cardData?.images_local?.length > 0) {
//             setFeedImage(cardData?.images_local[0]);
//         }
//     }, [cardData?.images_local]);

//     const handleBookmarkClick = (e: React.MouseEvent) => {
//         e.stopPropagation();
//         setIsBookmarked(!isBookmarked);
//     };

//     const handleThumbsUpClick = (e: React.MouseEvent) => {
//         e.stopPropagation();
//         setActiveThumb(activeThumb === "up" ? null : "up");
//     };

//     const handleThumbsDownClick = (e: React.MouseEvent) => {
//         e.stopPropagation();
//         setActiveThumb(activeThumb === "down" ? null : "down");
//     };

//     const handleNavigation = (id: string) => {
//         const publicationURL = routes.publication.path.replace(":queryId", queryID || "");
//         const newURL = publicationURL.replace(":id", id);
//         const blacklistedWordsParam = blacklistedWords?.join(",");

//         if (queryID && id) {
//             window.open(newURL + `?highlightText=${matchedContent || ''}&highlightWords=${encodeURIComponent(blacklistedWordsParam || '')}`, "_blank");
//         } else {
//             if (!queryID && !id) toast.error("Query Id and Publication Id not found");
//             else if (!queryID) toast.error("Query Id not found");
//             else toast.error("Publication Id not found");
//         }
//     };

//     const handleMilitaryPresence = (e: React.MouseEvent) => {
//         e.stopPropagation();
//         handleRouteClick(
//             routes.millitaryPresenceMap.path + `?lat=${cardData?.location[1]}&lng=${cardData?.location[2]}&label=${cardData?.location[0]}`,
//             {}
//         );
//     };

//     const formatHTML = (rawHTML: string) => {
//         return rawHTML?.replace(/\n/g, "<br/>");
//     };

//     const handleImageError = () => {
//         setImageBroken(true);
//     };

//     return (
//         <Box sx={{
//             cursor: "pointer",
//             background: theme.palette.background.darkCardBackground,
//             border: theme.palette.additionalColors.databasecardBorder,
//             padding: theme.spacing(2),
//             borderRadius: theme.spacing(2),
//             display: "flex",
//             width: "100%",

//             "&:hover": {
//                 backgroundColor: "#0D1318",
//             },
//         }} onClick={() => handleNavigation(cardData?._id)}>
//             <Box display="flex" width="100%" flexDirection="column" >
//                 {/* Header Section with Source Icon, Title, and Bookmark */}
//                 <Box display="flex" width="100%" alignItems="center" >
//                     <Box sx={{
//                         width: 40,
//                         height: 40,
//                         backgroundColor: "#161B22",
//                         borderRadius: theme.spacing(1),
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         flexShrink: 0,

//                     }}>
//                         <img
//                             src={cardData?.source === "news" ? cardData?.fav_icon || publicationImage(cardData?.source) : publicationImage(cardData?.source)}
//                             alt="source"
//                             width="20px"
//                             height="20px"
//                             style={{ borderRadius: "2px" }}
//                         />
//                     </Box>

//                     <Box flex={1} ml={2}>
//                         {cardData?.title && cardData?.title !== "NA" ? (
//                             <Typography variant="h6" color={theme.palette.text.titleLabel} sx={{ fontWeight: 500 }}>
//                                 {textTruncate(cardData?.title, 60)}
//                             </Typography>
//                         ) : (
//                             <Box display="flex" gap={1}>
//                                 <Avatar src={cardData?.user?.profile_image || cardData?.channelDetails?.thumbnail} />
//                                 <Box>
//                                     <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
//                                         {cardData?.user?.name || cardData?.channelDetails?.channel_title}
//                                     </Typography>
//                                     <Typography variant="body2" color={theme.palette.text.tableHeader}>
//                                         {cardData?.user?.handle}
//                                     </Typography>
//                                 </Box>
//                             </Box>
//                         )}
//                     </Box>

//                     <IconButton onClick={handleBookmarkClick} size="large" sx={{ ml: 'auto' }}>
//                         {isBookmarked ? (
//                             <Bookmark sx={{ color: theme.palette.info.main }} />
//                         ) : (
//                             <BookmarkBorder sx={{ color: theme.palette.text.primary }} />
//                         )}
//                     </IconButton>
//                 </Box>



//                 {/* Content Section */}
//                 <Box display="flex" gap={3} flex={1} m={2}>
//                     <Box flex={1}>
//                         <Typography
//                             variant="body4"
//                             color={theme.palette.text.titleLabel}
//                             sx={{
//                                 ...theme.typography,
//                                 display: "-webkit-box",
//                                 WebkitBoxOrient: "vertical",
//                                 WebkitLineClamp: 6,
//                                 overflow: "hidden",
//                                 fontSize: "18px",
//                             }}
//                             dangerouslySetInnerHTML={{
//                                 __html: formatHTML(
//                                     textTruncate(String(showTranslatedFeed && cardData?.translated_content ? cardData?.translated_content : cardData?.content), 100)
//                                 ),
//                             }}
//                         />
//                     </Box>
//                     {cardData?.images_local?.length > 0 && !imageBroken && feedImage && (
//                         <Box position="relative" flexShrink={0}>
//                             <img
//                                 src={feedImage}
//                                 alt="content"
//                                 style={{
//                                     width: "160px",
//                                     height: "120px",
//                                     borderRadius: "8px",
//                                     objectFit: "cover",
//                                 }}
//                                 loading="lazy"
//                                 onError={handleImageError}
//                             />
//                             {cardData?.images_local?.length > 1 && (
//                                 <Typography
//                                     variant="caption"
//                                     sx={{
//                                         position: "absolute",
//                                         bottom: 8,
//                                         right: 8,
//                                         bgcolor: "rgba(0, 0, 0, 0.75)",
//                                         color: "white",
//                                         padding: "2px 6px",
//                                         borderRadius: 1,
//                                     }}
//                                 >
//                                     +{cardData?.images_local.length - 1}
//                                 </Typography>
//                             )}
//                         </Box>
//                     )}
//                 </Box>

//                 {/* Source Info Row */}
//                 <Box display="flex" alignItems="center" gap={2} m={2}>
//                     <Typography variant="body2" color={theme.palette.text.tableHeader}>
//                         {cardData?.creation_time ?
//                             `${date[2]} ${date[0]} ${date[1]}` :
//                             cardData?.publishedAt ?
//                                 formatDateTimeIntoDDMMMYYYY(new Date(cardData.publishedAt)) :
//                                 ""}
//                     </Typography>
//                     <Box display="flex" alignItems="center" gap={1}>
//                         <img
//                             src={cardData?.fav_icon || publicationImage(cardData?.source)}
//                             alt="source"
//                             width="16px"
//                             height="16px"
//                             style={{ borderRadius: "2px" }}
//                         />
//                         <Typography variant="body2" color={theme.palette.text.tableHeader}>
//                             {cardData?.source_name || cardData?.source || "Unknown Source"}
//                         </Typography>
//                     </Box>
//                     <Box display="flex" alignItems="center" gap={1}>
//                         {/* <img
//                             src={cardData?.fav_icon || publicationImage(cardData?.source)}
//                             alt="source"
//                             width="16px"
//                             height="16px"
//                             style={{ borderRadius: "2px" }}
//                         /> */}
//                         <Typography variant="h4" color={theme.palette.text.tableHeader}>
//                             {cardData?.kind || cardData?.source || "Unknown Source"}
//                         </Typography>
//                     </Box>
//                 </Box>

//                 {/* Footer Section */}
//                 <Box
//                     sx={{
//                         mt: 'auto',
//                         pt: 2,
//                         borderTop: '1px solid rgba(255, 255, 255, 0.1)',
//                     }}
//                     m={2}
//                 >
//                     <Box display="flex" flexDirection="column" gap={2}>
//                         {/* Metrics row */}
//                         <Box display="flex" alignItems="center" justifyContent="space-between">
//                             <Box display="flex" alignItems="center" gap={2}>
//                                 <Typography variant="body2" color={theme.palette.text.tableHeader}>
//                                     Relevance: <span style={{ color: theme.palette.info.main }}>{cardData?.relevance}</span>
//                                 </Typography>
//                                 <Typography variant="body2" color={theme.palette.text.tableHeader} sx={{ mx: 2 }}>
//                                     Authenticity: <span style={{ color: theme.palette.info.main }}>{cardData?.authenticity}</span>
//                                 </Typography>
//                                 <Box display="flex" alignItems="center" gap={1}>
//                                     <Typography variant="body2" color={theme.palette.text.tableHeader}>
//                                         Risk:
//                                     </Typography>
//                                     <CustomThreadButtons
//                                         label={cardData?.threat && cardData?.threat !== "NA" && cardData?.threat !== "" ? cardData?.threat : "Low"}
//                                         status={cardData?.threat && cardData?.threat !== "NA" && cardData?.threat !== "" ? cardData?.threat : "low"}
//                                         sx={{ height: "28px", width: "88px", pointerEvents: "none" }}
//                                     />
//                                 </Box>
//                             </Box>

//                             {/* Thumbs up/down icons */}
//                             <Box>
//                                 <IconButton
//                                     onClick={handleThumbsUpClick}
//                                     sx={{ color: activeThumb === "up" ? theme.palette.info.main : theme.palette.text.primary }}
//                                 >
//                                     {activeThumb === "up" ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
//                                 </IconButton>
//                                 <IconButton
//                                     onClick={handleThumbsDownClick}
//                                     sx={{ color: activeThumb === "down" ? theme.palette.info.main : theme.palette.text.primary }}
//                                 >
//                                     {activeThumb === "down" ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
//                                 </IconButton>
//                             </Box>
//                         </Box>

//                         {/* Action Buttons */}
//                         <Box display="flex" justifyContent="flex-end" gap={1}>
//                             {cardData?.military_movement === 1 && (
//                                 <CustomSecondaryButton
//                                     sx={{ ...theme.typography, height: "28px" }}
//                                     onClick={handleMilitaryPresence}
//                                 >
//                                     Military Presence
//                                 </CustomSecondaryButton>
//                             )}
//                             {cardData?.live_stream && (
//                                 <CustomSecondaryButton
//                                     sx={{ ...theme.typography, height: "28px" }}
//                                     onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleRouteClick(routes.videoFeed.path.replace(":feedId", cardData?.data_id));
//                                     }}
//                                 >
//                                     Check Live Video
//                                 </CustomSecondaryButton>
//                             )}
//                         </Box>
//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     );
// }

// export default ModifiedPulicationListViewCard
import { Bookmark, BookmarkBorder, PlayArrow } from "@mui/icons-material";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSecondaryButton from "../../../../../../components/CustomSecondaryButton";
import CustomThreadButtons from "../../../../../../components/CustomThreadButtons";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import { useAppSelector } from "../../../../../../store/hooks";
import { formatDateTimeIntoDDMMMYYYY, publicationImage, textTruncate } from "../../../../../../utils/helper";

interface CardProps {
    [x: string]: any;
    view?: string;
    matchedContent?: string;
    blacklistedWords?: string[];
}

const ModifiedPublicationListViewCard = ({ cardData, view, matchedContent, blacklistedWords = [] }: CardProps) => {
    const theme = useTheme();
    const { showTranslatedFeed } = useAppSelector((state) => state.analysis);
    const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
    const handleRouteClick = useHandleRouteClick();
    const date = cardData?.creation_time?.split(" ");
    const [feedImage, setFeedImage] = useState<string | undefined>(undefined);
    const [imageBroken, setImageBroken] = React.useState(false);
    const [searchParams] = useSearchParams();
    const { queryId } = useParams();
    const [activeThumb, setActiveThumb] = useState<"up" | "down" | null>(null);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const queryID = queryId || searchParams.get("query") || query?.id;
    const isYouTubeContent = cardData?.source?.toLowerCase() === 'youtube';

    useEffect(() => {
        if (cardData?.images_local?.length > 0) {
            setFeedImage(cardData?.images_local[0]);
        }
    }, [cardData?.images_local]);

    const handleVideoPlay = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (cardData?.url) {
            window.open(cardData.url, '_blank');
        }
    };

    const handleNavigation = (id: string) => {
        const publicationURL = routes.publication.path.replace(":queryId", queryID || "");
        const newURL = publicationURL.replace(":id", id);
        const blacklistedWordsParam = blacklistedWords?.join(",");

        if (queryID && id) {
            window.open(newURL + `?highlightText=${matchedContent || ''}&highlightWords=${encodeURIComponent(blacklistedWordsParam || '')}`, "_blank");
        } else {
            if (!queryID && !id) toast.error("Query Id and Publication Id not found");
            else if (!queryID) toast.error("Query Id not found");
            else toast.error("Publication Id not found");
        }
    };

    const handleBookmarkClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsBookmarked(!isBookmarked);
    };

    const handleThumbsUpClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setActiveThumb(activeThumb === "up" ? null : "up");
    };

    const handleThumbsDownClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setActiveThumb(activeThumb === "down" ? null : "down");
    };

    const handleMilitaryPresence = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleRouteClick(
            routes.millitaryPresenceMap.path + `?lat=${cardData?.location[1]}&lng=${cardData?.location[2]}&label=${cardData?.location[0]}`,
            {}
        );
    };

    const formatHTML = (rawHTML: string) => {
        return rawHTML?.replace(/\n/g, "<br/>");
    };

    const handleImageError = () => {
        setImageBroken(true);
    };

    return (
        <Box sx={{
            cursor: "pointer",
            background: theme.palette.background.darkCardBackground,
            border: theme.palette.additionalColors.databasecardBorder,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(2),
            display: "flex",
            width: "100%",
            "&:hover": {
                backgroundColor: "#0D1318",
            },
        }} onClick={() => handleNavigation(cardData?._id)}>
            <Box display="flex" width="100%" flexDirection="column">
                {/* Header Section */}
                <Box display="flex" width="100%" alignItems="center">
                    <Box sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "#161B22",
                        borderRadius: theme.spacing(1),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexShrink: 0,
                    }}>
                        <img
                            src={cardData?.source === "news" ? cardData?.fav_icon || publicationImage(cardData?.source) : publicationImage(cardData?.source)}
                            alt="source"
                            width="20px"
                            height="20px"
                            style={{ borderRadius: "2px" }}
                        />
                    </Box>

                    <Box flex={1} ml={2}>
                        {cardData?.title && cardData?.title !== "NA" ? (
                            <Typography variant="h6" color={theme.palette.text.titleLabel} sx={{ fontWeight: 500 }}>
                                {textTruncate(cardData?.title, 60)}
                            </Typography>
                        ) : (
                            <Box display="flex" gap={1}>
                                <Avatar src={cardData?.user?.profile_image || cardData?.channelDetails?.thumbnail} />
                                <Box>
                                    <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
                                        {cardData?.user?.name || cardData?.channelDetails?.channel_title}
                                    </Typography>
                                    <Typography variant="body2" color={theme.palette.text.tableHeader}>
                                        {cardData?.user?.handle}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>

                    <IconButton onClick={handleBookmarkClick} size="large" sx={{ ml: 'auto' }}>
                        {isBookmarked ? (
                            <Bookmark sx={{ color: theme.palette.info.main }} />
                        ) : (
                            <BookmarkBorder sx={{ color: theme.palette.text.primary }} />
                        )}
                    </IconButton>
                </Box>

                {/* Content Section */}
                <Box display="flex" gap={3} flex={1} m={2}>
                    <Box flex={1}>
                        <Typography
                            variant="body4"
                            color={theme.palette.text.titleLabel}
                            sx={{
                                ...theme.typography,
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 6,
                                overflow: "hidden",
                                fontSize: "18px",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: formatHTML(
                                    textTruncate(String(showTranslatedFeed && cardData?.translated_content ? cardData?.translated_content : cardData?.content), 100)
                                ),
                            }}
                        />
                    </Box>

                    {/* Image/Thumbnail Section */}
                    {(isYouTubeContent || (cardData?.images_local?.length > 0 && !imageBroken && feedImage)) && (
                        <Box position="relative" flexShrink={0}>
                            {isYouTubeContent ? (
                                <Box sx={{ position: "relative" }}>
                                    <img
                                        src={cardData?.thumbnail}
                                        alt="YouTube thumbnail"
                                        style={{
                                            width: "160px",
                                            height: "120px",
                                            borderRadius: "8px",
                                            objectFit: "cover",
                                        }}
                                        onError={handleImageError}
                                    />
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "40px",
                                            height: "40px",
                                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                                            borderRadius: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            "&:hover": {
                                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                                                transform: "translate(-50%, -50%) scale(1.1)",
                                            },
                                            transition: "all 0.2s ease-in-out",
                                        }}
                                        onClick={handleVideoPlay}
                                    >
                                        <PlayArrow sx={{ color: "white", fontSize: 24 }} />
                                    </Box>
                                </Box>
                            ) : (
                                <>
                                    <img
                                        src={feedImage}
                                        alt="content"
                                        style={{
                                            width: "160px",
                                            height: "120px",
                                            borderRadius: "8px",
                                            objectFit: "cover",
                                        }}
                                        loading="lazy"
                                        onError={handleImageError}
                                    />
                                    {cardData?.images_local?.length > 1 && (
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                position: "absolute",
                                                bottom: 8,
                                                right: 8,
                                                bgcolor: "rgba(0, 0, 0, 0.75)",
                                                color: "white",
                                                padding: "2px 6px",
                                                borderRadius: 1,
                                            }}
                                        >
                                            +{cardData?.images_local.length - 1}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </Box>

                {/* Source Info Row */}
                <Box display="flex" alignItems="center" gap={2} m={2}>
                    <Typography variant="body2" color={theme.palette.text.tableHeader}>
                        {cardData?.creation_time ?
                            `${date[2]} ${date[0]} ${date[1]}` :
                            cardData?.publishedAt ?
                                formatDateTimeIntoDDMMMYYYY(new Date(cardData.publishedAt)) :
                                ""}
                    </Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                        <img
                            src={cardData?.fav_icon || publicationImage(cardData?.source)}
                            alt="source"
                            width="16px"
                            height="16px"
                            style={{ borderRadius: "2px" }}
                        />
                        <Typography variant="body2" color={theme.palette.text.tableHeader}>
                            {cardData?.source_name || cardData?.source || "Unknown Source"}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h4" color={theme.palette.text.tableHeader}>
                            {cardData?.kind || cardData?.source || "Unknown Source"}
                        </Typography>
                    </Box>
                    {isYouTubeContent && (
                        <Box
                            onClick={handleVideoPlay}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                cursor: 'pointer',
                                color: theme.palette.text.primary,
                                '&:hover': {
                                    color: theme.palette.info.main
                                }
                            }}
                        >
                            <PlayArrow sx={{ fontSize: 20 }} />
                            <Typography sx={{ fontSize: "16px" }}>Watch On Youtube</Typography>
                        </Box>
                    )}
                </Box>

                {/* Footer Section */}
                <Box
                    sx={{
                        mt: 'auto',
                        pt: 2,
                        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                    m={2}
                >
                    <Box display="flex" flexDirection="column" gap={2}>
                        {/* Metrics row */}
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="body2" color={theme.palette.text.tableHeader}>
                                    Relevance: <span style={{ color: theme.palette.info.main }}>{cardData?.relevance}</span>
                                </Typography>
                                <Typography variant="body2" color={theme.palette.text.tableHeader} sx={{ mx: 2 }}>
                                    Authenticity: <span style={{ color: theme.palette.info.main }}>{cardData?.authenticity}</span>
                                </Typography>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography variant="body2" color={theme.palette.text.tableHeader}>
                                        Risk:
                                    </Typography>
                                    <CustomThreadButtons
                                        label={cardData?.threat && cardData?.threat !== "NA" && cardData?.threat !== "" ? cardData?.threat : "Low"}
                                        status={cardData?.threat && cardData?.threat !== "NA" && cardData?.threat !== "" ? cardData?.threat : "low"}
                                        sx={{ height: "28px", width: "88px", pointerEvents: "none" }}
                                    />
                                </Box>
                            </Box>

                            {/* Thumbs up/down icons */}
                            <Box>
                                <IconButton
                                    onClick={handleThumbsUpClick}
                                    sx={{ color: activeThumb === "up" ? theme.palette.info.main : theme.palette.text.primary }}
                                >
                                    {activeThumb === "up" ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
                                </IconButton>
                                <IconButton
                                    onClick={handleThumbsDownClick}
                                    sx={{ color: activeThumb === "down" ? theme.palette.info.main : theme.palette.text.primary }}
                                >
                                    {activeThumb === "down" ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
                                </IconButton>
                            </Box>
                        </Box>

                        {/* Action Buttons */}
                        <Box display="flex" justifyContent="flex-end" gap={1}>

                            {cardData?.military_movement === 1 && (
                                <CustomSecondaryButton
                                    sx={{ ...theme.typography, height: "28px" }}
                                    onClick={handleMilitaryPresence}
                                >
                                    Military Presence
                                </CustomSecondaryButton>
                            )}
                            {cardData?.live_stream && (
                                <CustomSecondaryButton
                                    sx={{ ...theme.typography, height: "28px" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRouteClick(routes.videoFeed.path.replace(":feedId", cardData?.data_id));
                                    }}
                                >
                                    Check Live Video
                                </CustomSecondaryButton>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default ModifiedPublicationListViewCard;