import { Box, Typography, useTheme } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import CustomButton from "../../../../../../components/CustomButton";
import GridView from "./GridView";
import ListView from "./ListView";

interface PublicationProps {
  view: boolean;
  drawerOpen: boolean;
  isRefresh: boolean;
  loading: boolean;
  feedData: Record<string, any>;
  loadMore: () => void;
  selectedSources?: any;
  blacklistedWords?: string[];
}

export default function Publication({ view, drawerOpen, isRefresh, feedData, loading, loadMore, selectedSources, blacklistedWords }: PublicationProps) {
  const theme = useTheme();
  return (
    <>
      {!loading ? (
        <>
          {/* <Box sx={{ py: 2 }}>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="caption" sx={{ fontSize: "16px" }}>Total Results: {feedData?.total_count}</Typography>
            <Divider sx={{ mt: 2 }} />
          </Box> */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1 }}>
            <Typography variant="caption" sx={{ fontSize: "16px" }}>
              Total Results: {feedData?.total_count}
            </Typography>
          </Box>

          {feedData?.results?.length > 0 ? (
            <Box mt={3}>
              {view ? (
                <ListView publicationData={feedData?.results} drawerOpen={drawerOpen} isRefresh={isRefresh} />
              ) : (
                <GridView publicationData={feedData?.results} drawerOpen={drawerOpen} isRefresh={isRefresh} selectedSources={selectedSources} blacklistedWords={blacklistedWords} />
              )}
              <Box textAlign="center" my={2}>
                {feedData?.next_page && <CustomButton onClick={loadMore}>Load More</CustomButton>}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                height: "100%",
                my: "5rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <CircularProgress /> */}
              <Typography variant="caption" sx={{ my: 2 }}>
                No Posts Available
              </Typography>
            </Box>
            // <CustomNoResults />
          )}

        </>
      ) : (
        <ContentUILoader type="publication" count={5} drawerOpen={drawerOpen} />
      )}
    </>
  );
}
