import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useLocation } from 'react-router-dom';
import { getAlertById, updateAlert } from '../../../store/alert/alertThunk';
import { useHandleRouteClick } from "../../../routes/hooks";

const SingleAlert = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const alert_id = queryParams.get('alert_id');
     const handleRouteClick = useHandleRouteClick();

    const { selectedAlert, loading, error } = useAppSelector((state: any) => state.alert);
    const [isResolved, setIsResolved] = useState(false);

    useEffect(() => {
        if (alert_id) {
            dispatch(getAlertById(alert_id));
        } else {
            console.error("alert_id is undefined");
        }
    }, [dispatch, alert_id]);

    useEffect(() => {
        if (selectedAlert) {
            setIsResolved(selectedAlert.is_resolved);
        }
    }, [selectedAlert]);

    const handleResolve = () => {
        setIsResolved(!isResolved);
        dispatch(updateAlert({ alertId: selectedAlert._id, isResolved: !isResolved }));
    };
    const highlightRedFlags = (content: string, redFlags: string[]) => {
        let highlightedContent = content;
        redFlags.forEach(flag => {
            const regex = new RegExp(`\\b(${flag.trim()})\\b`, 'gi'); // Match complete words only
            highlightedContent = highlightedContent.replace(regex, `<span style="background-color: yellow; color: darkgoldenrod; font-weight: bold;">$1</span>`);
        });
        return highlightedContent;
    };

    // Extract red flags from the reason, split by commas
    const redFlags = selectedAlert?.reason.split(':')[1]  // Get the part after the colon
        ?.split(',')  // Split the red flags by commas
        .map((flag:any) => flag.trim())  // Trim each word to remove leading/trailing spaces
        .filter((flag:any) => flag);  // Remove any empty flags

    
    if (loading) {
        return <Typography variant="caption" textAlign="center">Loading alert...</Typography>;
    }

    if (error) {
        return <Typography variant="caption" textAlign="center" color="error">Error: {error.message}</Typography>;
    }

    if (!selectedAlert) {
        return <Typography variant="caption" textAlign="center">Alert not found.</Typography>;
    }

    return (
        <Box>
        {/* Heading and Back Button */ }
        < Box mb = { 2} >
                <Typography variant="h1" color={theme.palette.text.titleLabel} sx={{ml:"10px"}}>
                    Alert 
                </Typography>
                <Button
                    variant="text"
                    onClick={() => handleRouteClick('/alert')} // Navigate back
                    
                >
                  &lt;  Back
                </Button>
            </Box >
        <Box
            sx={{
                cursor: "pointer",
                background: theme.palette.background.darkCardBackground,
                border: theme.palette.additionalColors.databasecardBorder,
                p: 2,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "80vh",
                overflow:"auto",
                flexWrap: "wrap",
                justifyContent: "space-between",
            }}
        >
            
            <Box width="100%">
                <Typography variant="bigCaption" color={theme.palette.text.titleLabel}>
                    {selectedAlert.reason}
                </Typography>

                <Box display="flex" gap={1} sx={{ marginY: "10px", alignItems: "center", justifyContent: "space-between" }}>
                    <Box>
                        {selectedAlert.datetime && (
                            <Typography variant="body4" color={theme.palette.text.tableHeader}>
                                {new Date(selectedAlert.datetime).toLocaleString()}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box display="flex" gap={1} alignItems={"center"} sx={{ margin: "20px 0" }}>
                    <Typography variant="body4" color={theme.palette.text.tableHeader}>
                        Red Flags:
                    </Typography>
                    <Box display="flex" gap={1}>
                        {redFlags.map((flag:any, index:any) => (
                            <Typography
                                key={index}
                                variant="body4"
                                sx={{
                                    backgroundColor: theme.palette.error.dark, // Red background
                                    color: 'white', // White text
                                    padding: '5px 10px', // Padding for better appearance
                                    borderRadius: '4px', // Optional: rounded corners
                                }}
                            >
                                {flag}
                            </Typography>
                        ))}
                    </Box>
                </Box>

                <Box display="flex" gap={1} alignItems={"center"}>
                    <Typography variant="body4" color={theme.palette.text.tableHeader}>
                        Content:
                        <Typography
                            variant="body4"
                            color={theme.palette.text.default}
                            sx={{ ml: "5px" }}
                            dangerouslySetInnerHTML={{ __html: highlightRedFlags(selectedAlert.content, redFlags) }}
                        />
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} mt={2} sx={{ width: "100%" }}>
                    <Button
                        variant="outlined"
                        sx={{
                            color: isResolved ? theme.palette.error.main : theme.palette.success.main,
                            borderColor: isResolved ? theme.palette.error.main : theme.palette.success.main,
                            "&:hover": {
                                backgroundColor: isResolved ? theme.palette.error.light : theme.palette.success.light,
                                borderColor: isResolved ? theme.palette.error.light : theme.palette.success.light,
                                color: isResolved ? theme.palette.error.dark : theme.palette.success.dark,
                            },
                        }}
                        onClick={handleResolve}
                    >
                        {isResolved ? 'Unresolve' : 'Resolve'}
                    </Button>
                </Box>
            </Box>
        </Box>
        </Box>
    );
};

export default SingleAlert;