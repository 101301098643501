import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language"; // Default icon
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';

function SourcesListDialog({ showMore, setShowMoreSources, sources }: any) {
    const theme = useTheme();

    const sourceIcons: Record<string, JSX.Element> = {
        news: <NewspaperIcon sx={{
            color: theme.palette.text.secondary, // Neutral color for news
            fontSize: '1.5rem',
        }} />,
        instagram: <InstagramIcon sx={{
            color: '#E1306C', // Instagram Pink
            fontSize: '1.5rem',
        }} />,
        youtube: <YouTubeIcon sx={{
            color: '#FF0000', // YouTube Red
            fontSize: '1.5rem',
        }} />,
        linkedin: <LinkedInIcon sx={{
            color: '#0077B5', // LinkedIn Blue
            fontSize: '1.5rem',
        }} />,
        twitter: <TwitterIcon sx={{
            color: '#1DA1F2', // Twitter Blue
            fontSize: '1.5rem',
        }} />,
        facebook: <FacebookIcon sx={{
            color: '#1877F2', // Facebook Blue
            fontSize: '1.5rem',
        }} />,
        default: <LanguageIcon sx={{
            color: theme.palette.text.primary, // Default text color
            fontSize: '1.5rem',
        }} />, // Fallback for unknown sources
    };

    return (
        <Dialog
            open={showMore}
            onClose={() => setShowMoreSources(false)}
            PaperProps={{
                sx: {
                    background: theme.palette.background.gradientLight1,
                    borderRadius: "8px",
                    py: 4,
                    px: 2,
                    width: "30%",
                    height: "80%",
                },
            }}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.039)",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: 'bold',
                    borderRadius: 4,
                    color: theme.palette.text.primary,
                    fontSize: '1.20rem',
                }}
            >
                {"All Sources"}
            </DialogTitle>
            <DialogContent sx={{ margin: 0, padding: 0 }}>
                {Object.entries(sources || {}).map(([source, count]) => (
                    <Box key={source} sx={{ marginBottom: "20px" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                background: theme.palette.background.darkCardBackground,
                                p: 2,
                                border: theme.palette.additionalColors.databasecardBorder,
                                borderRadius: "8px",
                                marginY: "5px",
                            }}
                        >
                            {/* Left side: Icon and Source Name */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2, // Space between icon and text
                                }}
                            >
                                {/* Render the appropriate icon */}
                                {sourceIcons[source] || sourceIcons.default}

                                {/* Source Name */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        color={theme.palette.text.titleLabel}

                                    >
                                        {source}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Right side: Count */}
                            <Box>
                                <Typography variant="h6" color={"green"}>
                                    {`${count}`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setShowMoreSources(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SourcesListDialog;
