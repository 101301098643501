
import { Button, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

export interface statusProps {
    label: string;
    status: string;
    onClick?: () => void;
    sx?: SxProps<Theme>;
}
const CustomThreadButtons = ({ label, status, onClick, sx }: statusProps) => {

    const theme = useTheme();

    let statusStyle: React.CSSProperties = {};

    switch (status) {
        case "high":
            statusStyle = {
                background: theme.palette.dashboardContainer.highStatusBg,
                color: theme.palette.text.highStatus,
                border: `1px solid ${theme.palette.text.highStatus}`,
            };
            break;
        case "medium":
            statusStyle = {
                background: theme.palette.dashboardContainer.moderateStatusBg,
                color: theme.palette.text.moderateStatus,
                border: `1px solid ${theme.palette.text.moderateStatus}`,
            };
            break;
        case "extreme":
            statusStyle = {
                background: theme.palette.dashboardContainer.extremeStatusBg,
                color: theme.palette.text.extremeStatus,
                border: `1px solid ${theme.palette.text.extremeStatus}`,
            };
            break;
        case "low":
            statusStyle = {
                background: "linear-gradient(0deg, #56f91d52, #54fe191a)",
                color: theme.palette.text.lowStatus,
                border: `1px solid #67d139`,
            };
            break;
        case "default":
            break;
    }
    return (
        <Button
            sx={{
                ...statusStyle,
                textTransform: "capitalize",
                ...sx,
            }}
            onClick={onClick}
        >
            {label}
        </Button>
    );
}

export default CustomThreadButtons