import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI, patchAPI } from "../../axios/utils";
import { AUTH_SERVICE_BASE_URL } from "../../utils/constants";

// Define the parameters for the getAlerts thunk
interface GetAlertsParams {
    queryId?: string; // Optional queryId
    limit?: number;   // Optional limit
    offset?:number;
}

// Fetch all alerts with optional queryId and limit
export const getAlerts = createAsyncThunk("alert/fetch-all-alerts", async ({ queryId, limit, offset }: GetAlertsParams, thunkAPI) => {
    try {
        // Construct the URL with queryId and limit
        const url = `${AUTH_SERVICE_BASE_URL}/lcproto/query/fetch-all-alerts/?limit=${limit}&offset=${offset}${queryId ? `&query_id=${queryId}` : ''}`;
        return await getAPI(url);
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.response });
    }
});

// Fetch a specific alert by ID
export const getAlertById = createAsyncThunk("alert/fetch-alert-by-id", async (alertId: string, thunkAPI) => {
    try {
        return await getAPI(`${AUTH_SERVICE_BASE_URL}/lcproto/query/fetch-all-alerts/?alert_id=${alertId}`);
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.response });
    }
});

// Update an individual alert
export const updateAlert = createAsyncThunk("alert/update-alert", async ({ alertId, isResolved }: { alertId: string; isResolved: boolean }, thunkAPI) => {
    try {
        return await patchAPI(`${AUTH_SERVICE_BASE_URL}/lcproto/query/alerts/update/?alert_id=${alertId}`, { is_resolved: isResolved });
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.response });
    }
});