import { Close, NorthEast, SvgIconComponent } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTab } from '../../../../../contexts/TabContext';
import { routes } from '../../../../../routes';
import { useHandleRouteClick } from '../../../../../routes/hooks';
import { EntitiesType } from '../../../../../store/analysis/entitiesSlice';
import { useAppSelector } from '../../../../../store/hooks';
import EntitiesTable from './EntitiesTable';

export interface CategoryCardProps {
    title: string;
    icon: SvgIconComponent;
    id: any;
}
const EVENT_TYPES = ['Event', 'Agreement', 'Convention', 'Infrastructure', 'Interaction'];
const LOCATION_TYPES = ['Country', 'Region', 'State', 'Countries', 'Territory', 'Territorial Claim', 'Territorial Integrity', "Village", 'Boundary'];
const MAIN_CATEGORIES = ['Person', 'Event', 'Topic'];

const header = [
    { id: "name", label: "Entity Name", searchEnable: true, sortable: true, minWidth: 150, customIcon: true },
    { id: "type", label: "Entity Type", searchEnable: true, sortable: true, minWidth: 100 },
    // { id: "subtype", label: "Entity Sub Type", searchEnable: true, sortable: true, minWidth: 100 },
    // { id: "description", label: "Description", searchEnable: true, sortable: true, minWidth: 200 },
    // { id: "score", label: "Risk Score", searchEnable: true, sortable: true, type: "button", minWidth: 100 },
    { id: "mentions", label: "Mentions", searchEnable: false, sortable: true, minWidth: 100 },
];

export const CategoryCard: React.FC<CategoryCardProps> = ({ title, icon: Icon, id }) => {
    const handleRouteClick = useHandleRouteClick();
    const theme = useTheme();
    const { onTabChange } = useTab();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { allEntities } = useAppSelector((state) => state.analysisEntities);
    const [filterData, setFilterData] = useState<EntitiesType[]>([]);
    const [queryParameters] = useSearchParams();
    const { entitiesPage } = useAppSelector((state: any) => state.magicEdit);
    const { isMagicEdit } = entitiesPage;

    // Get only 4 items for card display
    const cardDisplayItems: any = filterData.slice(0, 4);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        const allEntities_ = [...allEntities].sort((f_: Record<string, any>, s_: Record<string, any>) => {
            const fMentions = parseInt(f_.mentions);
            const sMentions = parseInt(s_.mentions);
            if (fMentions < sMentions) return 1;
            if (fMentions > sMentions) return -1;
            return 0;
        });

        const filterData = allEntities_.filter((entity: EntitiesType) => {
            if (id === "all") {
                return entity;
            } else if (id === "Miscellaneous") {
                // For Miscellaneous, return items that don't belong to main categories or location types
                return !MAIN_CATEGORIES.includes(entity?.type) && !LOCATION_TYPES.includes(entity?.type);
            } else if (id === "Location") {
                // For Location category, include all location-related types
                return LOCATION_TYPES.includes(entity?.type);
            } else if (id === "Event") {
                return EVENT_TYPES.includes(entity?.type);
            } else {
                // For other main categories, filter as before
                return entity?.type === id;
            }
        });

        setFilterData(filterData);
    }, [allEntities, id]);

    // Helper function to format trend
    const formatTrend = (value: number) => {
        return `+${value}%`;
    };

    const getRowData = (data: any) => {
        const queryId = queryParameters.get("query");
        const specificEntity = data?._id['$oid'];
        onTabChange("feed");
        handleRouteClick(`${routes.home.path}?tab=home&subtab=feed&query=${queryId}&specific_entity=${specificEntity}`);
    };

    return (
        <>
            <Card
                sx={{
                    // bgcolor: 'rgba(12, 16, 21, 0.95)',
                    background: theme.palette.background.darkCardBackground,
                    // border: '1px solid',
                    border: theme.palette.additionalColors.databasecardBorder,
                    borderColor: 'rgba(107, 114, 128, 0.1)',
                    borderRadius: '16px',
                    '&:hover': {
                        borderColor: theme.palette.primary.main,
                    },
                    transition: 'all 0.3s',
                }}
            >
                <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Box
                                sx={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: '12px',
                                    bgcolor: 'rgba(147, 51, 234, 0.1)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Icon sx={{ color: theme.palette.primary.main }} />
                            </Box>
                            <Box>
                                <Typography variant="h6" color={theme.palette.text.titleLabel}>
                                    {title}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                    {filterData.length} entities
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        {cardDisplayItems.map((item: any, index: any) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    p: 2,
                                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                                    borderRadius: '12px',
                                    '&:hover': {
                                        bgcolor: 'rgba(147, 51, 234, 0.05)',
                                        '& .arrow-icon': {
                                            opacity: 1,
                                        },
                                    },
                                }}
                            >
                                <Box sx={{ minWidth: 0 }}>
                                    <Typography color="rgb(199, 221, 253)" noWrap>
                                        {item.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" noWrap>
                                        {item.type}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={2}>
                                    <Box textAlign="right">
                                        <Typography color="primary" fontWeight="medium">
                                            {item.mentions}
                                        </Typography>
                                        {/* <Typography variant="body2" color="success.main">
                                            {formatTrend(15)}
                                        </Typography> */}
                                    </Box>
                                    <NorthEast
                                        onClick={() => getRowData(item)}
                                        className="arrow-icon"
                                        sx={{
                                            opacity: 0,
                                            transition: 'opacity 0.3s',
                                            color: theme.palette.grey[400],
                                            fontSize: 20,
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>

                    {filterData.length > 4 && (
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleOpenDialog}
                            sx={{
                                mt: 2,
                                borderColor: 'rgba(147, 51, 234, 0.2)',
                                color: theme.palette.primary.main,
                                '&:hover': {
                                    bgcolor: 'rgba(147, 51, 234, 0.1)',
                                    borderColor: 'rgba(147, 51, 234, 0.2)',
                                },
                            }}
                        >
                            View more
                        </Button>
                    )}
                </CardContent>
            </Card>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        bgcolor: 'rgba(12, 16, 21, 0.95)',
                        borderRadius: '16px',
                        border: '1px solid rgba(147, 51, 234, 0.2)',
                    }
                }}
            >
                <DialogTitle sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: 'white'
                }}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <Icon sx={{ color: theme.palette.primary.main }} />
                        <Typography variant="h6">{title} Details</Typography>
                    </Box>
                    <IconButton
                        onClick={handleCloseDialog}
                        sx={{
                            color: theme.palette.grey[400],
                            '&:hover': { color: 'white' }
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <EntitiesTable
                            header={header}
                            tableData={filterData}
                            rows={10}
                            pagination={true}
                            search={true}
                            editing={isMagicEdit}
                            handleClick={getRowData}
                            type={id}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};