import { createSlice } from "@reduxjs/toolkit";
import { getAlerts, getAlertById, updateAlert } from "./alertThunk";

export type AlertType = {
    _id: string;
    article_id: string;
    content: string;
    datetime: string;
    is_resolved: boolean;
    reason: string;
};

export type AlertInitialStateType = {
    isLoading: boolean;
    alertsLoading: boolean;
    alerts: Array<AlertType>;
    selectedAlert: AlertType | null;
};

const alertInitialState: AlertInitialStateType = {
    isLoading: false,
    alertsLoading: false,
    alerts: [],
    selectedAlert: null,
};

const alertSlice = createSlice({
    name: "alerts",
    initialState: alertInitialState,
    reducers: {
        pushAlert(state, action) {
            state.alerts.push(action.payload); // Use push for better performance
        },
        setSelectedAlert(state, action) {
            state.selectedAlert = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Fetch alerts
        builder
            .addCase(getAlerts.pending, (state) => {
                state.alertsLoading = true;
            })
            .addCase(getAlerts.fulfilled, (state, action) => {
                state.alertsLoading = false;
                state.alerts = action.payload.data.results || []; 
                state.selectedAlert = state.alerts[0] || null;
            })
            .addCase(getAlerts.rejected, (state, action) => {
                state.alertsLoading = false;
            })
        builder
            // Fetch a specific alert by ID
            .addCase(getAlertById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAlertById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedAlert = action.payload.data; 
            })
            .addCase(getAlertById.rejected, (state, action) => {
                state.isLoading = false;
            })
        builder
            // Update an individual alert
            .addCase(updateAlert.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateAlert.fulfilled, (state, action) => {
                state.isLoading = false;
                const updatedAlert = action.payload.data; 
                const index = state.alerts.findIndex(alert => alert._id === updatedAlert._id);
                if (index !== -1) {
                    state.alerts[index] = updatedAlert; // Update the alert in the state
                }
            })
            .addCase(updateAlert.rejected, (state, action)=> {
                state.isLoading = false;
            });
    },
});

export const alertActions = alertSlice.actions;

export default alertSlice;