import { Bookmark, BookmarkBorder, PlayArrow } from "@mui/icons-material"; // Added PlayArrow
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import { useAppSelector } from "../../../../../../store/hooks";
import { formatDateTimeIntoDDMMMYYYY, publicationImage, textTruncate } from "../../../../../../utils/helper";

interface CardProps {
  [x: string]: any;
  view?: string;
  matchedContent?: string;
  blacklistedWords?: string[];
}

export default function ModifiedPublicationCard({ cardData, matchedContent, blacklistedWords = [] }: CardProps) {
  const theme = useTheme();
  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
  const { showTranslatedFeed } = useAppSelector((state) => state.analysis);
  const handleRouteClick = useHandleRouteClick();
  const date = cardData?.creation_time?.split(" ");
  const [feedImage, setFeedImage] = useState<null | string>(null);
  const [imageBroken, setImageBroken] = React.useState(false);
  const [searchParams] = useSearchParams();
  const { queryId } = useParams();
  const [activeThumb, setActiveThumb] = useState<"up" | "down" | null>(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const queryID = queryId || searchParams.get("query") || query?.id;
  const isYouTubeContent = cardData?.source?.toLowerCase() === 'youtube'; // Added check for YouTube content

  useEffect(() => {
    if (cardData?.images_local?.length > 0) {
      setFeedImage(cardData.images_local[0]);
    } else if (cardData?.images?.length > 0) {
      setFeedImage(cardData.images[1]);
    }
  }, [cardData?.images_local]);

  const handleNavigation = (id: string) => {
    const publicationURL = routes.publication.path.replace(":queryId", queryID || "");
    const newURL = publicationURL.replace(":id", id);
    const blacklistedWordsParam = blacklistedWords?.join(",");

    if (queryID && id) {
      window.open(newURL + `?highlightText=${matchedContent || ''}&highlightWords=${encodeURIComponent(blacklistedWordsParam || '')}`, "_blank");
    } else {
      if (!queryID && !id) toast.error("Query Id and Publication Id not found");
      else if (!queryID) toast.error("Query Id not found");
      else toast.error("Publication Id not found");
    }
  };

  // Added handler for video playback
  const handleVideoPlay = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (cardData?.url) {
      window.open(cardData.url, '_blank');
    }
  };

  const handleImageError = () => {
    setImageBroken(true);
  };

  const handleBookmarkClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsBookmarked(!isBookmarked);
  };

  const handleThumbsUpClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setActiveThumb(activeThumb === "up" ? null : "up");
  };

  const handleThumbsDownClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setActiveThumb(activeThumb === "down" ? null : "down");
  };

  const formatHTML = (rawHTML: string) => {
    return rawHTML?.replace(/\n/g, "<br/>");
  };

  return (
    <Box
      sx={{
        cursor: "pointer",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: theme.spacing(1),
        width: "100%",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        "&:hover": {
          backgroundColor: "#0D1318",
        },
      }}
      onClick={() => handleNavigation(cardData?._id)}
    >
      {/* Header with Source Info and Date */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          pb: 1.5
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
          <img
            src={cardData?.source === "news" ? cardData?.fav_icon || publicationImage(cardData?.source) : publicationImage(cardData?.source)}
            alt="source"
            width="20px"
            height="20px"
            style={{ borderRadius: "2px" }}
          />
          <Typography sx={{ fontSize: "18px" }} color={theme.palette.text.tableHeader}>
            {cardData?.source || "Unknown"}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "18px" }} color={theme.palette.text.tableHeader}>
          {cardData?.creation_time ?
            `${date[0]} ${date[1]} ${date[2]}` :
            cardData?.date ?
              cardData.date :
              cardData?.publishedAt ?
                formatDateTimeIntoDDMMMYYYY(new Date(cardData.publishedAt)) :
                ""}
        </Typography>
      </Box>

      {/* Thumbnail Section */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "180px",
          backgroundColor: "#161B22",
        }}
      >
        {isYouTubeContent ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              cursor: "pointer",
              "&:hover": {
                "& .overlay": {
                  opacity: 1,
                },
              },
            }}
            onClick={handleVideoPlay}
          >
            <img
              src={cardData?.thumbnail || feedImage}
              alt="YouTube thumbnail"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              onError={handleImageError}
            />
            <Box
              className="overlay"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "48px",
                height: "48px",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  transform: "translate(-50%, -50%) scale(1.1)",
                },
                transition: "all 0.2s ease-in-out",
              }}
            >
              <PlayArrow sx={{ color: "white", fontSize: 32 }} />
            </Box>
          </Box>
        ) : (
          feedImage && !imageBroken ? (
            <img
              src={feedImage}
              alt="thumbnail"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              onError={handleImageError}
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#161B22",
              }}
            >
              <img
                src={publicationImage(cardData?.source)}
                alt="source"
                style={{
                  width: "48px",
                  height: "48px",
                }}
              />
            </Box>
          )
        )}
      </Box>

      {/* Content Section */}
      <Box sx={{ p: 2, flex: 1, display: "flex", flexDirection: "column" }}>
        {/* Title & Bookmark */}
        <Box display="flex" gap={1} mb={1}>
          <Typography
            variant="subtitle1"
            color={theme.palette.text.titleLabel}
            sx={{
              flex: 1,
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              lineHeight: 1.2,
              fontWeight: 500,
            }}
            dangerouslySetInnerHTML={{
              __html: formatHTML(
                textTruncate(String(showTranslatedFeed && cardData?.translated_content ? cardData?.translated_content : cardData?.content), 100)
              ),
            }}
          />

        </Box>

        {/* Rating & Bookmark */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            pt: 2,
            mt: 'auto'
          }}

        >

          {isYouTubeContent && (
            <Box
              onClick={handleVideoPlay}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                color: theme.palette.text.primary,
                '&:hover': {
                  color: theme.palette.info.main
                }
              }}
            >
              <PlayArrow sx={{ fontSize: 20 }} />
              <Typography sx={{ fontSize: "16px" }}>Watch On Youtube</Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton
              onClick={handleThumbsUpClick}
              size="small"
              sx={{
                color: activeThumb === "up" ? theme.palette.info.main : theme.palette.text.primary,
                padding: 0.5
              }}
            >
              {activeThumb === "up" ? <ThumbUpAltIcon fontSize="small" /> : <ThumbUpOffAltIcon fontSize="small" />}
            </IconButton>
            <IconButton
              onClick={handleThumbsDownClick}
              size="small"
              sx={{
                color: activeThumb === "down" ? theme.palette.info.main : theme.palette.text.primary,
                padding: 0.5
              }}
            >
              {activeThumb === "down" ? <ThumbDownAltIcon fontSize="small" /> : <ThumbDownOffAltIcon fontSize="small" />}
            </IconButton>
            <IconButton
              onClick={handleBookmarkClick}
              size="small"
              sx={{ p: 0.5 }}
            >
              {isBookmarked ? (
                <Bookmark sx={{ color: theme.palette.info.main, fontSize: 20 }} />
              ) : (
                <BookmarkBorder sx={{ color: theme.palette.text.primary, fontSize: 20 }} />
              )}
            </IconButton>
          </Box>
        </Box>

        {/* Metrics */}
        <Box
          sx={{
            mt: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            pt: 1
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* <Box display="flex" gap={2}>
              <Typography variant="caption" color={theme.palette.text.tableHeader}>
                Relevance: <span style={{ color: theme.palette.info.main }}>{cardData?.relevance}</span>
              </Typography>
              <Typography variant="caption" color={theme.palette.text.tableHeader}>
                Authenticity: <span style={{ color: theme.palette.info.main }}>{cardData?.authenticity}</span>
              </Typography>
              <Typography variant="caption" color={theme.palette.text.tableHeader}>
                Risk:
              </Typography>
              <CustomThreadButtons
                label={cardData?.threat || "Low"}
                status={cardData?.threat?.toLowerCase() || "low"}
                sx={{ height: "24px", width: "70px", pointerEvents: "none" }}
              />
            </Box> */}

            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={2}>
                <Typography variant="caption" color={theme.palette.text.tableHeader}>
                  Relevance: <span style={{ color: theme.palette.info.main }}>{cardData?.relevance}</span>
                </Typography>
                <Typography variant="caption" color={theme.palette.text.tableHeader}>
                  Authenticity: <span style={{ color: theme.palette.info.main }}>{cardData?.authenticity}</span>
                </Typography>
                <Typography variant="caption" color={theme.palette.text.tableHeader}>
                  Risk: <span style={{
                    color: cardData?.threat?.toLowerCase() === 'high' ? theme.palette.text.highStatus :
                      cardData?.threat?.toLowerCase() === 'medium' ? theme.palette.text.moderateStatus :
                        '#4CAF50', fontSize: "18px"
                  }}>{cardData?.threat?.toLowerCase() === 'medium' ? 'Moderate' : cardData?.threat || 'Low'}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}