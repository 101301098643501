// import { Box, Typography, useTheme } from "@mui/material";
// import React from "react";
// import { useSearchParams } from "react-router-dom";
// import { useAppDispatch } from "../store/hooks";
// import { setActiveAnalysisTab } from "../store/ui/uiSlice";

// export interface StaticsProps {
//   label: string;
//   count: number;
//   icon: React.ReactNode;
// }
// export default function CustomStatics({ label, count, icon }: StaticsProps) {
//   const theme = useTheme();
//   const dispatch = useAppDispatch();
//   const [searchParams, setSearchParams] = useSearchParams({ tab: "home" });

//   const handleClick = () => {
//     if (label === "Entities") {
//       dispatch(setActiveAnalysisTab("entities"));
//       setSearchParams((params) => {
//         params.set("page", "entities");
//         return params;
//       });
//     }
//     if (label === "Results") {
//       dispatch(setActiveAnalysisTab("feed"));
//       setSearchParams((params) => {
//         params.set("page", "feed");
//         return params;
//       });
//     }
//   };

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         background: theme.palette.background.darkCardBackground,
//         display: "flex",
//         p: 2,
//         border: theme.palette.additionalColors.databasecardBorder,
//         borderRadius: "8px",
//         cursor: label === "Entities" || label === "Results" ? "pointer" : "default",
//       }}
//       onClick={handleClick}
//     >
//       <Box>
//         <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
//           {label}
//         </Typography>
//         <Typography variant="h3" mt={0.5} color={theme.palette.text.titleLabel}>
//           {count}
//         </Typography>
//       </Box>
//       <Box sx={{ background: theme.palette.primary.tertiaryGradient, p: 1 }} pt={1} ml="auto">
//         {icon}
//       </Box>
//     </Box>
//   );
// }

import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { setActiveAnalysisTab } from "../store/ui/uiSlice";

export interface StaticsProps {
  label: string;
  count: number;
  icon: React.ReactNode;
  subLabel?: string;
}

export default function CustomStatics({
  label,
  count,
  icon,
  subLabel = "Total",
}: StaticsProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams({ tab: "home" });

  const handleClick = () => {
    if (label === "Entities") {
      dispatch(setActiveAnalysisTab("entities"));
      setSearchParams((params) => {
        params.set("page", "entities");
        return params;
      });
    }
    if (label === "Results") {
      dispatch(setActiveAnalysisTab("feed"));
      setSearchParams((params) => {
        params.set("page", "feed");
        return params;
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.darkCardBackground,
        p: 2,
        border: theme.palette.additionalColors.databasecardBorder,
        borderRadius: "8px",
        cursor: label === "Entities" || label === "Results" ? "pointer" : "default",
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", mb: 1 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1.5 }}>
          {/* Icon container */}
          <Box
            sx={{
              background: theme.palette.primary.tertiaryGradient,
              p: 0.75,
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {icon}
          </Box>

          {/* Label and sublabel */}
          <Box>
            <Typography variant="subtitle1" color={theme.palette.text.titleLabel}>
              {label}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
                display: 'block',
                mt: 0.5
              }}
            >
              {subLabel}
            </Typography>
          </Box>
        </Box>

        {/* Trend Indicator */}
        {/* <Box
          sx={{
            px: 1,
            py: 0.5,
            borderRadius: '16px',
            background: negative
              ? `${theme.palette.error.dark}40`
              : `${theme.palette.primary.main}20`,
            border: '1px solid',
            borderColor: negative
              ? `${theme.palette.error.main}40`
              : `${theme.palette.primary.main}40`,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: negative
                ? theme.palette.error.main
                : theme.palette.primary.main,
              fontWeight: 500
            }}
          >
            {trend}
          </Typography>
        </Box> */}
      </Box>

      {/* Count */}
      <Box sx={{ mt: 2, }}> {/* Added padding-left to align with label */}
        <Typography variant="h3" color={theme.palette.text.titleLabel}>
          {count}
        </Typography>
      </Box>
    </Box>
  );
}