import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Grid, InputAdornment, Pagination, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CheckboxFilterButton, { MultiSelectValueType } from "../../../components/CheckboxFilterButton";
import CustomInputField from "../../../components/CustomInputField";
import QueryCard from "../../../components/QueryCard";
import StatsBoxes from "../../../components/StatsBoxes";
import { getQueries } from "../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../store/hooks";
import { getQuerySummary, markAsFavoriteQuery, removeAsFavoriteQuery, startQuery, stopQuery } from "../../../store/query/queryThunk";
import { queryTypeFilterOptions, solutionFilterOptions } from "../../../utils/constants";
import DeleteQueryDialog from "./DeleteQueryDialog";
import SavedToFavoritesDialog from "./SavedToFavoritesDialog";

const PAGE_ITEMS_COUNT = 10;

export default function QueriesHistory() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [page, setPage] = React.useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
  const [queryData, setQueryData] = useState<Record<string, any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [toDeleteQueryid, setToDeleteQueryid] = useState<string>("");
  const [summaryData, setSummaryData] = useState()
  const handleChangeQueryType = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  const handleChangeSolution = (value: MultiSelectValueType[]) => {
    console.log(value);
  };

  const toggleDeleteDialog = (id?: string) => {
    if (id) {
      console.log("toDeleteQueryid", id);
      setToDeleteQueryid(id);
    }
    setOpenDeleteDialog((prev) => !prev);
  };

  const toggleSaveDialog = () => {
    setOpenSaveDialog((prev) => !prev);
  };

  const handleDelete = () => { };

  const handleSaveToFavorites = (queryId: string | number) => {
    dispatch(markAsFavoriteQuery(queryId))
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully marked as a favorite query.");
          setQueryData((prev) => {
            return prev.map((v_: Record<string, any>) => {
              if (v_._id !== queryId) {
                return v_;
              } else {
                v_.is_favourite = true;
                return v_;
              }
            });
          });
        } else {
          toast.error("Oops! Some issues while marking as a favorite query.");
        }
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while marking as a favorite query.");
      });
  };

  const handleRemoveFromFavorites = (queryId: string | number) => {
    dispatch(removeAsFavoriteQuery(queryId))
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully removed from favorite query.");
          setQueryData((prev) => {
            return prev.map((v_: Record<string, any>) => {
              if (v_._id !== queryId) {
                return v_;
              } else {
                v_.is_favourite = false;
                return v_;
              }
            });
          });
        } else {
          toast.error("Oops! Some issues while removing from favorite query.");
        }
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while removing from favorite query.");
      });
  };

  const handleStartQuery = (queryId: string | number) => {
    dispatch(startQuery(queryId))
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully started query.");
          setQueryData((prev) => {
            return prev.map((v_: Record<string, any>) => {
              if (v_._id !== queryId) {
                return v_;
              } else {
                v_.is_active = true;
                return v_;
              }
            });
          });
        } else {
          toast.error("Oops! Some issues while starting query.");
        }
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while starting query.");
      });
  };

  const handleStopQuery = (queryId: string | number) => {
    dispatch(stopQuery(queryId))
      .then((res) => {
        if (res.payload.status === 200) {
          toast.success("Successfully stopped query.");
          setQueryData((prev) => {
            return prev.map((v_: Record<string, any>) => {
              if (v_._id !== queryId) {
                return v_;
              } else {
                v_.is_active = false;
                return v_;
              }
            });
          });
        } else {
          toast.error("Oops! Some issues while stopping query.");
        }
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while stopping query.");
      });
  };

  useEffect(() => {
    dispatch(getQueries({ isFavourite: false }))
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 200) {
          let queryData = res?.payload?.data;
          setQueryData(queryData.reverse());
        } else {
          toast.error("Failed to get the data.");
        }
      })
      .catch((e) => setLoading(false));

    dispatch(getQuerySummary())
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res)
        setLoading(false);
        if (res?.payload?.status === 200) {
          let data = res?.payload?.data;
          setSummaryData(data)
        } else {
          toast.error("Failed to get the data.");
        }
      })
      .catch((e) => setLoading(false));
  }, []);

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <CustomInputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { }}
            placeholder="Search"
            size="small"
            variant="outlined"
            autoComplete="off"
            sx={{
              width: { lg: "40%", md: "100%" },
              mt: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" gap={2}>
          <CheckboxFilterButton
            label="Query Type"
            onChange={(value) => handleChangeQueryType(value)}
            options={queryTypeFilterOptions}
            showSearch={false}
          />
          <CheckboxFilterButton
            label="Solution"
            onChange={(value) => handleChangeSolution(value)}
            options={solutionFilterOptions}
            showSearch={false}
          />
        </Grid>
      </Grid>
      <StatsBoxes summaryData={summaryData} />
      <Box
        sx={{
          py: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
        }}
      >
        {loading ? (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            {/* Loader is shown while loading */}
            <CircularProgress size={50} />
          </Box>
        ) : (
          <>
            {queryData?.length > 0 ? (
              <Grid container gap={1} columnGap={0.1} sx={{
                display: "flex",
                justifyContent: "space-evenly"
              }}>
                {queryData?.slice((page - 1) * PAGE_ITEMS_COUNT, page * PAGE_ITEMS_COUNT)?.map((item: any) => {
                  return (
                    <>
                      <QueryCard
                        data={item}
                        isHistory
                        handleDeleteFromHistory={() => toggleDeleteDialog(item._id)}
                        handleSaveToFavorites={() => handleSaveToFavorites(item._id)}
                        handleDeleteFromFavorites={() => handleRemoveFromFavorites(item._id)}
                        handleStartQuery={() => handleStartQuery(item._id)}
                        handleStopQuery={() => handleStopQuery(item._id)}
                      />
                      <SavedToFavoritesDialog handleToggle={toggleSaveDialog} open={openSaveDialog} queryId={item._id} />
                    </>
                  );
                })}
                {toDeleteQueryid && <DeleteQueryDialog handleToggle={toggleDeleteDialog} open={openDeleteDialog} queryId={toDeleteQueryid} />}
              </Grid>
            ) : (
              <Box sx={{ textAlign: "center", mt: 4 }}>
                {/* Loader will also be shown here if no results */}
                <CircularProgress size={50} />
              </Box>
            )}
          </>
        )}
      </Box>

      <Box>
        {
          <Box
            sx={{
              my: 2,
              mr: 1,
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {queryData?.length / PAGE_ITEMS_COUNT > 0 && (
              <Pagination
                onChange={handlePageChange}
                page={page}
                count={Math.ceil(queryData?.length / PAGE_ITEMS_COUNT)}
                size="small"
                sx={(theme) => ({
                  ...theme.palette.pagination,
                })}
              />
            )}
          </Box>
        }
      </Box>
    </>
  );
}
