import { Box } from "@mui/material";
import ContentUILoader from "../../../../../../components/ContentUILoader";
import CustomNoResults from "../../../../../../components/CustomNoResult";
import ImageCard from "./ImageCard";

interface ImageDataProps {
  created_at: string;
  media_image: string;
  source_link: string;
  relevance: string;
  authenticity: string;
  status: string;
  image: string;
  author: string;
}

interface ImageProps {
  drawerOpen: boolean;
  isRefresh: boolean;
  loading: boolean;
  feedData: Record<string, any>;
}

export default function Images({ drawerOpen, isRefresh, loading, feedData }: ImageProps) {
  const imagesData = feedData?.filter((feed: any) => feed?.images !== null);

  const filterData =
    imagesData?.flatMap((data: Record<string, any>) =>
      data?.images?.map((image: string) => ({
        created_at: data?.creation_time ?? "",
        media_image:
          data?.user?.profile_image ||
          data?.authors?.[0]?.profile_image ||
          (data?.kind === "news" ? "/assets/news.png" : "/assets/twitter.png"),
        source_link: data?.url ?? "#",
        relevance: "97%",
        authenticity: "97%",
        status: "moderate",
        image: image,
        source: data?.source ?? "Unknown",
        author: data?.user?.name || data?.authors?.[0]?.name || "Unknown",
        dataId: data?._id ?? "",
      }))
    ) || [];

  const removeDuplicates = (array: ImageDataProps[]) => {
    const seen = new Set();
    return array.filter((item) => {
      const keyValue = item?.image;
      return keyValue && !seen.has(keyValue) ? seen.add(keyValue) : false;
    });
  };

  const uniqueFiltered = removeDuplicates(filterData);

  return (
    <>
      {!loading && !isRefresh ? (
        uniqueFiltered.length > 0 ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)", // 3 columns in the grid
              gap: 2, // Space between grid items
              mt: 3,
              cursor: "pointer",
            }}
          >
            {uniqueFiltered.map((data: ImageDataProps, idx: number) => (
              <Box
                key={data.image || idx}
                sx={{
                  width: "100%",
                  aspectRatio: "1", // Ensures equal width and height
                  overflow: "hidden",
                  borderRadius: "8px", // Optional rounded corners
                  height:'320px',
                }}
              >
                <ImageCard
                  cardData={data}
                  index={idx}
                  drawerOpen={drawerOpen}
                  mergedColumnIds={[]}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <CustomNoResults />
        )
      ) : (
        <ContentUILoader type="image" count={3} drawerOpen={drawerOpen} />
      )}
    </>
  );
}
