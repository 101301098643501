import { Box, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { analysisQueryActions } from "../../../../store/analysis/analysisQuerySlice";
import { getHashtagPosts, getQueryResult } from "../../../../store/analysis/analysisThunk";
import { EntitiesType } from "../../../../store/analysis/entitiesSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { LCP_CORE_SERVICE_BASE_URL } from "../../../../utils/constants";
import FilterDrawerSimple from "./components/FilterDrawer";
import Images from "./components/Images";
import Publication from "./components/Publications";
import EntitySearch from "./components/Publications/SpecificPublication/EntitySearch";

interface FeedSectionProps {
  isRefresh: boolean;
  removePageRefresh(): void;
}

export default function FeedSection({ isRefresh, removePageRefresh }: FeedSectionProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { feedFilter } = useAppSelector((state: any) => state.analysisQuery);
  const { query } = useAppSelector((state) => state.analysisQuery);
  const [activeTab, setActiveTab] = useState(state?.activeTab || "publication");
  const [view, setView] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sortBy, setSortBy] = useState("relevance");
  const [publicationData, setPublicationData] = useState<Record<string, any>>({});
  const [selectedSources, setSelectedSources] = useState<any>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [redFlags, setRedFlags] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const queryId = params.get("query") || query?.id;
  const specific_entity = params.get("specific_entity");
  const hashtagId = params.get("hashtagId");
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryData = (queryId: string) => {
    setLoading(true);
    let sources = selectedSources.map((item: any) => item.id);

    if (hashtagId) {
      dispatch(getHashtagPosts({
        queryId,
        hashtagId
      }))
        .then((res) => {
          // console.log("🚀 ~ .then ~ res:", res)
          setLoading(false);
          if (res?.payload?.status === 200) {
            // console.log(res?.payload?.data);

            const transformedData = {
              results: res?.payload?.data?.posts.map((item: any) => item.post),
              total_count: res?.payload?.data?.total_posts
            };
            setPublicationData(transformedData);
            removePageRefresh();
          } else {
            toast.error("Failed to get hashtag posts.");
          }
        })
        .catch((error) => {
          toast.error("Failed to get hashtag posts.");
          setLoading(false);
        });
    } else {
      dispatch(getQueryResult({ queryId, sort_by: sortBy, redFlags, sources, specific_entity }))
        .then((res) => {
          setLoading(false);
          if (res?.payload?.status === 200) {
            setPublicationData(res?.payload?.data);
            removePageRefresh();
          } else {
            toast.error("Failed to get the data.");
          }
        })
        .catch((e) => {
          toast.error("Failed to get the data.");
          setLoading(false);
        });
    }


  };

  const fetchEntityPosts = (entityId: string) => {
    const newParams = new URLSearchParams(params);
    newParams.set('specific_entity', entityId);
    newParams.delete('hashtagId'); // Remove hashtag param if exists
    navigate(`${location.pathname}?${newParams.toString()}`);
  };;


  const getAuthenticitySorted = useCallback(() => {

    setSortBy("authenticity");
  }, []);

  const getRelevanceSorted = useCallback(() => {
    setSortBy("relevance");
  }, []);

  const handleLoadMoreQueryData = () => {
    dispatch(getQueryResult({ nextPage: publicationData?.next_page.replace("http://lcpproto:8000", LCP_CORE_SERVICE_BASE_URL) }))
      .then((res) => {
        // console.log("res:", res)
        setLoading(false);
        if (res?.payload?.status === 200) {
          setPublicationData((prev) => {
            return { ...prev, ...res?.payload?.data, results: [...prev.results, ...res?.payload?.data?.results] };
          });
          removePageRefresh();
          // console.log("publicationData", res.payload.data);
        } else {
          toast.error("Failed to get the data.");
        }
      })
      .catch((e) => {
        toast.error("Failed to get the data.");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (queryId) {
      getQueryData(queryId);
    }
    if (specific_entity) {
      toast.info("Showing Posts Belongs to Specific Entity!!")
    }
    if (hashtagId) {
      toast.info("Showing Posts for Selected Topic")
    }
  }, [queryId, sortBy, redFlags, selectedSources, specific_entity, hashtagId]);

  useEffect(() => {
    if (queryId && isRefresh) {
      getQueryData(queryId);
    }
  }, [isRefresh]);


  const tabs = [
    {
      id: "publication",
      title: "Publications",
      disabled: false,
      component: (
        <Publication
          view={view}
          feedData={publicationData}
          loading={loading}
          drawerOpen={drawerOpen}
          isRefresh={isRefresh}
          loadMore={handleLoadMoreQueryData}
          selectedSources={[]}
          blacklistedWords={redFlags}
        />
      ),
    },
    {
      id: "image",
      title: "Images",
      disabled: false,
      component: <Images drawerOpen={drawerOpen} isRefresh={isRefresh} feedData={publicationData?.results} loading={loading} />,
    },
  ];

  const getActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const getView = (viewTab: boolean) => {
    setView(viewTab);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleFilterApply = (data: any) => {
    dispatch(analysisQueryActions.addFeedFilter(data));
  };

  const handleReset = (data: any) => {
    dispatch(analysisQueryActions.addFeedFilter(data));
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    // Check if scrolled to the bottom
    if (scrollTop + clientHeight + 50 >= scrollHeight) {
      if (publicationData?.next && !moreLoading) {
        setMoreLoading(true);
        getMoreData(); // Trigger your function
      }
    }
  }, [publicationData, moreLoading]);

  const getMoreData = () => {
    // console.log(publicationData?.next)
    if (publicationData?.next) {
      setMoreLoading(true);
      dispatch(getQueryResult({ nextPage: publicationData?.next }))
        .then((res) => {
          setMoreLoading(false);
          if (res?.payload?.status === 200) {
            setPublicationData((prevData: any) => {
              let data = res?.payload?.data
              data['results'] = [...prevData.results, ...data.results]
              return data;
            });
          } else {
            toast.error("Failed to get the data.");
          }
          // console.log("🚀 ~ .then ~ res?.payload?.data:", res?.payload?.data);
        })
        .catch((e) => {
          toast.error("Failed to get the data.");
          setMoreLoading(false);
        });
    }

  };

  const handleEntityTypeChange = (entityId: string | null, entityType: any) => {

    setSelectedEntityId(entityId);

    if (entityId) {
      // Handle topics differently
      if (entityType === 'topics') {
        // Update URL with hashtag parameter instead of specific_entity
        const newParams = new URLSearchParams(params);
        newParams.set('hashtagId', entityId);
        newParams.delete('specific_entity');
        navigate(`${location.pathname}?${newParams.toString()}`);
      } else {
        // For other entity types, use the original logic
        fetchEntityPosts(entityId);
      }
    } else {
      // Clear both params when deselecting
      const newParams = new URLSearchParams(params);
      newParams.delete('specific_entity');
      newParams.delete('hashtag');
      navigate(`${location.pathname}?${newParams.toString()}`);
    }
  };

  const handleSelectedEntities = (selectedEntities: EntitiesType[]) => {
    const newParams = new URLSearchParams(searchParams);

    if (selectedEntities.length > 0) {
      const entityIds = selectedEntities
        .map(entity => (entity as any)._id['$oid'])
        .join(',');
      newParams.set('specific_entity', entityIds);
    } else {
      newParams.delete('specific_entity');
    }

    setSearchParams(newParams);
  };

  return (
    <Box
      ref={containerRef}
      onScroll={handleScroll}
      sx={{
        height: "calc(100vh - 200px)",
        overflowY: "scroll",
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
    >

      <Box sx={{ mb: 1, mt: 1 }}>
        <EntitySearch onEntitiesChange={handleSelectedEntities} />
      </Box>

      <FilterDrawerSimple tabs={tabs}
        getActiveTab={getActiveTab}
        getView={getView}
        handleDrawerOpen={handleDrawerOpen}
        openDrawer={drawerOpen}
        setStateVariable={setSelectedSources}
        getAuthenticitySorted={getAuthenticitySorted}
        getRelevanceSorted={getRelevanceSorted}
        setRedFlags={setRedFlags}
        selectedEntityId={selectedEntityId}
        onEntityTypeChange={handleEntityTypeChange} />

      {/* <FilterSection
        tabs={tabs}
        getActiveTab={getActiveTab}
        getView={getView}
        handleDrawerOpen={handleDrawerOpen}
        openDrawer={drawerOpen}
        setStateVariable={setSelectedSources}
        getAuthenticitySorted={getAuthenticitySorted}
        getRelevanceSorted={getRelevanceSorted}
        setRedFlags={setRedFlags}
        selectedEntityId={selectedEntityId}
        onEntityTypeChange={handleEntityTypeChange}

      /> */}
      {/* <Box>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
      {moreLoading && <Typography>Loading More ...</Typography>} */}

      <Box>
        {activeTab === "publication" ? (
          <Publication
            view={view}
            feedData={publicationData}
            loading={loading}
            drawerOpen={drawerOpen}
            isRefresh={isRefresh}
            loadMore={handleLoadMoreQueryData}
            selectedSources={[]}
            blacklistedWords={redFlags}
          />
        ) : (
          <Images
            drawerOpen={drawerOpen}
            isRefresh={isRefresh}
            feedData={publicationData?.results}
            loading={loading}
          />
        )}
      </Box>
      {moreLoading && <Typography>Loading More ...</Typography>}
    </Box>
  );
}
