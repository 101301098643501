// import { Box, useTheme } from "@mui/material";
// import { useState } from "react";
// import { LuArrowDownUp } from "react-icons/lu";
// import CustomTab from "../../../../components/CustomTab";
// import RadioFilterButton from "../../../../components/RadioFilterButton";
// import EntitiesDetails from "./components/EntitiesDetails";

// const tabs = [
//   {
//     id: "all",
//     title: "All",
//     disabled: false,
//     component: <EntitiesDetails type="all" />,
//   },
//   {
//     id: "persons",
//     title: "Persons",
//     disabled: false,
//     component: <EntitiesDetails type="Person" />,
//   },
//   // {
//   //   id: "organizations",
//   //   title: "Organizations",
//   //   disabled: false,
//   //   component: <EntitiesDetails type="organization" />,
//   // },
//   {
//     id: "locations",
//     title: "Locations",
//     disabled: false,
//     component: <EntitiesDetails type="Location" />,
//   },
//   {
//     id: "events",
//     title: "Events",
//     disabled: false,
//     component: <EntitiesDetails type="Event" />,
//   },
//   {
//     id: "topics",
//     title: "Topics",
//     disabled: false,
//     component: <EntitiesDetails type="Topic" />,
//   },
// ];
// export default function EntitiesSection() {
//   const theme = useTheme();
//   const [activeTab, setActiveTab] = useState(tabs[0].id);
//   const [sortedValue, setsortedValue] = useState("risk score: extreme first");
//   const [subtype, setSubType] = useState("all");

//   const handleActiveTab = (tab: string) => {
//     setActiveTab(tab);
//   };

//   const handleRiskFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setsortedValue(e.target.value);
//   };

//   const handleTypeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSubType(e.target.value);
//   };

//   const riskScoreOptions = [
//     { id: "risk-score-extreme-first", text: "Mentions" },
//     // { id: "risk-score-low-first", text: "Low First" },
//   ];
//   const subType = [
//     { id: "all", text: "All" },
//     { id: "historical-figure", text: "Historical Figure" },
//     { id: "social-activist", text: "Social Activist" },
//     { id: "humanitarian", text: "Humanitarian" },
//     { id: "influential-figure", text: "Influential Figure" },
//     { id: "goverment-worker", text: "Goverment Worker" },
//     { id: "civilians", text: "Civilians" },
//     { id: "business figure", text: "Business Figure" },
//   ];

//   return (
//     <Box>
//       <Box
//         display="flex"
//         sx={{
//           [theme.breakpoints.down("lg")]: {
//             flexDirection: "column",
//             gap: 2,
//           },
//           borderTop: "1px solid",
//           borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
//           pt: 3,
//         }}
//       >
//         <CustomTab
//           tabs={tabs}
//           handleActiveTab={handleActiveTab}
//           sx={{
//             border: `1px solid ${theme.palette.primary.tertiary}`,
//             textAlign: "center",
//             p: "0 2px",
//             alignItems: "center",
//             borderRadius: "6px",
//             [theme.breakpoints.only("lg")]: {
//               width: "65%",
//             },
//           }}
//           activeTab={activeTab}
//         />
//         <Box ml={{ lg: "auto", gap: "10px", display: "flex" }}>
//           {activeTab !== "all" && (
//             <RadioFilterButton label="SubType" onChange={(value) => { }} options={subType} defaultValue={subType[0].id} sx={{ width: "110px" }} />
//           )}
//           <RadioFilterButton
//             label="Sorting "
//             onChange={(value) => { }}
//             options={riskScoreOptions}
//             startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
//             defaultValue={riskScoreOptions[0].id}
//             sx={{ width: "200px" }}
//           />
//         </Box>
//       </Box>
//       {tabs.find((tab) => tab.id === activeTab)?.component}
//     </Box>
//   );
// }

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTab } from "../../../../contexts/TabContext";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { EntitiesType } from "../../../../store/analysis/entitiesSlice";
import { EntityGrid } from './components/EntityGrid';
import FilterEntitiesSection from "./components/FilterEntitiesSection";
import RelatedEntities from "./components/RelatedEntities";


interface EntitiesSectionProps {
}

const EntitiesSection: React.FC<EntitiesSectionProps> = () => {
  const theme = useTheme();
  const [queryParameters] = useSearchParams();
  const handleRouteClick = useHandleRouteClick();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchState, setSearchState] = useState<{
    isActive: boolean;
    selectedEntity: EntitiesType | null;
    relatedEntities: EntitiesType[];
  }>({
    isActive: false,
    selectedEntity: null,
    relatedEntities: []
  });
  const { onTabChange } = useTab();

  const handleSearchStateChange = (
    isActive: boolean,
    selectedEntity: EntitiesType | null,
    relatedEntities: EntitiesType[]
  ) => {
    setSearchState({
      isActive,
      selectedEntity,
      relatedEntities
    });
  };

  return (
    <Box>
      <Box
        sx={{
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          pt: 3,
        }}
      >
        <FilterEntitiesSection
          onSearchStateChange={handleSearchStateChange}
        />

        {searchState.isActive ? (
          <Box sx={{ px: { xs: 1, sm: 2 }, pb: 2 }}>
            <RelatedEntities
              selectedEntity={searchState.selectedEntity}
              relatedEntities={searchState.relatedEntities}
              onEntityClick={(entity: any) => {
                const queryId = queryParameters.get("query");
                const specificEntity = entity?._id['$oid'];
                onTabChange("feed");
                handleRouteClick(`${routes.home.path}?tab=home&subtab=feed&query=${queryId}&specific_entity=${specificEntity}`);
              }}
            />
          </Box>
        ) : (
          <EntityGrid
            searchTerm={searchTerm}
            onSearchChange={setSearchTerm}
          />
        )}
      </Box>
    </Box>
  );
};

export default EntitiesSection;