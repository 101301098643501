import { Button, useTheme } from "@mui/material";

export default function CustomFilterButton({ children, ...rest }: any) {
    const theme = useTheme();

    return (
        <Button
            variant="contained"
            sx={(theme) => ({
                ...theme.typography.bigButton,
                color: (theme) => theme.palette.primary.main,
                border: `1px solid ${theme.palette.additionalColors.border1Light}`,
                borderRadius: "4px",
                background: theme.palette.background.tertiaryDarkGradient,
                boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                "&:hover": {
                    background: theme.palette.background.tertiaryGradient,
                    boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                },
                "&:active": {
                    background: theme.palette.background.gradientLight1,
                    boxShadow: "0px 0px 2px 0px #12161D, 0px 0px 1px 0px rgba(48, 62, 90, 0.55)",
                    color: theme.palette.text.active,
                },
                "&:disabled": {
                    background: theme.palette.action.disabled,
                    color: theme.palette.background.dark,
                },
                [theme.breakpoints.down("lg")]: {
                    fontSize: "15px",
                },
            })}
            {...rest}
        >
            {children}
        </Button>
    );
}
