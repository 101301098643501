import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ForceGraph2D from "react-force-graph-2d";
import { useSearchParams } from "react-router-dom";
import ZoomController from "../../../../../components/ZoomController";
import UiLoading from "../../../../../layouts/UiLoading";
import { getEntities, getLatestQuery, getQueryById } from "../../../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { BASE_URL } from "../../../../../utils/constants";
import CustomDropdown from "../../Feed/components/CustomDropdown";
import ConnectionsPopover from "./ConnectionsPopover/ConnectionsPopover";
import LinkPopoverContent from "./ConnectionsPopover/LinkPopoverContent";
import NodePopoverContent from "./ConnectionsPopover/NodePopoverContent";

type SocialMediaSource = 'youtube' | 'facebook' | 'linkedin' | 'twitter' | 'instagram' | 'news' | 'cnn' | 'default';

const SOURCE_COLORS: Record<SocialMediaSource, string> = {
    youtube: "#FF0000",
    facebook: "#1877F2",
    linkedin: "#0A66C2",
    twitter: "#1DA1F2",
    instagram: "#C13584",
    news: "#FFA500",
    cnn: "#FF6B00",
    default: "#888888"
};

type EntityType = 'events' | 'locations' | 'people';

const ENTITY_COLORS: Record<EntityType, string> = {
    events: "#A78BFA",    // Lavender
    locations: "#F87171", // coral
    people: "#92400E"     // Chocolate Brown
};

interface NewNetworkGraphProps {
    setSelectedNode: (nodeId: string | null) => void;
    visiblePosts: string[];
    onNodeClick: (nodeId: string, posts: string[]) => void;
}

const ZoomScale: React.FC<{ zoom: number, maxZoom: number }> = ({ zoom, maxZoom }) => {
    const theme = useTheme();  // Added theme hook

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 80,
                left: 20,
                backgroundColor: theme.palette.background.paper,  // Now uses theme background
                padding: '8px',
                borderRadius: '4px',
                color: theme.palette.text.primary,  // Now uses theme text color
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '14px',
                zIndex: 1000,
                boxShadow: theme.shadows[1]  // Added theme shadow
            }}
        >
            <span>Zoom: {(zoom * 100).toFixed(0)}%</span>
            <Box
                sx={{
                    width: '100px',
                    height: '4px',
                    backgroundColor: theme.palette.divider,  // Now uses theme divider color
                    borderRadius: '2px'
                }}
            >
                <Box
                    sx={{
                        width: `${(zoom / maxZoom) * 100}%`,
                        height: '100%',
                        backgroundColor: theme.palette.primary.main,  // Now uses theme primary color
                        borderRadius: '2px',
                        transition: 'width 0.2s'
                    }}
                />
            </Box>
        </Box>
    );
};

const ANIMATION = {
    LINE_DASH_POST: 5,
    LINE_SPACING_POST: 5,
    LINE_DASH_REGULAR: 10,
    LINE_SPACING_REGULAR: 10,
    SPEED: 0.5
};

const NewNetworkGraph: React.FC<NewNetworkGraphProps> = ({ setSelectedNode, visiblePosts, onNodeClick }) => {
    const theme = useTheme();
    const graphRef = useRef<any>(null);
    const { hashtags, isLoading: connectionsLoading } = useAppSelector((state) => state.analysisConnections);
    const { query, isLoading: queryLoading } = useAppSelector((state) => state.analysisQuery);
    const [entitiesLoading, setEntitiesLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [graphData, setGraphData] = useState<any>({ nodes: [], links: [] });
    const [posts, setPosts] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [zoom, setZoom] = useState(3);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [currentLink, setCurrentLink] = useState<Record<string, any> | null>(null);
    const [currentNode, setCurrentNode] = useState<any | null>(null);
    const [openedNodes, setOpenedNodes] = useState<Set<string>>(new Set());
    const [coords, setCoords] = useState<number[]>([0, 0]);
    const containerRef = useRef<any>(null);

    // states for entity filtering
    const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [filteredEntities, setFilteredEntities] = useState<{
        people: any[];
        events: any[];
        locations: any[];
    }>({
        people: [],
        events: [],
        locations: []
    });

    const minZoom = 0.5;  // Changed from 1
    const maxZoom = 5;    // Changed from 20
    const zoomStep = 0.15;  // Changed from 0.5
    const zoomTransitionDuration = 400; // New constant for smooth transition
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    // Query handling useEffect
    useEffect(() => {
        const fetchData = async () => {
            const queryFromUrl = searchParams.get("query");
            setLoading(true);

            // Clear existing data when fetching new query
            setGraphData({ nodes: [], links: [] });
            setPosts([]);
            setCurrentNode(null);
            setSelectedNode(null);

            try {
                let currentQueryId;
                if (queryFromUrl) {
                    const queryRes = await dispatch(getQueryById(queryFromUrl)).unwrap();
                    if (queryRes?.status === 200) {
                        currentQueryId = queryRes.data._id;
                    }
                }

                if (!currentQueryId) {
                    const latestRes = await dispatch(getLatestQuery()).unwrap();
                    if (latestRes?.status === 200 && latestRes?.data?._id) {
                        const queryRes = await dispatch(getQueryById(latestRes.data._id)).unwrap();
                        currentQueryId = queryRes?.payload?.data?._id;
                    }
                }
            } catch (error) {
                console.error("Error fetching query:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchParams, dispatch, setSelectedNode]);

    // new effect for fetching entities
    useEffect(() => {
        const queryId = searchParams.get("query");
        if (!queryId) return;

        setEntitiesLoading(true);

        dispatch(getEntities(queryId))
            .then((res: any) => {
                if (res.payload?.data) {
                    const entities = res.payload.data;

                    // Group entities by type
                    const groupedEntities = entities.reduce((acc: any, entity: any) => {
                        if (!entity || !entity.type) return acc;

                        const type = entity.type.toLowerCase();
                        let category;

                        if (type === 'person') category = 'people';
                        else if (type === 'event') category = 'events';
                        else if (type === 'location') category = 'locations';
                        else return acc;

                        if (!acc[category]) acc[category] = [];

                        // Standardize entity structure
                        const standardizedEntity = {
                            _id: entity._id?.$oid || entity._id || '', // Get just the $oid value
                            name: entity.name || 'Unnamed',
                            mentions: parseInt(entity.mentions || entity.count || '0'),
                            type: type
                        };

                        acc[category].push(standardizedEntity);
                        return acc;
                    }, { people: [], events: [], locations: [] });

                    // Sort and get top 5 for each category
                    Object.keys(groupedEntities).forEach(category => {
                        groupedEntities[category] = groupedEntities[category]
                            .sort((a: any, b: any) => b.mentions - a.mentions)
                            .slice(0, 5);
                    });

                    setFilteredEntities(groupedEntities);
                }
            })
            .catch((error) => {
                console.error('Error fetching entities:', error);
            })
            .finally(() => {
                setEntitiesLoading(false);
            });
    }, [searchParams, dispatch]);

    // Hashtags mapping useEffect
    useEffect(() => {
        if (hashtags?.length && query?._id) {
            const nodes = hashtags.map((hashtag) => ({
                id: hashtag.hashtag_id,
                name: hashtag.name,
                count: hashtag.count,
            }));

            const links = hashtags.flatMap((hashtag) =>
                hashtag.connected_hashtags?.map((connectedHashtag: string) => ({
                    source: hashtag.hashtag_id,
                    target: connectedHashtag,
                })) || []
            );

            setGraphData({ nodes, links });
        }
    }, [hashtags, query?._id]);

    // Graph rendering useEffect
    useEffect(() => {
        if (graphRef.current) {
            const graph = graphRef.current;
            graph.zoom(1);
            const d3Force = graph.d3Force("charge");
            d3Force.strength(-50); // Reduced from -150 to bring nodes closer
            graph.d3Force("link").distance(130); // Reduced from 130 to bring nodes closer
        }
    }, [graphData]);

    const getViewportCenter = () => {
        if (!containerRef.current) return { x: 0, y: 0 };
        const box = containerRef.current.getBoundingClientRect();
        return {
            x: box.width / 2,
            y: box.height / 2
        };
    };

    const [animationTime, setAnimationTime] = useState(0);

    // Animation frame handler
    useEffect(() => {
        let animationFrameId: number | undefined;

        const animate = (): void => {
            setAnimationTime(prev => prev + 0.01); // Slower rotation
            animationFrameId = window.requestAnimationFrame(animate);
        };

        animate();
        return () => {
            if (animationFrameId !== undefined) {
                window.cancelAnimationFrame(animationFrameId);
            }
        };
    }, []);

    const handleZoomOut = () => {
        if (!graphRef.current) return;

        const { x, y } = getViewportCenter();
        const nextZoom = Math.min(maxZoom, zoom + zoomStep);

        const graphCoords = graphRef.current.screen2GraphCoords(x, y);

        graphRef.current.zoom(nextZoom, zoomTransitionDuration);
        graphRef.current.centerAt(graphCoords.x, graphCoords.y, zoomTransitionDuration);

        setZoom(nextZoom);
    };

    const handleZoomIn = () => {
        if (!graphRef.current) return;

        const { x, y } = getViewportCenter();
        const nextZoom = Math.max(minZoom, zoom - zoomStep);

        const graphCoords = graphRef.current.screen2GraphCoords(x, y);

        graphRef.current.zoom(nextZoom, zoomTransitionDuration);
        graphRef.current.centerAt(graphCoords.x, graphCoords.y, zoomTransitionDuration);

        setZoom(nextZoom);
    };

    const handleLinkClick = (link: Record<string, any>, e: MouseEvent) => {
        setCurrentNode(null);
        setCurrentLink(link);
        const localX = e.clientX;
        const localY = e.clientY;
        setCoords([localX, localY]);
        setOpenPopover(true);
    };

    const drawLink = (link: any, ctx: CanvasRenderingContext2D, isCurrentNodeLink: boolean, animationTime: number) => {
        const source = link.source;
        const target = link.target;

        if (!source || !target) return;

        const dx = target.x - source.x;
        const dy = target.y - source.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const sourceRadius = (source.count > 20 ? 25 : 20) + 8;
        const targetRadius = target.count > 10 ? 15 : 10;

        const nx = dx / distance;
        const ny = dy / distance;

        const startX = source.x + nx * sourceRadius;
        const startY = source.y + ny * sourceRadius;
        const endX = target.x - nx * targetRadius;
        const endY = target.y - ny * targetRadius;

        // Animated flowing dash pattern
        ctx.beginPath();
        ctx.setLineDash([5, 5]);
        ctx.lineDashOffset = -animationTime * 50; // Flowing speed
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = theme.palette.primary.main;
        ctx.lineWidth = 1.5;
        ctx.stroke();
        ctx.setLineDash([]);
    };

    const nodePaint = (node: any, ctx: CanvasRenderingContext2D, globalScale: number) => {
        const radius = node.count > 20 ? 25 : 20;
        const OUTER_CIRCLE_OFFSET = 8;
        let color;

        if (node.isPost) {
            if (node.entityType && ENTITY_COLORS[node.entityType as EntityType]) {
                color = ENTITY_COLORS[node.entityType as EntityType];
            } else {
                const sourceName = node.socialType as SocialMediaSource;
                color = SOURCE_COLORS[sourceName] || SOURCE_COLORS.default;
            }
        } else {
            const baseColor = theme.palette.primary.main;
            const brightness = getColorBasedOnCount(node.count);
            color = adjustColorBrightness(baseColor, brightness);
        }

        const centerX = node.x;
        const centerY = node.y;

        if (!node.isPost) {
            // Draw rotating outer circle
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = theme.palette.primary.main;
            ctx.lineWidth = 1.5;

            // Create rotation effect
            ctx.translate(centerX, centerY);
            ctx.rotate(animationTime * 2); // Rotation speed
            ctx.translate(-centerX, -centerY);

            // Draw dashed circle
            const dashSegments = 30;
            const anglePerSegment = (2 * Math.PI) / dashSegments;

            for (let i = 0; i < dashSegments; i++) {
                if (i % 2 === 0) {
                    const startAngle = i * anglePerSegment;
                    const endAngle = startAngle + anglePerSegment;

                    ctx.beginPath();
                    ctx.arc(centerX, centerY, radius + OUTER_CIRCLE_OFFSET, startAngle, endAngle);
                    ctx.stroke();
                }
            }
            ctx.restore();
        }

        // Draw main circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        ctx.fillStyle = node.isPost ? `${color}90` : `${color}70`;  // Use the calculated color
        ctx.fill();
        ctx.strokeStyle = color;  // Use the calculated color for stroke too
        ctx.stroke();

        // Add text
        ctx.font = node.isPost ? '10px Arial' : '12px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#fff';

        if (node.isPost) {
            // For post nodes: name outside, count inside
            ctx.font = '10px Arial';
            ctx.fillText(node.name, centerX, centerY - radius - 5); // Name above the node
            ctx.font = '12px Arial';
            ctx.fillText(node.count.toString(), centerX, centerY); // Count in center
        } else {
            // For hashtag nodes: Keep existing logic
            ctx.font = '12px Arial';
            ctx.fillText(node.name, centerX, centerY - radius - 5);
            ctx.fillText(node.count.toString(), centerX, centerY);

            // Only show connections count if this is the currently selected node
            if (openedNodes.has(node.id)) {
                // Calculate the number of connections (post links)
                const connectionCount = graphData.links.filter((link: any) => {
                    const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
                    return sourceId === node.id && link.isPostLink;
                }).length;

                // Display connections count below the node
                ctx.font = '11px Arial';
                ctx.fillText(`${connectionCount} connections`, centerX, centerY + radius + 15);
            }
        }
    };

    const fetchedNodes = new Set(); // Track nodes that have already fetched posts

    const handleNodeClickDetails = async (node: any, event?: MouseEvent | undefined) => {
        const nodeId = node.id;
        const nodeName = node.name;

        // If it's a post node with URL, handle it separately
        if (node.isPost && node.url) {
            window.open(node.url, "_blank");
            return;
        }

        // Check if this node is already opened using openedNodes Set
        const isNodeOpened = openedNodes.has(nodeId);

        // If node is already opened, close it
        if (isNodeOpened) {
            // Clear all states related to the node
            setSelectedNode(null);
            setPosts([]);
            setCurrentNode(null);
            setOpenPopover(false);

            // Remove from opened nodes when closing
            setOpenedNodes(prev => {
                const newSet = new Set(prev);
                newSet.delete(nodeId);
                return newSet;
            });

            // Clear ALL related graph data for this node
            setGraphData((prev: { nodes: any[]; links: any[] }) => ({
                nodes: prev.nodes.filter(n => {
                    if (n.isPost) {
                        const [sourceId] = n.id.split('-');
                        return sourceId !== nodeId;
                    }
                    return true;
                }),
                links: prev.links.filter(link => {
                    if (link.isPostLink) {
                        const targetId = typeof link.target === 'object' ? link.target.id : link.target;
                        const [postSourceId] = targetId.split('-');
                        return postSourceId !== nodeId;
                    }
                    return true;
                })
            }));

            return;
        }

        // If node is not opened, proceed with opening it
        if (!query?._id) {
            console.error("No query ID available");
            return;
        }

        setLoading(true);
        setOpenedNodes(prev => new Set(prev).add(nodeId));
        setSelectedNode(nodeName);

        try {
            const response = await fetch(
                `${BASE_URL}/lcproto/query/posts_by_hashtag/?hashtag_id=${encodeURIComponent(nodeId)}&query_id=${encodeURIComponent(query._id)}`
            );

            if (response.ok) {
                const data = await response.json();
                const posts = data?.posts || [];
                setPosts(posts);

                if (posts.length > 0) {
                    const transformedPosts = posts.map((post: any) => ({
                        id: `${nodeId}-${post.post._id}`,
                        name: post.post.content?.split(' ').slice(0, 4).join(' ') + '...',
                        count: post.post.source === "youtube"
                            ? post.post.likeCount || 0
                            : post.post.source === "facebook"
                                ? post.post.authors?.[0]?.followers || post.post.comments || 0
                                : post.post.likes || 0,
                        url: post.post.url || "",
                        isPost: true,
                        source: nodeId,
                        socialType: (post.post.source || 'default').toLowerCase()
                    }));

                    const postLinks = transformedPosts.map((post: any) => ({
                        source: nodeId,
                        target: post.id,
                        isPostLink: true
                    }));

                    // Update graph data atomically
                    setGraphData((prev: { nodes: any[]; links: any[] }) => ({
                        nodes: [...prev.nodes, ...transformedPosts],
                        links: [...prev.links, ...postLinks]
                    }));

                    setCurrentNode(node);
                    onNodeClick(nodeId, posts.map((post: any) => post.post._id));
                }
            } else {
                console.error("Failed to fetch posts for node:", nodeId);
                setPosts([]);
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
            setPosts([]);
        } finally {
            setLoading(false);
        }
    };

    const handleNodeClickWithEntity = async (node: any, matchingPosts: any[], selectedEntities: {
        events: string[],
        people: string[],
        locations: string[]
    }) => {
        const nodeId = node.id;

        if (!query?._id) return;

        if (matchingPosts.length > 0) {
            setPosts(matchingPosts);

            const transformedPosts = matchingPosts.map((post: any) => {
                const postEntities = post.data_result?.entities || [];
                const normalizedEntityIds = postEntities.map((e: string | { $oid: string }) =>
                    typeof e === 'object' ? e.$oid : e
                );

                // Use passed in selectedEntities instead of state
                let entityType: EntityType | undefined;

                if (selectedEntities.events.some(id => normalizedEntityIds.includes(id))) {
                    entityType = 'events';
                } else if (selectedEntities.people.some(id => normalizedEntityIds.includes(id))) {
                    entityType = 'people';
                } else if (selectedEntities.locations.some(id => normalizedEntityIds.includes(id))) {
                    entityType = 'locations';
                }

                return {
                    id: `${nodeId}-${post.post._id}`,
                    name: post.post.content?.split(' ').slice(0, 4).join(' ') + '...',
                    count: post.post.source === "youtube"
                        ? post.post.likeCount || 0
                        : post.post.source === "facebook"
                            ? post.post.authors?.[0]?.followers || post.post.comments || 0
                            : post.post.likes || 0,
                    url: post.post.url || "",
                    isPost: true,
                    source: nodeId,
                    socialType: (post.post.source || 'default').toLowerCase(),
                    entityType
                };
            });

            const postLinks = transformedPosts.map((post: any) => ({
                source: nodeId,
                target: post.id,
                isPostLink: true
            }));

            // Update graph data atomically
            setGraphData((prev: { nodes: any[]; links: any[] }) => ({
                nodes: [...prev.nodes, ...transformedPosts],
                links: [...prev.links, ...postLinks]
            }));

            setOpenedNodes(prev => new Set(prev).add(nodeId));
            setCurrentNode(node);
            onNodeClick(nodeId, matchingPosts.map((post: any) => post.post._id));
        }
    };

    const handleEntityClick = (type: string, value: string) => {
        setLoading(true);

        try {
            // Create new arrays for each entity type
            let updatedEvents = [...selectedEvents];
            let updatedPeople = [...selectedPeople];
            let updatedLocations = [...selectedLocations];

            // Update only the selected category, keeping others unchanged
            switch (type) {
                case 'events':
                    // If selecting same value, clear it. Otherwise, set as only value
                    updatedEvents = selectedEvents[0] === value ? [] : [value];
                    break;
                case 'people':
                    // If selecting same value, clear it. Otherwise, set as only value
                    updatedPeople = selectedPeople[0] === value ? [] : [value];
                    break;
                case 'locations':
                    // If selecting same value, clear it. Otherwise, set as only value
                    updatedLocations = selectedLocations[0] === value ? [] : [value];
                    break;
            }

            // Update state with new selections
            setSelectedEvents(updatedEvents);
            setSelectedPeople(updatedPeople);
            setSelectedLocations(updatedLocations);

            // Clear all existing post nodes and links
            setGraphData((prev: { nodes: any[]; links: any[] }) => ({
                nodes: prev.nodes.filter((node) => !node.isPost),
                links: prev.links.filter((link) => !link.isPostLink)
            }));

            // Reset node states
            setOpenedNodes(new Set());
            setCurrentNode(null);

            // Combine all selected entities
            const allSelectedEntities = [
                ...updatedEvents,
                ...updatedPeople,
                ...updatedLocations
            ];

            if (allSelectedEntities.length > 0) {
                const hashtagNodes = graphData.nodes.filter((node: any) => !node.isPost);

                // Process each hashtag node
                hashtagNodes.forEach(async (node: any) => {
                    try {
                        const response = await fetch(
                            `${BASE_URL}/lcproto/query/posts_by_hashtag/?hashtag_id=${encodeURIComponent(node.id)}&query_id=${encodeURIComponent(query._id)}`
                        );

                        if (response.ok) {
                            const data = await response.json();
                            const posts = data?.posts || [];

                            // Filter posts that match ANY of the selected entities
                            const matchingPosts = posts.filter((post: any) => {
                                const postEntities = post.data_result?.entities || [];
                                const normalizedPostEntities = postEntities.map((e: string | { $oid: string }) =>
                                    typeof e === 'object' ? e.$oid : e
                                );

                                return allSelectedEntities.some(entityId =>
                                    normalizedPostEntities.includes(entityId)
                                );
                            });

                            if (matchingPosts.length > 0) {
                                handleNodeClickWithEntity(node, matchingPosts, {
                                    events: updatedEvents,
                                    people: updatedPeople,
                                    locations: updatedLocations
                                });
                            }
                        }
                    } catch (error) {
                        console.error(`Error processing node ${node.name}:`, error);
                    }
                });
            }
        } catch (error) {
            console.error("Error in entity filtering:", error);
        } finally {
            setLoading(false);
        }
    };

    const getColorBasedOnCount = (count: number) => {
        const maxCount = Math.max(...graphData.nodes.map((node: any) => Number(node.count)));
        const normalizedCount = count / (maxCount || 1);
        const brightness = Math.min(1, Math.max(0.3, 1 - normalizedCount * 0.7));
        return brightness;
    };

    const adjustColorBrightness = (hexColor: string, brightness: number): string => {
        let r = parseInt(hexColor.slice(1, 3), 16);
        let g = parseInt(hexColor.slice(3, 5), 16);
        let b = parseInt(hexColor.slice(5, 7), 16);

        r = Math.round(r * brightness);
        g = Math.round(g * brightness);
        b = Math.round(b * brightness);

        r = Math.max(0, Math.min(255, r));
        g = Math.max(0, Math.min(255, g));
        b = Math.max(0, Math.min(255, b));

        return `#${(1 << 24 | (r << 16) | (g << 8) | b).toString(16).slice(1).padStart(6, '0')}`;
    };

    return (
        <Box sx={{ position: "relative", width: "100%", height: 550 }}>
            {/* Entity Filter Dropdowns */}
            <Box sx={{
                mt: 2,
                p: { xs: 1, sm: 2 },
                bgcolor: theme.palette.background.paper,
                borderRadius: '12px',
                border: `1px solid ${theme.palette.primary.tertiary}`,
                overflow: 'hidden'
            }}>
                {/* Left Section - Entity Types */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    gap: { xs: 2, sm: 2, md: 2 },
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                        width: { xs: '100%', md: 'auto' }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            minWidth: 'fit-content'
                        }}>
                            <PersonIcon sx={{ color: theme.palette.primary.main }} />
                            <Typography variant="subtitle2">Entities:</Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                            width: { xs: '100%', sm: 'auto' },
                            flexWrap: { sm: 'wrap', md: 'nowrap' },
                            alignItems: 'center'
                        }}>
                            {/* Events Dropdown */}
                            <Box sx={{ position: 'relative', minWidth: '180px' }}>
                                <CustomDropdown
                                    label="Events"
                                    value={selectedEvents[selectedEvents.length - 1] || ''} // Show last selected value
                                    onChange={(e) => handleEntityClick('events', e.target.value)}
                                    options={filteredEntities.events?.filter(Boolean).map((event: any) => ({
                                        id: event._id,
                                        name: event.name || 'Unnamed Event',
                                        mentions: parseInt(event.mentions || 0),
                                        hideClose: true
                                    })) || []}
                                    showMentions
                                    showViewAll={false}
                                />
                            </Box>

                            {/* People Dropdown */}
                            <Box sx={{ position: 'relative', minWidth: '180px' }}>
                                <CustomDropdown
                                    label="People"
                                    value={selectedPeople[selectedPeople.length - 1] || ''}
                                    onChange={(e) => handleEntityClick('people', e.target.value)}
                                    options={filteredEntities.people?.filter(Boolean).map((person: any) => ({
                                        id: person._id,
                                        name: person.name || 'Unnamed Person',
                                        mentions: parseInt(person.mentions || 0),
                                        hideClose: true
                                    })) || []}
                                    showMentions
                                    showViewAll={false}
                                />
                            </Box>

                            {/* Locations Dropdown */}
                            <Box sx={{ position: 'relative', minWidth: '180px' }}>
                                <CustomDropdown
                                    label="Locations"
                                    value={selectedLocations[selectedLocations.length - 1] || ''}
                                    onChange={(e) => handleEntityClick('locations', e.target.value)}
                                    options={filteredEntities.locations?.filter(Boolean).map((location: any) => ({
                                        id: location._id,
                                        name: location.name || 'Unnamed Location',
                                        mentions: parseInt(location.mentions || 0),
                                        hideClose: true
                                    })) || []}
                                    showMentions
                                    showViewAll={false}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Box>


            {(loading || queryLoading || connectionsLoading || entitiesLoading) && <UiLoading height="100vh" />}
            <ZoomScale zoom={zoom} maxZoom={maxZoom} />
            <ForceGraph2D
                ref={graphRef}
                height={522}
                graphData={graphData}
                nodeCanvasObject={(node, ctx, globalScale) => nodePaint(node, ctx, globalScale)}
                linkCanvasObject={(link, ctx) => drawLink(link, ctx, currentNode?.id === link.source, animationTime)}
                linkWidth={2}
                linkColor={(link) => theme.palette.primary.main}
                backgroundColor={theme.palette.background.default}
                cooldownTicks={100}
                enableZoomInteraction={true}
                minZoom={minZoom}
                maxZoom={maxZoom}
                onZoom={(zoom) => setZoom(zoom.k)}
                onLinkClick={handleLinkClick}
                onNodeClick={handleNodeClickDetails}
            />
            <ZoomController
                sx={{ position: "absolute", right: 20, bottom: 20 }}
                zoomOut={handleZoomOut}
                zoomIn={handleZoomIn}
            />
            {(currentLink || currentNode?.id) && (
                <ConnectionsPopover
                    open={openPopover}
                    coords={coords}
                    handleClose={() => setOpenPopover(false)}
                >
                    {currentLink && currentLink.relation && <LinkPopoverContent linkData={currentLink} />}
                    {currentNode && <NodePopoverContent nodeData={currentNode} />}
                </ConnectionsPopover>
            )}
        </Box>
    );
};

export default NewNetworkGraph;
