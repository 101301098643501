import { Language } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { AiFillStar, AiFillWechat, AiOutlineStar } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FaWaveSquare } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { useHandleRouteClick } from "../routes/hooks";
import CustomDivider from "./CustomDivider";
import CustomIconButton from "./CustomIconButton";
import CustomPopover from "./CustomPopover";
import CustomStatus from "./CustomStatus";
import CustomSwitch from "./CustomSwitch";
import CustomTextButton from "./CustomTextButton";

export interface QueryCardDataType {
  id: number | string;
  isLiveMonitoring: boolean;
  dataSource: string;
  aiModels: string;
  resultsCount: number;
  daetime: Date;
  results_count?: number;
  keywords?: string[];
  pipeline_id: string;
  responsibilities?: string[];
  text?: string;
  role?: string;
  languages: Array<string>;
  [x: string]: any;
  is_active: boolean;
}

export interface QueryCardProps {
  data: QueryCardDataType;
  isFavorite?: boolean;
  isHistory?: boolean;
  liveMonitoring?: boolean;
  handleDeleteFromFavorites?: (value?: any) => any;
  handleSaveToFavorites?: (value?: any) => any;
  handleDeleteFromHistory?: (value?: any) => any;
  handleStartQuery?: (value?: any) => any;
  handleStopQuery?: (value?: any) => any;
}

export default function QueryCard({
  data,
  isFavorite = false,
  isHistory = false,
  liveMonitoring = false,
  handleDeleteFromFavorites = () => { },
  handleSaveToFavorites = () => { },
  handleDeleteFromHistory = () => { },
  handleStartQuery = () => { },
  handleStopQuery = () => { },
}: QueryCardProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();

  const [expandDetails, setExpandDetails] = React.useState(false);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [toggle, setToggle] = useState(data?.isLiveMonitoring);
  const [openDialog, setOpenDialog] = useState(false);

  const toggleExpandDetails = () => {
    setExpandDetails((prev) => !prev);
  };

  const handleOpenMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = () => {
    setMoreOptionsAnchorEl(null);
  };

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const handleQueryClick = () => {
    handleRouteClick(`${routes.home.path}?tab=home&subtab=overview&query=${data?._id}`);
  };

  const isQueryRunning = data.is_active;

  // function to handle redirect to AI Assist Page
  const handleAiAssist = () => {
    const queryId = data?._id;
    navigate(`/aiAssist?tab=aiAssist&query=${queryId}`);
  }

  // function to handle "View Details" click
  const handleViewDetailsClick = () => {
    setOpenDialog(true); // Open the Dialog for read-only view
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Grid item xs={12} sm={12} md={5.9} lg={5.9} xl={5.9}>
      <Box sx={{ background: theme.palette.background.paper, p: 3, borderRadius: 6, border: `0.5px solid ${theme.palette.additionalColors.border}` }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {/* Status dot */}
            <div
              style={{
                borderRadius: "50%",
                height: "0.7rem",
                width: "0.7rem",
                backgroundColor: isQueryRunning ? "green" : "red",
                marginRight: 8, // Space between dot and text
              }}
            ></div>

            {/* Query text aligned to the left */}
            <Box
              onClick={handleQueryClick}
              sx={{
                padding: "8px 10px",
                borderRadius: 1,
                width: "fit-content",
                cursor: "pointer",
                flexGrow: 1,  // Ensures the text takes available space
              }}
            >
              <Typography variant="h4" color={theme.palette.text.primary} fontWeight="bold">
                {data?.text ? data?.text : data?.keywords?.join(", ")}
              </Typography>
            </Box>

            {/* Actions aligned to the right */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {!isQueryRunning ? (
                <CustomStatus label="Start" status="neutral" onClick={() => handleStartQuery()} sx={{ width: "88px", height: "28px" }} />
              ) : (
                <CustomStatus label="Stop" status="extreme" onClick={() => handleStopQuery()} sx={{ width: "88px", height: "28px" }} />
              )}
              {liveMonitoring && (
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="body4"
                    sx={{
                      color: theme.palette.text.titleLabel,
                    }}
                  >
                    Live Monitoring
                  </Typography>
                  <CustomSwitch name="theme" onChange={handleToggle} checked={toggle} />
                </Stack>
              )}
              {data?.is_favourite && <AiFillStar size="12px" />}
              <CustomIconButton onClick={handleOpenMoreOptions} sx={{ color: theme.palette.text.default }}>
                <BsThreeDots />
              </CustomIconButton>
              <CustomPopover open={Boolean(moreOptionsAnchorEl)} anchorEl={moreOptionsAnchorEl} onClose={handleCloseMoreOptions}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    "&>.MuiButtonBase-root": {
                      justifyContent: "start",
                      textAlign: "start",
                    },
                  }}
                >
                  {isFavorite && (
                    <CustomTextButton onClick={() => handleDeleteFromFavorites()} startIcon={<CloseIcon color="error" />}>
                      Delete from favorites
                    </CustomTextButton>
                  )}
                  {isHistory && (
                    <>
                      {!data.is_favourite ? (
                        <CustomTextButton onClick={() => handleSaveToFavorites()} startIcon={<AiOutlineStar color="primary" />}>
                          Save to the favorites
                        </CustomTextButton>
                      ) : (
                        <CustomTextButton onClick={() => handleDeleteFromFavorites()} startIcon={<AiFillStar color="error" />}>
                          Remove from favorites
                        </CustomTextButton>
                      )}
                      <CustomDivider width="100%" />
                      <CustomTextButton onClick={() => handleDeleteFromHistory()} startIcon={<CloseIcon color="error" />}>
                        Delete from history
                      </CustomTextButton>
                      <CustomDivider width="100%" />
                      <CustomTextButton onClick={handleAiAssist} startIcon={<AiFillWechat color="primary" />}>
                        Chat with AI
                      </CustomTextButton>
                    </>
                  )}
                </Box>
              </CustomPopover>
            </Box>
          </Box>
        </Grid>

        <Grid container spacing={2} item xs={12} mt={2}>
          {/* Language and Results */}
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Language sx={{ color: theme.palette.text.secondary, fontSize: "13px", marginRight: "5px" }} />
              <Typography variant="caption" color={theme.palette.text.secondary} fontWeight="normal">
                {data?.languages?.join(", ") || "N/A"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FaWaveSquare color={theme.palette.text.secondary} style={{ marginRight: "5px" }} />
              <Typography variant="caption" color={theme.palette.text.secondary} fontWeight="normal">
                {data.results_count || 0} Results
              </Typography>
            </Box>
          </Grid>

          {/* Updated and View Details */}
          {/* <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="caption" color={theme.palette.text.secondary} fontWeight="normal">
                Updated 2m ago
              </Typography>
            </Box>
          </Grid> */}

          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomTextButton onClick={handleViewDetailsClick}>
                View Details {expandDetails ? <ChevronRightIcon sx={{ transform: "rotate(90deg)" }} /> : <ChevronRightIcon />}
              </CustomTextButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Dialog for View Details */}
      <Dialog open={openDialog} onClose={handleCloseDialog} sx={{
        '& .MuiDialog-paper': {
          backgroundColor: theme.palette.background.paper,
          borderRadius: 4, // Rounded corners for a smooth appearance
          padding: theme.spacing(3), // Added padding for inner spacing
        }
      }}>
        <DialogTitle sx={{
          backgroundColor: "#245785",
          fontWeight: 'bold',
          borderRadius: 4,
          color: theme.palette.text.primary,
          fontSize: '1.20rem', // for dialog title
          paddingBottom: theme.spacing(1),
        }}>
          Query Details
        </DialogTitle>
        <DialogContent sx={{
          fontWeight: 'bold',
          mt: 2,
          color: theme.palette.text.secondary,
          fontFamily: theme.palette.primary.main,
          fontSize: '1rem',
          '& p': {
            marginBottom: theme.spacing(1.5), // Add spacing between paragraphs
          }
        }}>
          <Typography variant="body1" color={theme.palette.text.secondary}>{data?.text}</Typography>
          <Typography variant="h4" color={theme.palette.text.secondary}>Languages: {data?.languages?.join(", ") || "N/A"}</Typography>
          <Typography variant="h4" color={theme.palette.text.secondary}>Results: {data?.results_count || 0}</Typography>
        </DialogContent>
        <DialogActions sx={{
          paddingTop: theme.spacing(1),
          justifyContent: 'flex-end',
        }}>
          <CustomTextButton
            onClick={handleCloseDialog}
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
            }}
          >
            Close
          </CustomTextButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
