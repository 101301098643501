import { Box, CardMedia, SxProps, Theme, Typography, useTheme } from "@mui/material";
import Typewriter from "../../../../../components/Typewriter";
import moment from "moment"

function transformString(input:string) {
  return input.replace(/@\[(.*?)\]\(.*?\)/g, '$1');
}

interface ChatBoxProps {
  type: string;
  message?: string;
  timestamp?: string;
  sx?: SxProps<Theme>;
  loader?: React.ReactElement;
  typing?: boolean;
  showUserIcons?: boolean;
  isSelected?: boolean;
  customSentBoxIcon?: React.ReactElement;
  customRecieveBoxIcon?: React.ReactElement;
  onCardClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function ChatBox({
  type,
  message,
  timestamp,
  loader,
  typing = false,
  showUserIcons = true,
  isSelected = false,
  customSentBoxIcon,
  customRecieveBoxIcon,
  onCardClick = () => {},
}: ChatBoxProps) {
  const theme = useTheme();
  const profileStyle = {
    width: "36px",
    height: "36px",
    alignItems: type === "outgoing" ? "start" : "end",
    alignSelf: "end",
  };

  //format the timestamp
  const formattedTimeStamp = timestamp ? moment(timestamp).format('hh:mm A'):''

  return (
    <Box display="flex" alignItems="flex-end" sx={{ justifyContent: type === "outgoing" ? "end" : "start", gap: 1, my: 2 }}>
      {type === "incoming" &&
        showUserIcons &&
        (customRecieveBoxIcon || <CardMedia component="img" image="/assets/user.png" alt="profile" sx={profileStyle} />)}

      <Box
        onClick={(e)=>{
          onCardClick(e);
        }}
        sx={{
          cursor:"pointer",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          background: !isSelected ? `${theme.palette.primary.tertiary}4D` : `${theme.palette.primary.tertiary}CC`,
          borderRadius: type === "outgoing" ? "20px 20px 0px 20px" : "20px 20px 20px 0",
          width: "70%",
          p: 2,
        }}
      >
        {message && (
          <Typography variant="body4" color={theme.palette.text.titleLabel} style={{whiteSpace: 'pre-wrap'}}>
            {typing ? <Typewriter text={transformString(message)} /> : transformString(message)} 
          </Typography>
        )}
        {loader}
        <Typography variant="body5" color={theme.palette.text.captionColor} sx={{ ml: type === "outgoing" ? "auto" : "0" }}>
          {formattedTimeStamp}
        </Typography>
      </Box>

      {type === "outgoing" &&
        showUserIcons &&
        (customSentBoxIcon || <CardMedia component="img" image={"/assets/profile.png"} alt="profile" sx={profileStyle} />)}
    </Box>
  );
}
