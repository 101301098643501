import { createContext, ReactNode, useContext } from 'react';

interface TabContextType {
    onTabChange: (tab: string) => void;
}

const TabContext = createContext<TabContextType | undefined>(undefined);

interface TabProviderProps {
    children: ReactNode;
    onTabChange: (tab: string) => void;
}

export const TabProvider = ({ children, onTabChange }: TabProviderProps) => {
    return (
        <TabContext.Provider value={{ onTabChange }}>
            {children}
        </TabContext.Provider>
    );
};

export const useTab = () => {
    const context = useContext(TabContext);
    if (context === undefined) {
        throw new Error('useTab must be used within a TabProvider');
    }
    return context;
};