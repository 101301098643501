import {
    Close as CloseIcon,
    KeyboardArrowRight,
    Person as PersonIcon,
    TableRows as TableRowsIcon, Window as WindowIcon
} from '@mui/icons-material';
import {
    Box,
    Button,
    Drawer,
    SelectChangeEvent,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import CustomFilterButton from '../../../../../components/CustomFilterButton';
import CustomIconButton from '../../../../../components/CustomIconButton';
import { useTab } from '../../../../../contexts/TabContext';
import { useHandleRouteClick } from '../../../../../routes/hooks';
import { getEntities, getNewConnections } from '../../../../../store/analysis/analysisThunk';
import { EntitiesType } from '../../../../../store/analysis/entitiesSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import CustomDropdown from './CustomDropdown';

export interface TabRecord {
    id: string | number;
    title: string;
    disabled?: boolean;
    component: React.ReactNode;
}

export interface TabLayoutProps {
    tabs: TabRecord[];
    getActiveTab: (tab: string) => void;
    getView: (view: boolean) => void;
    handleDrawerOpen(): void;
    openDrawer: boolean;
    setStateVariable?: any;
    getAuthenticitySorted: () => void;
    getRelevanceSorted: () => void;
    setRedFlags: any;
    selectedEntityId: string | null;
    onEntityTypeChange: (entityId: string | null, entityType: any) => void;

}

const FilterDrawerSimple = ({
    tabs,
    getActiveTab,
    getView,
    setStateVariable,
    getAuthenticitySorted,
    getRelevanceSorted,
    onEntityTypeChange,

}: TabLayoutProps) => {

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const { onTabChange } = useTab();
    const { allEntities } = useAppSelector((state) => state.analysisEntities);
    const handleRouteClick = useHandleRouteClick();
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryParameters] = useSearchParams();
    const [listView, setListView] = useState(false);
    const [contentType, setContentType] = useState('publication');
    const [selectedSource, setSelectedSource] = useState('all');
    const [sortBy, setSortBy] = useState('relevance');
    const [filteredEntities, setFilteredEntities] = useState<{
        people: any[];
        events: any[];
        locations: any[];
    }>({
        people: [],
        events: [],
        locations: []
    });
    const [selectedEvent, setSelectedEvent] = useState("");
    const [selectedPerson, setSelectedPerson] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedTopic, setSelectedTopic] = useState("");
    const [topicsData, setTopicsData] = useState<any[]>([]);
    const [activeFilter, setActiveFilter] = useState<{
        type: string;
        value: string;
        displayValue: string;
    } | null>(null);
    const sources = [
        { id: "all", text: "All" },
        { id: "news", text: "News" },
        { id: "twitter", text: "Twitter" },
        { id: "facebook", text: "Facebook" },
        { id: "instagram", text: "Instagram" },
        { id: "youtube", text: "Youtube" },
        { id: "linkedin", text: "Linkedin" },
        { id: "weibo", text: "Weibo" },
        { id: "indiatoday", text: "Indiatoday" },
        { id: "cnn", text: "CNN" },
        { id: "bbc", text: "BBC" },
    ];



    const handleDrawerOpenn = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleView = () => {
        setListView((prev) => !prev);
    };

    const handleSelectedEntities = (selectedEntities: EntitiesType[]) => {
        const newParams = new URLSearchParams(searchParams);

        if (selectedEntities.length > 0) {
            const entityIds = selectedEntities
                .map(entity => (entity as any)._id['$oid'])
                .join(',');
            newParams.set('specific_entity', entityIds);
        } else {
            newParams.delete('specific_entity');
        }

        setSearchParams(newParams);
    };

    const handleContentTypeChange = (event: SelectChangeEvent) => {
        setContentType(event.target.value);
        getActiveTab(event.target.value);
    };

    const handleSourceChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        setSelectedSource(value);
        if (value === 'all') {
            setStateVariable(sources)
        } else {
            setStateVariable([{ id: value, text: value }]);

        }
    };


    const handleSortChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        setSortBy(value);

        let sortByValue = "";
        switch (value) {
            case "Most Recent":
                sortByValue = "recent";
                break;
            case "autenticity":
                sortByValue = "autenticity";
                getAuthenticitySorted();
                break;
            case "relevance":
            default:
                sortByValue = "relevance";
                getRelevanceSorted();
                break;
        }
        setSortBy(sortByValue);
    };

    const clearFilterByType = (type: string) => {
        switch (type) {
            case 'events':
                setSelectedEvent('');
                break;
            case 'people':
                setSelectedPerson('');
                break;
            case 'locations':
                setSelectedLocation('');
                break;
            case 'topics':
                setSelectedTopic('');
                break;
        }
    };

    // const handleEntityClick = (type: string, value: string) => {
    //     if (value === 'view-all') {
    //         if (onTabChange) {
    //             onTabChange('entities');
    //         }
    //         const currentQuery = searchParams.get('query');
    //         handleRouteClick(`${window.location.pathname}?tab=home&subtab=entities${currentQuery ? `&query=${currentQuery}` : ''}`);
    //     } else {

    //         switch (type) {
    //             case 'events':
    //                 setSelectedEvent(value);

    //                 break;
    //             case 'people':
    //                 setSelectedPerson(value);

    //                 break;
    //             case 'locations':
    //                 setSelectedLocation(value);

    //                 break;
    //             case 'topics':
    //                 setSelectedTopic(value);

    //                 break;
    //         }
    //         onEntityTypeChange(value, type);
    //     }

    //     setOpen(false)
    // };

    const handleEntityClick = (type: string, value: string) => {
        if (value === 'view-all') {
            if (onTabChange) {
                onTabChange('entities');
            }
            const currentQuery = searchParams.get('query');
            handleRouteClick(`${window.location.pathname}?tab=home&subtab=entities${currentQuery ? `&query=${currentQuery}` : ''}`);
            return;
        }

        // Clear any existing filter before applying new one
        if (activeFilter && activeFilter.type !== type) {
            clearFilterByType(activeFilter.type);
        }

        let displayValue = '';
        switch (type) {
            case 'events':
                setSelectedEvent(value);
                displayValue = filteredEntities.events.find(e => e._id.$oid === value)?.name || '';
                break;
            case 'people':
                setSelectedPerson(value);
                displayValue = filteredEntities.people.find(p => p._id.$oid === value)?.name || '';
                break;
            case 'locations':
                setSelectedLocation(value);
                displayValue = filteredEntities.locations.find(l => l._id.$oid === value)?.name || '';
                break;
            case 'topics':
                setSelectedTopic(value);
                displayValue = topicsData.find(t => t._id.$oid === value)?.name || '';
                break;
        }

        if (value) {
            setActiveFilter({ type, value, displayValue });
        } else {
            setActiveFilter(null);
        }

        onEntityTypeChange(value, type);
        setOpen(false);
    };

    const handleRemoveFilter = () => {
        if (activeFilter) {
            // Clear the specific filter
            clearFilterByType(activeFilter.type);

            // Clear URL parameters
            const newParams = new URLSearchParams(queryParameters);
            newParams.delete('specific_entity');
            newParams.delete('hashtagId');
            handleRouteClick(`${window.location.pathname}?${newParams.toString()}`);

            // Reset active filter
            setActiveFilter(null);
        }
    };


    useEffect(() => {
        getView(listView);
    }, [listView]);


    useEffect(() => {



        const groupedEntities = allEntities.reduce((acc: any, entity: any) => {
            const type = entity.type.toLowerCase();

            let category;
            if (type === 'person') category = 'people';
            else if (type === 'event') category = 'events';
            else if (type === 'location') category = 'locations';
            else return acc;

            if (!acc[category]) acc[category] = [];
            acc[category].push(entity);
            return acc;
        }, { people: [], events: [], locations: [] });

        Object.keys(groupedEntities).forEach(category => {
            groupedEntities[category] = groupedEntities[category]
                .sort((a: any, b: any) => b.mentions - a.mentions)
                .slice(0, 5);
        });

        setFilteredEntities(groupedEntities);
    }, [allEntities]);

    useEffect(() => {
        let queryId = queryParameters.get("query");
        if (queryId) {
            dispatch(getEntities(queryId)).then((res) => {
                // console.log(res);

            }).catch((e) => {
                console.log(e);

            }).finally(() => {

            })


            dispatch(getNewConnections(queryId))
                .then((response: any) => {

                    if (response.payload?.data) {
                        const formattedTopics = response.payload.data.hashtags.map((hashtag: any) => ({
                            _id: { $oid: hashtag.hashtag_id },
                            name: hashtag.name, // Remove # from display name
                            mentions: hashtag.count
                        }));
                        setTopicsData(formattedTopics.slice(0, 5));
                    }
                })
                .catch((error) => {
                    console.log('Error fetching topics:', error);
                });
        }

    }, [queryParameters]);

    useEffect(() => {
        // Get relevant parameters from URL
        const hashtagId = queryParameters.get('hashtagId');
        const specificEntity = queryParameters.get('specific_entity');

        // First handle hashtagId (topic) selection
        if (hashtagId && topicsData.length > 0) {
            const matchingTopic = topicsData.find(topic => topic._id.$oid === hashtagId);
            if (matchingTopic) {
                setSelectedTopic(matchingTopic._id.$oid);
                // Update active filter
                setActiveFilter({
                    type: 'topics',
                    value: matchingTopic._id.$oid,
                    displayValue: matchingTopic.name
                });
            }
        } else if (specificEntity && topicsData.length > 0) {
            // Check if the specific entity is actually a topic
            const matchingTopic = topicsData.find(topic => topic._id.$oid === specificEntity);
            if (matchingTopic) {
                setSelectedTopic(matchingTopic._id.$oid);
                // Update active filter
                setActiveFilter({
                    type: 'topics',
                    value: matchingTopic._id.$oid,
                    displayValue: matchingTopic.name
                });
            }
        } else if (!hashtagId && !specificEntity) {
            // Reset topic selection if neither parameter is present
            setSelectedTopic("");
            // Clear active filter if it was a topic
            setActiveFilter(prev => prev?.type === 'topics' ? null : prev);
        }
    }, [queryParameters, topicsData]);


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    width: '100%',
                    px: { xs: 1, sm: 2 },
                    py: 1
                }}
            >
                {/* Left side - Filter Button */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CustomFilterButton onClick={handleDrawerOpenn}>
                        More Filters
                        <KeyboardArrowRight sx={{ ml: "3px" }} />
                    </CustomFilterButton>

                    {activeFilter && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <ActiveFilterChip
                                filterType={activeFilter.type}
                                filterValue={activeFilter.displayValue}
                                onRemove={handleRemoveFilter}
                            /> */}
                            <CustomFilterButton
                                onClick={handleRemoveFilter}
                                startIcon={<CloseIcon fontSize="small" />}
                            >
                                {activeFilter.type.charAt(0).toUpperCase() + activeFilter.type.slice(1)} : {activeFilter.displayValue}
                            </CustomFilterButton>
                        </Box>
                    )}
                </Box>

                {/* Spacer */}
                <Box sx={{ flex: 1 }} />

                {/* Right side - Dropdowns and View Toggle */}
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                >
                    <CustomDropdown
                        label="Content Type"
                        value={contentType}
                        onChange={handleContentTypeChange}
                        options={[
                            { id: 'publication', text: 'Publications' },
                            { id: 'image', text: 'Images' }
                        ]}
                    />

                    <CustomDropdown
                        label="Source Platform"
                        value={selectedSource}
                        onChange={handleSourceChange}
                        options={sources.map(source => ({
                            id: source.id,
                            text: source.text
                        }))}
                    />

                    <CustomDropdown
                        label="Sort Results"
                        value={sortBy}
                        onChange={handleSortChange}
                        options={[
                            { id: 'relevance', text: 'Most Relevant' },
                            { id: 'autenticity', text: 'High Authenticity' }
                        ]}
                    />

                    <CustomIconButton
                        sx={{
                            width: "36px",
                            height: "36px",
                            color: theme.palette.text.default
                        }}
                        onClick={handleView}
                    >
                        {listView ? <TableRowsIcon /> : <WindowIcon />}
                    </CustomIconButton>
                </Stack>
            </Box>


            <Drawer
                anchor="left"
                variant="persistent"
                open={open}
                onClose={handleDrawerClose}
                hideBackdrop={true}
                PaperProps={{
                    sx: {
                        width: { xs: '100%', sm: 400 },
                        background: "linear-gradient(90deg, #1c222f 0%, #1D2330 100%)",
                        boxShadow: "25px 0px 51px rgba(0, 0, 0, 0.25)",
                        borderRadius: '0px 10px 10px 0px',
                        border: `2px solid #383f5054`,
                        zIndex: 1210,
                        paddingLeft: theme.spacing(1.5),
                        paddingRight: theme.spacing(2),
                        overflowY: 'hidden',
                        p: 3,
                        marginLeft: {
                            xs: '0',
                            md: '4px'
                        },
                        borderRight: 'none',

                        left: {
                            xs: '0',
                            md: '90px'
                        },
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        position: 'fixed',
                    }
                }}
            >
                {/* Drawer Header */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                }}>
                    <Typography variant="h6">Filters</Typography>
                    <Button
                        variant="text"


                        onClick={handleDrawerClose}
                    >
                        <CloseIcon sx={{ width: "18px", height: "18px", mt: "-3px", mr: "5px" }} />
                        Close
                    </Button>
                </Box>

                <Stack spacing={3}>
                    {/* Entity Filters */}
                    <Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 2
                        }}>
                            <PersonIcon sx={{ color: theme.palette.primary.main }} />
                            <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>Entities</Typography>
                        </Box>

                        <Stack spacing={2}>
                            <CustomDropdown
                                label="Events"
                                value={selectedEvent}
                                onChange={(e) => handleEntityClick('events', e.target.value)}
                                options={filteredEntities.events.map(event => ({
                                    id: event._id.$oid,
                                    name: event.name,
                                    mentions: event.mentions
                                }))}
                                showMentions
                                showViewAll

                            />


                            <CustomDropdown
                                label="People"
                                value={selectedPerson}
                                onChange={(e) => handleEntityClick('people', e.target.value)}
                                options={filteredEntities.people.map(person => ({
                                    id: person._id.$oid,
                                    name: person.name,
                                    mentions: person.mentions
                                }))}
                                showMentions
                                showViewAll

                            />

                            {/* Locations Dropdown */}
                            <CustomDropdown
                                label="Locations"
                                value={selectedLocation}
                                onChange={(e) => handleEntityClick('locations', e.target.value)}
                                options={filteredEntities.locations.map(location => ({
                                    id: location._id.$oid,
                                    name: location.name,
                                    mentions: location.mentions
                                }))}
                                showMentions
                                showViewAll

                            />

                            {/* Topics Dropdown */}
                            <CustomDropdown
                                label="Topics"
                                value={selectedTopic}
                                onChange={(e) => handleEntityClick('topics', e.target.value)}
                                options={topicsData.map(topic => ({
                                    id: topic._id.$oid,
                                    name: topic.name,
                                    mentions: topic.mentions
                                }))}
                                showMentions
                                showViewAll
                            />
                        </Stack>
                    </Box>


                </Stack>

            </Drawer>
        </>
    );
};

export default FilterDrawerSimple;