import { Box, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useTab } from "../../../../../contexts/TabContext"
import { getNewConnections } from "../../../../../store/analysis/analysisThunk"
import { EntitiesType } from "../../../../../store/analysis/entitiesSlice"
import { useAppDispatch } from "../../../../../store/hooks"
import CustomDropdown from "../../Feed/components/CustomDropdown"
import SearchEntities from "./SearchEntities"

// Dummy data for entities
const dummyEntities = {
    events: [
        { _id: { $oid: "e1" }, name: "Climate Change Conference 2024", mentions: 145 },
        { _id: { $oid: "e2" }, name: "Tech Innovation Summit", mentions: 98 },
        { _id: { $oid: "e3" }, name: "Global Economic Forum", mentions: 87 },
        { _id: { $oid: "e4" }, name: "Healthcare Revolution Expo", mentions: 76 },
        { _id: { $oid: "e5" }, name: "Sustainable Energy Convention", mentions: 65 }
    ],
    emergingTopics: [
        { _id: { $oid: "t1" }, name: "Artificial Intelligence Ethics", mentions: 178 },
        { _id: { $oid: "t2" }, name: "Sustainable Development", mentions: 156 },
        { _id: { $oid: "t3" }, name: "Digital Privacy", mentions: 134 },
        { _id: { $oid: "t4" }, name: "Remote Work Culture", mentions: 112 },
        { _id: { $oid: "t5" }, name: "Mental Health Awareness", mentions: 98 }
    ]
}

// FilterEntitiesSection.tsx
interface FilterEntitiesSectionProps {
    onSearchStateChange: (
        isActive: boolean,
        selectedEntity: EntitiesType | null,
        relatedEntities: EntitiesType[]
    ) => void;
}

const FilterEntitiesSection: React.FC<FilterEntitiesSectionProps> = ({
    onSearchStateChange
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { onTabChange } = useTab();
    const [queryParameters] = useSearchParams();
    const [selectedEvent, setSelectedEvent] = useState("")
    const [selectedTopic, setSelectedTopic] = useState("")
    const [filteredEntities, setFilteredEntities] = useState(dummyEntities)
    const [topicsData, setTopicsData] = useState<any[]>([]);
    const [emerginTrendData, setEmerginTrendData] = useState<any[]>([]);
    console.log("🚀 ~ FilterEntitiesSection ~ emerginTrendData:", emerginTrendData)
    const [selectedEmerginTrend, setSelectedEmerginTrend] = useState("")
    const handleEntityClick = (type: any, value: string) => {
        if (type === 'emergingTopics') {
            setSelectedEmerginTrend(value)
            if (value === 'view-all') {
                console.log('View all events clicked')
            } else {
                const newParams = new URLSearchParams(queryParameters);
                newParams.set('tab', 'home');
                newParams.set('subtab', 'feed');
                // Keep the existing query parameter
                const queryId = queryParameters.get('query');
                if (queryId) {
                    newParams.set('query', queryId);
                }
                // Set the hashtagId
                newParams.set('hashtagId', value);
                newParams.delete('specific_entity');

                // Navigate with the exact URL structure
                onTabChange("feed");
                navigate(`/home?${newParams.toString()}`);
            }
        } else {
            setSelectedTopic(value)
            if (value === 'view-all') {
                console.log('View all emerging topics clicked')
            } else {
                // Create new URL params maintaining the existing structure
                const newParams = new URLSearchParams(queryParameters);
                newParams.set('tab', 'home');
                newParams.set('subtab', 'feed');
                // Keep the existing query parameter
                const queryId = queryParameters.get('query');
                if (queryId) {
                    newParams.set('query', queryId);
                }
                // Set the hashtagId
                newParams.set('hashtagId', value);
                newParams.delete('specific_entity');

                // Navigate with the exact URL structure
                onTabChange("feed");
                navigate(`/home?${newParams.toString()}`);
            }
        }
    }

    useEffect(() => {
        const queryId = queryParameters.get("query");
        if (queryId) {
            // setLoading(true);
            dispatch(getNewConnections(queryId))
                .then((response: any) => {
                    // console.log("🚀 ~ .then ~ response:", response)

                    if (response.payload?.data) {
                        const formattedTopics = response.payload.data.hashtags.map((hashtag: any) => ({
                            id: hashtag.hashtag_id,
                            name: hashtag.name,
                            mentions: hashtag.count
                        }));
                        // console.log("🚀 ~ formattedTopics ~ formattedTopics:", formattedTopics.slice(0, 5))
                        setTopicsData(formattedTopics.slice(0, 5));
                        setEmerginTrendData(formattedTopics.slice(5, 10));
                        // setTopicsData(response.payload.data.has);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching topics:', error);
                })
                .finally(() => {
                    // setLoading(false);
                });
        }
    }, [queryParameters]);

    return (
        <Box sx={{ p: { xs: 0.5, sm: 1 } }}>
            <Box sx={{
                p: { xs: 1, sm: 2 },
                overflow: 'hidden'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: { xs: 2, md: 3 },
                    justifyContent: 'space-between' // This pushes the dropdowns to the right
                }}>
                    {/* Search Bar */}
                    <Box sx={{
                        width: { xs: '100%', md: '300px' } // Fixed width for search bar
                    }}>
                        {/* <EntitySearch onEntitiesChange={() => { }} /> */}
                        <SearchEntities
                            onSearchStateChange={onSearchStateChange}
                        />
                    </Box>

                    {/* Dropdowns Container */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                        width: { xs: '100%', md: 'auto' },
                        justifyContent: { xs: 'flex-start', md: 'flex-end' }
                    }}>

                        {/* Emerging Topics Dropdown */}
                        <CustomDropdown
                            label="Emerging Topics"
                            value={selectedEmerginTrend}
                            onChange={(e) => handleEntityClick('emergingTopics', e.target.value)}
                            options={emerginTrendData.map(event => ({
                                id: event.id,
                                name: event.name,
                                mentions: event.mentions
                            }))}
                            showMentions
                        // showViewAll
                        />

                        <CustomDropdown
                            label="Trending Topics"
                            value={selectedTopic}
                            onChange={(e) => handleEntityClick('trendingTopics', e.target.value)}
                            options={topicsData}
                            showMentions
                        // showViewAll
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FilterEntitiesSection