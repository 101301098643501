// import { Psychology as BrainIcon } from '@mui/icons-material';
// import { Autocomplete, Box, Chip, TextField, useTheme } from '@mui/material';
// import { useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import { getEntities } from '../../../../../store/analysis/analysisThunk';
// import { EntitiesType } from '../../../../../store/analysis/entitiesSlice';
// import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';


// const SearchEntities = () => {
//     const theme = useTheme();
//     const dispatch = useAppDispatch();
//     const [searchParams] = useSearchParams();
//     const { allEntities } = useAppSelector((state) => state.analysisEntities);
//     const [availableEntities, setAvailableEntities] = useState<EntitiesType[]>([]);
//     const [open, setOpen] = useState(false);

//     useEffect(() => {
//         const queryId = searchParams.get('query');
//         if (queryId) {
//             dispatch(getEntities(queryId));
//         }
//     }, [dispatch, searchParams]);

//     useEffect(() => {
//         setAvailableEntities(allEntities);
//     }, [allEntities]);

//     return (
//         <Box sx={{ width: '100%' }}>
//             {/* Search Input Container */}
//             <Box sx={{ position: 'relative' }}>
//                 {/* Brain Icon and Search Text */}
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         left: '16px',
//                         top: '50%',
//                         transform: 'translateY(-50%)',
//                         display: 'flex',
//                         alignItems: 'center',
//                         gap: '6px',
//                         zIndex: 1,
//                         color: theme.palette.text.disabled,
//                     }}
//                 >
//                     <BrainIcon sx={{ fontSize: 18 }} />
//                     <span style={{ fontSize: '13px' }}>Search...</span>
//                 </Box>

//                 {/* Command Key Shortcut */}
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         right: '12px',
//                         top: '50%',
//                         transform: 'translateY(-50%)',
//                         zIndex: 1,
//                     }}
//                 >
//                     <Chip
//                         label="⌘K"
//                         size="small"
//                         sx={{
//                             height: '20px',
//                             backgroundColor: theme.palette.background.darkCardBackground,
//                             border: `1px solid ${theme.palette.primary.tertiary}`,
//                             borderRadius: '4px',
//                             '& .MuiChip-label': {
//                                 fontSize: '11px',
//                                 padding: '0 4px',
//                                 color: theme.palette.text.disabled,
//                             },
//                         }}
//                     />
//                 </Box>

//                 <Autocomplete
//                     id="entity-search"
//                     options={availableEntities}
//                     open={open}
//                     onInputChange={(event, value) => {
//                         setOpen(value.length > 0);
//                     }}
//                     getOptionLabel={(option) => option.name || ''}
//                     isOptionEqualToValue={(option: any, value: any) =>
//                         option?._id?.['$oid'] === value?._id?.['$oid']
//                     }
//                     popupIcon={null}
//                     ListboxProps={{
//                         style: {
//                             fontSize: '13px'
//                         }
//                     }}
//                     slotProps={{
//                         paper: {
//                             sx: {
//                                 backgroundColor: theme.palette.background.darkCardBackground,
//                                 '& .MuiAutocomplete-option': {
//                                     fontSize: '13px',
//                                     padding: '4px 8px',
//                                 }
//                             }
//                         }
//                     }}
//                     renderInput={(params) => {
//                         const { InputProps, inputProps, ...rest } = params;
//                         const { endAdornment, ...other } = InputProps;
//                         return (
//                             <TextField
//                                 {...rest}
//                                 InputProps={{
//                                     ...other,
//                                     endAdornment: endAdornment,
//                                     inputProps: {
//                                         ...inputProps,
//                                         placeholder: "What would you like to search?"
//                                     },
//                                     style: {
//                                         paddingLeft: '100px',
//                                         paddingRight: '50px',
//                                     }
//                                 }}
//                                 variant="outlined"
//                                 sx={{
//                                     width: '100%',
//                                     backgroundColor: theme.palette.background.darkCardBackground,
//                                     borderRadius: '8px',
//                                     '& .MuiOutlinedInput-root': {
//                                         minHeight: '36px',
//                                         '& fieldset': {
//                                             borderColor: theme.palette.primary.tertiary,
//                                             borderRadius: '8px',
//                                         },
//                                         '&:hover fieldset': {
//                                             borderColor: theme.palette.primary.main,
//                                         },
//                                         '&.Mui-focused fieldset': {
//                                             borderColor: theme.palette.primary.main,
//                                         },
//                                         '& input': {
//                                             fontSize: '13px',
//                                             height: '36px',
//                                             padding: '0 !important',
//                                             '&::placeholder': {
//                                                 color: theme.palette.text.disabled,
//                                                 opacity: 0.7,
//                                                 fontSize: '13px',
//                                             }
//                                         }
//                                     }
//                                 }}
//                             />
//                         );
//                     }}
//                     renderOption={(props, option) => (
//                         <Box
//                             component="li"
//                             {...props}
//                             sx={{
//                                 display: 'flex',
//                                 justifyContent: 'space-between',
//                                 alignItems: 'center',
//                                 fontSize: '13px',
//                                 '&:hover': {
//                                     backgroundColor: `${theme.palette.primary.main}20`,
//                                 }
//                             }}
//                         >
//                             <span>{option.name}</span>
//                             {/* {option.mentions && (
//                                 <Chip
//                                     label={`${option.mentions} mentions`}
//                                     size="small"
//                                     sx={{
//                                         height: '20px',
//                                         backgroundColor: theme.palette.primary.main,
//                                         color: theme.palette.common.white,
//                                         fontSize: '11px',
//                                         '& .MuiChip-label': {
//                                             padding: '0 8px',
//                                         }
//                                     }}
//                                 />
//                             )} */}
//                         </Box>
//                     )}
//                     filterOptions={(options, { inputValue }) =>
//                         options.filter((option) =>
//                             option.name?.toLowerCase().includes(inputValue.toLowerCase())
//                         )
//                     }
//                     sx={{
//                         '& .MuiAutocomplete-endAdornment': {
//                             top: '50%',
//                             transform: 'translateY(-50%)',
//                         }
//                     }}
//                 />
//             </Box>

//             {/* Results Section */}
//             <Box sx={{ mt: 2 }}>
//                 {open && availableEntities.length === 0 && (
//                     <Box sx={{
//                         textAlign: 'center',
//                         color: theme.palette.text.disabled,
//                         py: 2,
//                         fontSize: '13px'
//                     }}>
//                         No entities found
//                     </Box>
//                 )}
//             </Box>
//         </Box>
//     );
// };

// export default SearchEntities;



import { Psychology as BrainIcon, Close as CloseIcon } from '@mui/icons-material';
import { Autocomplete, Box, Chip, IconButton, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getEntities } from '../../../../../store/analysis/analysisThunk';
import { EntitiesType } from '../../../../../store/analysis/entitiesSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

interface SearchEntitiesProps {
    onSearchStateChange: (
        isActive: boolean,
        selectedEntity: EntitiesType | null,
        relatedEntities: EntitiesType[]
    ) => void;
}

const SearchEntities: React.FC<SearchEntitiesProps> = ({
    onSearchStateChange
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { allEntities } = useAppSelector((state) => state.analysisEntities);
    const [availableEntities, setAvailableEntities] = useState<EntitiesType[]>([]);
    const [selectedEntity, setSelectedEntity] = useState<EntitiesType | null>(null);
    const [relatedEntities, setRelatedEntities] = useState<EntitiesType[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const queryId = searchParams.get('query');
        if (queryId) {
            dispatch(getEntities(queryId));
        }
    }, [dispatch, searchParams]);

    useEffect(() => {
        setAvailableEntities(allEntities);
    }, [allEntities]);

    useEffect(() => {
        onSearchStateChange(
            selectedEntity !== null,
            selectedEntity,
            relatedEntities
        );
    }, [selectedEntity, relatedEntities, onSearchStateChange]);

    const handleEntitySelect = (entity: EntitiesType | null) => {
        setSelectedEntity(entity);

        if (entity) {

            const filtered = allEntities.filter(item =>
                item.name.toLowerCase().includes(entity.name.toLowerCase())
            );
            setRelatedEntities(filtered);
        } else {
            setRelatedEntities([]);
        }
        setOpen(false);
    };

    const handleClearSearch = () => {
        setSelectedEntity(null);
        setRelatedEntities([]);
        setOpen(false);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/* Search Input Container */}
            <Box sx={{ position: 'relative' }}>
                {/* Brain Icon and Search Text */}
                <Box
                    sx={{
                        position: 'absolute',
                        left: '16px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        zIndex: 1,
                        color: theme.palette.text.disabled,
                    }}
                >
                    <BrainIcon sx={{ fontSize: 18 }} />
                    <span style={{ fontSize: '13px' }}>Search...</span>
                </Box>

                {/* Command Key Shortcut */}
                <Box
                    sx={{
                        position: 'absolute',
                        right: '12px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    {selectedEntity ? (
                        <IconButton
                            size="small"
                            onClick={handleClearSearch}
                            sx={{
                                color: theme.palette.text.disabled,
                                '&:hover': {
                                    color: theme.palette.text.primary,
                                    backgroundColor: `${theme.palette.primary.main}20`
                                }
                            }}
                        >
                            <CloseIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                    ) : (
                        <Chip
                            label="⌘K"
                            size="small"
                            sx={{
                                height: '20px',
                                backgroundColor: theme.palette.background.darkCardBackground,
                                border: `1px solid ${theme.palette.primary.tertiary}`,
                                borderRadius: '4px',
                                '& .MuiChip-label': {
                                    fontSize: '11px',
                                    padding: '0 4px',
                                    color: theme.palette.text.disabled,
                                },
                            }}
                        />
                    )}
                </Box>

                <Autocomplete
                    id="entity-search"
                    options={availableEntities}
                    value={selectedEntity}
                    onChange={(_, newValue) => handleEntitySelect(newValue)}
                    open={open}
                    onInputChange={(_, value) => {
                        setOpen(value.length > 0);
                    }}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                        option?._id?.['$oid'] === value?._id?.['$oid']
                    }
                    popupIcon={null}
                    ListboxProps={{
                        style: {
                            fontSize: '13px'
                        }
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                backgroundColor: theme.palette.background.darkCardBackground,
                                '& .MuiAutocomplete-option': {
                                    fontSize: '13px',
                                    padding: '4px 8px',
                                }
                            }
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    paddingLeft: '100px',
                                    paddingRight: '50px',
                                }
                            }}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "What would you like to search?"
                            }}
                            variant="outlined"
                            sx={{
                                width: '100%',
                                backgroundColor: theme.palette.background.darkCardBackground,
                                borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                    minHeight: '36px',
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.tertiary,
                                        borderRadius: '8px',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '& input': {
                                        fontSize: '13px',
                                        height: '36px',
                                        padding: '0 !important',
                                        '&::placeholder': {
                                            color: theme.palette.text.disabled,
                                            opacity: 0.7,
                                            fontSize: '13px',
                                        }
                                    }
                                }
                            }}
                        />
                    )}
                    renderOption={(props, option: any) => (
                        <Box
                            component="li"
                            {...props}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontSize: '13px',
                                '&:hover': {
                                    backgroundColor: `${theme.palette.primary.main}20`,
                                }
                            }}
                        >
                            <span>{option.name}</span>
                            {/* {option.mentions && (
                                <Chip
                                    label={`${option.mentions} mentions`}
                                    size="small"
                                    sx={{
                                        height: '20px',
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        fontSize: '11px',
                                        '& .MuiChip-label': {
                                            padding: '0 8px',
                                        }
                                    }}
                                />
                            )} */}
                        </Box>
                    )}
                />
            </Box>


        </Box>
    );
};

export default SearchEntities;