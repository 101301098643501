import { Warning as WarningIcon } from '@mui/icons-material';
import LogoutIcon from "@mui/icons-material/Logout";
import { AiFillStar } from "react-icons/ai";
import { GiArtificialIntelligence } from "react-icons/gi";
import { IoMdHome, IoMdSettings } from "react-icons/io";
import { MdAnalytics, MdWorkHistory } from "react-icons/md";


import AISetting from "../pages/CustomAI/components/AISetting";
import AllImageRecognation from "../pages/CustomAI/components/Settings/Results/AllImageRecognation";
import ResultsOverview from "../pages/CustomAI/components/Settings/Results/ResultsOverview";
import SpecificModelTraining from "../pages/CustomAI/components/Settings/Results/SpecificModelTraining";
import Logout from "../pages/Logout";
import Alert from "../pages/alert/";
import SingleAlert from "../pages/alert/component/SingleAlert";
import Analytics from "../pages/analysis";
import TableFullView from "../pages/analysis/Components/Connections/Components/TableFullView";
import SpecificEntity from "../pages/analysis/Components/Entities/SpecificEntity";
import SpecificAudio from "../pages/analysis/Components/Feed/components/Audios/Analysis/SpecificAudio";
import SpecificPublication from "../pages/analysis/Components/Feed/components/Publications/SpecificPublication/SpecificPublication";
import RelatedOverview from "../pages/analysis/Components/Feed/components/Videos/Analysis/RelatedOverview";
import SpecificVideo from "../pages/analysis/Components/Feed/components/Videos/Analysis/SpecificVideo";
import RecognitionOverview from "../pages/analysis/Components/Overview/RiskSummary/component/RecognitionOverview";
import RiskFactorDetails from "../pages/analysis/Components/Overview/RiskSummary/component/RiskFactorDetails";
import RiskScoreDetails from "../pages/analysis/Components/Overview/RiskSummary/component/RiskScoreDetails";
import MillitaryPresence from "../pages/analysis/MillitaryPresence";
import VideoFeed from "../pages/analysis/VideoFeed";
import FavoriteQueries from "../pages/favoriteQueries";
import ForgotPassword from "../pages/forgotPassword";
import History from "../pages/history";
import Home from "../pages/home";
import CustomCase from "../pages/home/CustomCase";
import CustomCaseList from "../pages/home/components/CustomCasePipeline/CustomCaseList";
import CustomCasePreview from "../pages/home/components/CustomCasePipeline/CustomCasePreview";
import PipelineCreation from "../pages/home/components/CustomCasePipeline/PipelineCreation";
import LogIn from "../pages/login";
import Invitation from "../pages/login/Invitation";
import AIModelDetail from "../pages/markerplace/AIModelDetail";
import CartItems from "../pages/markerplace/component/CartItems";
import SpecificSolution from "../pages/markerplace/component/SpecificSolution";
import PaymentDetails from "../pages/markerplace/component/Upgrade/PaymentDetails";
import UpgradeInfo from "../pages/markerplace/component/Upgrade/UpgradeInfo";
import NewHome from "../pages/new_home";
import Notifications from "../pages/notifications";
import Register from "../pages/register";
import ResetPassword from "../pages/resetPassword";
import Settings from "../pages/settings";
import WorkspaceDetail from "../pages/settings/WorkspaceDetail";
import AddNewUser from "../pages/settings/WorkspaceDetail/AddNewUser";
import SpecificUser from "../pages/settings/WorkspaceDetail/SpecificUser";
import DowngradeInfo from "../pages/settings/component/DowngradePlan/DowngradeInfo";
import StartPage from "../pages/start";
import DiscoreSection from "../pages/support/component/discover/DiscoverSection";
import VerifyEmail from "../pages/verifyEmail";
export interface DrawerMenuItemsInterface {
  text: string;
  path: string;
  icon: React.ReactNode;
}

export interface HeaderIconProps {
  magicEdit?: boolean;
  notificationIcon?: boolean;
  profile?: boolean;
  aiAssist?: boolean;
  cart?: boolean;
}

export interface RoutesInterface {
  path: string;
  title: string;
  icon: React.ReactNode | null;
  isLoginRequired: boolean;
  page: React.ReactNode;
  isHiddenOnSideDrawer: boolean;
  isAdditional: boolean;
  showSideDrawer?: boolean;
  header?: HeaderIconProps;
}

export const routes: Record<string, RoutesInterface> = {
  startPage: {
    path: "/",
    title: "Start",
    icon: null,
    isLoginRequired: false,
    page: <StartPage />,
    isAdditional: false,
    isHiddenOnSideDrawer: false,
  },
  login: {
    path: "/login",
    title: "Log In",
    icon: null,
    isLoginRequired: false,
    page: <LogIn />,
    isAdditional: false,
    isHiddenOnSideDrawer: false,
  },
  register: {
    path: "/register",
    title: "Register",
    icon: null,
    isLoginRequired: false,
    page: <Register />,
    isAdditional: false,
    isHiddenOnSideDrawer: false,
  },
  forgotPassword: {
    path: "/forgot-password",
    title: "Forgot Password",
    icon: null,
    isLoginRequired: false,
    page: <ForgotPassword />,
    isAdditional: false,
    isHiddenOnSideDrawer: false,
  },
  resetPassword: {
    path: "/reset-password/reset/:uidb64/:token",
    title: "Reset Password",
    icon: null,
    isLoginRequired: false,
    page: <ResetPassword />,
    isAdditional: false,
    isHiddenOnSideDrawer: false,
  },
  verifyEmail: {
    path: "/verify/email/:token",
    title: "Email Verify",
    icon: null,
    isLoginRequired: false,
    page: <VerifyEmail />,
    isAdditional: false,
    isHiddenOnSideDrawer: false,
  },
  invitation: {
    path: "/invitation",
    title: "Invitation",
    icon: null,
    isLoginRequired: false,
    page: <Invitation />,
    isAdditional: false,
    isHiddenOnSideDrawer: false,
  },
  logout: {
    path: "/logout",
    title: "Logout",
    icon: <LogoutIcon sx={{ transform: "rotate(180deg);" }} />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: true,
    page: <Logout />,
  },

  // Private
  home: {
    path: "/home",
    title: "Home",
    icon: <IoMdHome />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    page: <Analytics />,
    // page: <NewHome />,
    header: {
      magicEdit: true,
      notificationIcon: true,
      profile: true,
      aiAssist: true,
    },
  },
  aiAssist: {
    path: "/aiAssist",
    title: "AI Assist",
    icon: <GiArtificialIntelligence />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    page: <NewHome />,
    header: {
      magicEdit: true,
      notificationIcon: true,
      profile: true,
      aiAssist: false,
    },
  },
  pipelines: {
    path: "/pipelines",
    title: "Pipelines",
    icon: <MdAnalytics />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    page: <Home />,
    header: {
      magicEdit: true,
      notificationIcon: true,
      profile: true,
      aiAssist: false,
    },
  },
  alert: {
    path: "/alert",
    title: "Alerts",
    icon: <WarningIcon />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    page: <Alert />,
    header: {
      magicEdit: false,
      notificationIcon: true,
      profile: true,
      aiAssist: false
    }

  },
  singleAlert: {
    path: "/single-alert",
    title: "Alerts",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: true,
    page: <SingleAlert />,
  },
  connectionTable: {
    path: "/connection/entity",
    title: "Entity",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <TableFullView />,
  },
  favorites: {
    path: "/favorites",
    title: "Favorites",
    icon: <AiFillStar />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    page: <FavoriteQueries />,
    header: {
      magicEdit: false,
      notificationIcon: true,
      profile: true,
      aiAssist: false,
    },
  },
  history: {
    path: "/history",
    title: "History",
    icon: <MdWorkHistory />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    page: <History />,
    header: {
      magicEdit: false,
      notificationIcon: true,
      profile: true,
      aiAssist: false,
    },
  },
  // marketPlace: {
  //   path: "/marketplace",
  //   title: "Market",
  //   icon: <RiShoppingBag3Fill />,
  //   isLoginRequired: true,
  //   isHiddenOnSideDrawer: false,
  //   isAdditional: false,
  //   page: <Marketplace />,
  //   header: {
  //     cart: true,
  //   },
  // },
  upgradeInfo: {
    path: "/marketplace/upgrade",
    title: "Upgrade",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    showSideDrawer: false,
    isAdditional: false,
    page: <UpgradeInfo />,
  },
  paymentDetails: {
    path: "/marketplace/payment",
    title: "Payment Details",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    showSideDrawer: false,
    isAdditional: false,
    page: <PaymentDetails />,
  },
  specificSolution: {
    path: "/marketplace/solution/:id",
    title: "Specific Solution",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    showSideDrawer: false,
    isAdditional: false,
    page: <SpecificSolution />,
  },
  cartItems: {
    path: "/marketplace/cart",
    title: "Cart",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    showSideDrawer: false,
    isAdditional: false,
    page: <CartItems />,
  },
  aiModelDetail: {
    path: "/model/:aiModelId",
    title: "Ai Model",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <AIModelDetail />,
    showSideDrawer: false,
  },
  settings: {
    path: "/settings",
    title: "Settings",
    icon: <IoMdSettings />,
    isLoginRequired: true,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    page: <Settings />,
    header: {
      magicEdit: false,
      notificationIcon: true,
      profile: true,
      aiAssist: false,
    },
  },
  workspaceDetail: {
    path: "/workspace/:workspaceId",
    title: "Workspace Detail",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <WorkspaceDetail />,
    showSideDrawer: false,
  },
  downgradeInfo: {
    path: "/settings/downgrade",
    title: "Downgrade",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    showSideDrawer: false,
    isAdditional: false,
    page: <DowngradeInfo />,
  },
  specificUser: {
    path: "/user/:workspaceId/:userId",
    title: "Specific User",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <SpecificUser />,
    showSideDrawer: false,
  },
  notifications: {
    path: "/notifications",
    title: "Notifications",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <Notifications />,
    showSideDrawer: false,
  },
  // support: {
  //   path: "/support",
  //   title: "Support",
  //   icon: <MdHelpCenter />,
  //   isLoginRequired: true,
  //   isHiddenOnSideDrawer: false,
  //   isAdditional: false,
  //   page: <Support />,
  // },
  discover: {
    path: "/discover",
    title: "Discover More",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <DiscoreSection />,
  },
  // customAI: {
  //   path: "/custom-ai",
  //   title: "Custom AI",
  //   icon: <AutoFixHighIcon />,
  //   isLoginRequired: true,
  //   isHiddenOnSideDrawer: false,
  //   isAdditional: false,
  //   page: <CustomAI />,
  //   header: {
  //     magicEdit: false,
  //     notificationIcon: true,
  //     profile: true,
  //     aiAssist: false,
  //   },
  // },
  aiSetting: {
    path: "/setting/:id",
    title: "Custom AI Setting",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <AISetting />,
  },
  resultsOverview: {
    path: "/custom-ai/results/:id",
    title: "Results Overview",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <ResultsOverview />,
  },
  recognation: {
    path: "/custom-ai/:model/:type/:id",
    title: "Image Recognation",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <AllImageRecognation />,
  },
  modelTraining: {
    path: "/custom-ai/:model/:id",
    title: "Specific Model Training",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <SpecificModelTraining />,
  },
  riskScore: {
    path: "/riskscore/:score",
    title: "Risk Score",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <RiskScoreDetails />,
  },
  riskFactor: {
    path: "/riskFactor/:riskfactor",
    title: "Risk Factor",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <RiskFactorDetails />,
  },
  publication: {
    path: "/publication/:queryId/:id",
    title: "Publication",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <SpecificPublication />,
  },
  specificEntity: {
    path: "/entity/:id",
    title: "Entity",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <SpecificEntity />,
  },
  specificVideo: {
    path: "/video/:id",
    title: "Video Analysis",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <SpecificVideo />,
  },
  relatedOverview: {
    path: "/video/:type/:id",
    title: "Video Overview Analysis",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <RelatedOverview />,
  },
  specificAudio: {
    path: "/audio/:id",
    title: "Audio Analysis",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <SpecificAudio />,
  },
  createCustomCase: {
    path: "/create/:caseId",
    title: "Create Custom Case",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <CustomCase />,
    showSideDrawer: false,
  },
  customCase: {
    path: "/customcase",
    title: "Custom Case",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <CustomCaseList />,
    showSideDrawer: false,
  },
  specificCustomCase: {
    path: "/customcase/:id",
    title: "Specific Custom Case",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <CustomCasePreview />,
    showSideDrawer: false,
  },
  pipelineCreation: {
    path: "/customcase/pipeline",
    title: "Pipeline Creation",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <PipelineCreation isEdit={false} />,
    showSideDrawer: false,
  },
  modifyCustomCase: {
    path: "/customcase/pipeline/:pipelineId",
    title: "Modify CustomCase",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <PipelineCreation isEdit={true} />,
    showSideDrawer: false,
  },
  millitaryPresenceMap: {
    path: "/analysis/millitary-presence",
    title: "Millitary Presence",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <MillitaryPresence />,
    showSideDrawer: false,
  },
  videoFeed: {
    path: "/analysis/video-feed/:feedId",
    title: "Video Feed",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <VideoFeed />,
    showSideDrawer: false,
  },
  recognationOverview: {
    path: "/analysis/:recognation",
    title: "Recognation Overview",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
    page: <RecognitionOverview />,
  },
  addUser: {
    path: "/workspace/:workspaceId/addUser",
    title: "Add New User To Workspace",
    icon: null,
    isLoginRequired: true,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    page: <AddNewUser />,
    showSideDrawer: false,
  },
};

export const routesArr: [string, RoutesInterface][] = Object.entries(routes);

export const drawerMenuItems = routesArr
  .filter((route: any): boolean => route[1].isLoginRequired && !route[1].isHiddenOnSideDrawer && !route[1].isAdditional)
  .map(
    (route: any): DrawerMenuItemsInterface => ({
      text: route[1].title,
      path: route[1].path,
      icon: route[1].icon,
    })
  );

export const additionalDrawerMenuItems = routesArr
  .filter((route: any): boolean => route[1].isLoginRequired && !route[1].isHiddenOnSideDrawer && route[1].isAdditional)
  .map(
    (route: any): DrawerMenuItemsInterface => ({
      text: route[1].title,
      path: route[1].path,
      icon: route[1].icon,
    })
  );
