import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactFlow, { Controls } from "reactflow";
import "reactflow/dist/style.css";
import BackButton from "../../../../components/BackButton";
import CustomButton from "../../../../components/CustomButton";
import CustomInputField from "../../../../components/CustomInputField";
import { routes } from "../../../../routes";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { getQueryById, saveQuery } from "../../../../store/analysis/analysisThunk";
import { useAppDispatch } from "../../../../store/hooks";
import { getAllModules, getPipelineDetail } from "../../../../store/pipeline/pipelineThunk";
import CustomNode from "./CustomNode";
import QueryInputs from "./QueryInputs";

export interface QueryProps {
  text: string;
  role: string;
  pipeline_id: any;
  languages: string[];
  redflags: string[];
  descriptors: string[],
  sources: string[];
  startDate: string | null;
  EndDate: String | null;
}

interface AvailableModulesType {
  id: string;
  label: string;
  inputs: string[];
  outputs: string[];
  type: string;
  subtype?: string;
  startdate: string;
  enddate: string;
}

export default function CustomCasePreview() {
  const theme = useTheme();
  const params = useParams();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();
  const dispatch = useAppDispatch();
  const [nodesData, setNodes] = useState<Record<string, any>>([]);
  const [caseName, setCaseName] = useState<string>(state?.name);
  const [availableModules, setAvailableModules] = useState<AvailableModulesType[]>([]);
  const [onlineSourceOptions, setOnlineSourceOptions] = useState<string[]>([]);
  const [physicalSourceOptions, setPhysicalSourceOptions] = useState<string[]>([]);
  console.log("parmas :", params);

  useEffect(() => {
    dispatch(getAllModules())
      .then((res) => {
        if (res?.payload !== undefined) {
          if (res?.payload?.status === 201 || res?.payload?.status === 200) {
            setAvailableModules(res?.payload?.data);
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  useEffect(() => {
    let onlineSources: string[] = [];
    let physicalSources: string[] = [];
    availableModules.forEach((module) => {
      if (module.type === "sources") {
        if (module.subtype === "online") {
          onlineSources.push(module.label);
        } else if (module.subtype === "physical") {
          physicalSources.push(module.label);
        }
      }
    });
    setOnlineSourceOptions(onlineSources);
    setPhysicalSourceOptions(physicalSources);
  }, [availableModules]);

  const nodeTypes = useMemo(
    () => ({
      selectorNode: (props: any) => (
        <CustomNode onlineSource={onlineSourceOptions} physicalSource={physicalSourceOptions} isCancel={false} {...props} />
      ),
    }),
    [onlineSourceOptions, physicalSourceOptions]
  );
  const id = params?.id;

  const handleBack = () => {
    handleRouteClick(routes.pipelines.path +"?tab=pipelines");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };

  const handleEdit = (id?: string, name?: string) => {
    if (id && name) {
      handleRouteClick(routes.modifyCustomCase.path.replace(":pipelineId", id), { backPath: "/customcase", name: name });
    }
  };

  const handleQuery = (queryValue: QueryProps) => {
    if (
      queryValue.text === "" ||
      queryValue.role === "" ||
      queryValue.languages.length === 0 ||
      queryValue.sources.length === 0 ||
      queryValue.redflags.length === 0 ||
      queryValue.descriptors.length === 0
    ) {
      toast.info("Please fill all details");
    } else {
      if (id) {
        const data = {
          ...queryValue,
          pipeline_id: id,
        };
        dispatch(saveQuery(data))
          .then((res: any) => {
            if (res?.payload !== undefined) {
              if (res?.payload?.status === 201 || res?.payload?.status === 200) {
                toast.success("Query saved successfully!");
                dispatch(getQueryById(res?.payload?.data?.id));
                handleRouteClick(`${routes.home.path}?tab=home&page=overview&query=${res?.payload?.data?.id}`, {});
              }
              if (res?.payload?.status !== 201 && res?.payload?.status !== 200) {
                toast.error(res?.payload?.error?.data.detail);
              }
            }
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    }
  };

  useEffect(() => {
    if (params) {
      dispatch(getPipelineDetail(params?.id))
        .then((res) => {
          if (res?.payload?.status === 200) {
            setNodes(res?.payload?.data);
            setCaseName(res?.payload?.data?.name);
          } else {
            toast("Pipeline data failed");
          }
        })
        .catch((e) => toast("Pipeline data failed"));
    }
  }, [params]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={1}>
        <Stack direction="column" alignItems="flex-start">
          <Stack direction="row" alignItems="flex-start" gap={3}>
            <Typography variant="h1" color={theme.palette.text.titleLabel}>
              Social Media Analysis
            </Typography>
            <CustomInputField
              placeholder="Pipeline Name"
              size="small"
              defaultValue={state?.name}
              value={caseName}
              variant="outlined"
              autoComplete="off"
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-input": {
                  ...theme.typography.body1,
                  color: theme.palette.text.titleLabel,
                },
                "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
                  height: "36px",
                },
                "&.MuiTextField-root": {
                  mt: 0,
                },
              }}
            />
          </Stack>
          <BackButton onClick={handleBack} sx={{ mt: "-3px" }} />
        </Stack>
        {/* <CustomButton
          onClick={() => params.id && handleEdit(params.id, caseName)}
          sx={{ ...theme.typography.bigButton, height: "36px", color: theme.palette.text.tableHeader }}
          endIcon={<BorderColorIcon sx={{ color: theme.palette.text.default }} />}
        >
          Edit Pipeline
        </CustomButton> */}
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid",
          borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          background: theme.palette.dashboardContainer.containerBackground,
          height: "100%",
          py: 3,
          px: { xs: 3, lg: 10, xl: 15 },
          mt: -1,
          overflow: "auto",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
                background: theme.palette.background.dark,
                borderRadius: 3,
                height: "100%",
                px: 4,
                pt: 2,
                py: 6,
                mt: -1,
              }}
            >
              <QueryInputs handleQuery={handleQuery} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} height="100%">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                boxShadow: theme.palette.dashboardContainer.insetBoxShadow,
                background: theme.palette.background.dark,
                borderRadius: 3,
                height: "100%",
                p: 2,
                mt: -1,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  borderRadius: 1,
                  background: `linear-gradient(to right, ${theme.palette.text.default} 50%, transparent 0%) top repeat-x, linear-gradient(${theme.palette.text.default} 50%, transparent 0%) right repeat-y, linear-gradient(to right, ${theme.palette.text.default} 50%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.text.default} 50%, transparent 0%) left repeat-y`,
                  backgroundSize: "20px 1px, 1px 20px",
                  gap: 2,
                }}
              >
                <ReactFlow
                  nodes={nodesData?.pipeline_config?.nodes}
                  edges={nodesData?.pipeline_config?.edges}
                  nodeTypes={nodeTypes}
                  snapToGrid={true}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 2,
                    padding: 2,
                    marginTop: 2,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  fitView
                  nodesDraggable={false}
                >
                  <Controls showInteractive={false} showZoom={true} showFitView={false} />
                </ReactFlow>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
